import { Typography } from "@material-tailwind/react";
import React from "react";

const OurComponent = ({ data }) => {
  return (
    <div
      className="grid-cols-1 w-[100%] min-h-[293px] bg-[#ffffff] rounded-[12px] flex flex-col justify-between"
    >
      <img src={data?.img} alt="img" className="w-full h-full rounded-t-[12px] " />
      <Typography className="flex flex-row justify-between text-lg font-medium text-text-500 pb-4 pt-3 pl-6 font-Be_Vietnam_Pro">
        {data?.name}{" "}
      </Typography>
    </div>
  );
};

export default OurComponent;
