import { Button, Typography } from "@material-tailwind/react";
import PageHeader from "../../../common/components/PageHeader";
import { setCourseActiveTab } from "./coursesTabSlice";
import { useDispatch } from "react-redux";
import { BenefitCard } from "../../Hackathon/BenefitCard";
import { CourseCard } from "./CourseCard";
import OurComponent from "../../../common/components/OurComponent";
import OurTestimonials from "../../../common/components/OurTestimonials";
import OurFaqs from "../../../common/components/OurFaqs";
import OurPricing from "../../../common/components/OurPricing";

const CoursesHome = () => {
    const dispatch = useDispatch();
    const arr = [1, 2, 3, 4, 5, 6]
    const benefits = [
        {
            heading: "Flexible Learning Schedule",
            text: "Fit your coursework around your existing commitments and obligations."
        },
        {
            heading: "Expert Instruction",
            text: "Learn from industry experts who have hands-on experience in design and development."
        },
        {
            heading: "Diverse Course Offerings",
            text: "Explore a wide range of design and development courses covering various topics."
        },
        {
            heading: "Updated Curriculum",
            text: "Access courses with up-to-date content reflecting the latest trends and industry practices."
        },
        {
            heading: "Practical Projects and Assignments",
            text: "Develop a portfolio showcasing your skills and abilities to potential employers."
        },
        {
            heading: "Interactive Learning Environment",
            text: "Collaborate with fellow learners, exchanging ideas and feedback to enhance your understanding."
        },
    ];

    const bannerBox2 = [
        {
            name: "Individual",
            img: "https://media.istockphoto.com/id/1369745788/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=2048x2048&w=is&k=20&c=XxZtwneAHjl0zdQ1YcvkT7PUL8WkpYRDsprz7kNZ3Sk=",
        },
        {
            name: "University",
            img: "https://media.istockphoto.com/id/1369745788/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=2048x2048&w=is&k=20&c=XxZtwneAHjl0zdQ1YcvkT7PUL8WkpYRDsprz7kNZ3Sk=",
        },
        {
            name: "Corporate",
            img: "https://media.istockphoto.com/id/1369745788/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=2048x2048&w=is&k=20&c=XxZtwneAHjl0zdQ1YcvkT7PUL8WkpYRDsprz7kNZ3Sk=",
        },
        {
            name: "Open",
            img: "https://media.istockphoto.com/id/1369745788/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=2048x2048&w=is&k=20&c=XxZtwneAHjl0zdQ1YcvkT7PUL8WkpYRDsprz7kNZ3Sk=",
        },
    ];

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <PageHeader
                    spanText="Unlock"
                    text="Your Creative Potential"
                    subHead1="with Online Design and Development Courses."
                    subHead2="Learn from Industry Experts and Enhance Your Skills."
                />
                <div className="flex items-center justify-center flex-col lg:flex-row mt-10 gap-5">
                    <Button
                        onClick={() => dispatch(setCourseActiveTab("all"))}
                        className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        Explore Courses
                    </Button>
                    <Button
                        onClick={() => dispatch(setCourseActiveTab("all"))}
                        className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        View Pricing
                    </Button>
                </div>
                <div className="w-full h-[600px] mt-20">
                    <img className="w-full h-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1552960504-34e1e1be3f53?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                </div>
                
                {/* --------------------------static benefits section---------- */}
                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Benefits
                    </Typography>
                    <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="mt-10 flex flex-wrap items-center justify-center gap-7">
                        {benefits.map((item, index) => (
                            <BenefitCard key={index} index={index} heading={item.heading} text={item.text} />
                        ))}
                    </div>
                </div>

                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Our Courses
                    </Typography>
                    <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            onClick={() => dispatch(setCourseActiveTab("all"))}
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="flex flex-wrap items-center gap-7">
                        {arr.map((item, index) => (
                            <CourseCard placement="home" key={index} />
                        ))}
                    </div>

                    <div className="w-full h-full mt-20">
                        <Typography className="text-4xl font-medium text-text-500 mb-1 font-Be_Vietnam_Pro">
                            Our Programs
                        </Typography>
                        <div className="flex w-full justify-between flex-col md:flex-row lg:flex-row xl:flex-row mb-16 gap-[3vw]">
                            <Typography className="text-base font-Be_Vietnam_Pro text-justify font-normal text-text-400 max-w-6xl">
                                Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam
                                eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac
                                cum eget habitasse in velit fringilla feugiat senectus in.
                            </Typography>
                            <Button className="bg-[#fcfcfd] text-text-500 font-Be_Vietnam_Pro font-medium text-base normal-case shadow-none hover:shadow-none min-w-[112px] max-h-[56px]">
                                View All
                            </Button>
                        </div>
                        <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-rows-4 md:grid-rows-2 lg:grid-rows-1 gap-5">
                            {bannerBox2.map((item, index) => {
                                return <OurComponent data={item} key={index} />;
                            })}
                        </div>

                        <div className="mt-20">
                            <OurTestimonials />
                        </div>
                        <div className="mt-20">
                            <OurPricing />
                        </div>
                        <div className="mt-20">
                            <OurFaqs />
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default CoursesHome;