import { Button, Typography } from "@material-tailwind/react";
import PageHeader from "../../common/components/PageHeader";
import UpcomingHackathon from "./UpcomingHackathon";
import { useNavigate } from "react-router";
import OurTestimonials from "../../common/components/OurTestimonials";
import OurFaqs from "../../common/components/OurFaqs";
import { setActiveTab } from "./hackathonTabSlice";
import { useDispatch } from "react-redux";
import { BenefitCard } from "./BenefitCard";
import ExpandedHackathon from "./Expandedhackathon";

const HackathonHome = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const benefits = [
        {
            heading: "Flexible Learning Schedule",
            text: "Fit your coursework around your existing commitments and obligations."
        },
        {
            heading: "Expert Instruction",
            text: "Learn from industry experts who have hands-on experience in design and development."
        },
        {
            heading: "Diverse Course Offerings",
            text: "Explore a wide range of design and development courses covering various topics."
        },
        {
            heading: "Updated Curriculum",
            text: "Access courses with up-to-date content reflecting the latest trends and industry practices."
        },
        {
            heading: "Practical Projects and Assignments",
            text: "Develop a portfolio showcasing your skills and abilities to potential employers."
        },
        {
            heading: "Interactive Learning Environment",
            text: "Collaborate with fellow learners, exchanging ideas and feedback to enhance your understanding."
        },
    ]

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <PageHeader
                    spanText="Unlock"
                    text="Your Creative Potential"
                    subHead1="with Online Design and Development Courses."
                    subHead2="Learn from Industry Experts and Enhance Your Skills."
                />

                <div className="flex items-center justify-center flex-col lg:flex-row mt-10 gap-5">
                    <Button
                        onClick={() => dispatch(setActiveTab("all"))}
                        className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        Explore Hackathons
                    </Button>
                    <Button
                        onClick={() => dispatch(setActiveTab("all"))}
                        className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        View Upcoming Hackathons
                    </Button>
                </div>

                {/* -------------------------most recent hackathon------------------- */}
                <div className="mt-20">
                    <ExpandedHackathon placement="home" />
                </div>

                {/* --------------------------static benefits section---------- */}
                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Benefits
                    </Typography>
                    <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="mt-10 flex flex-wrap items-center justify-center gap-7">
                        {benefits.map((item, index) => (
                            <BenefitCard key={index} index={index} heading={item.heading} text={item.text} />
                        ))}
                    </div>
                </div>

                {/* -------------------------upcoming hackathon---------------------------- */}
                <div className="mt-20">
                    <UpcomingHackathon type="Upcoming" />
                </div>
                <div className="mt-20">
                    <OurTestimonials />
                </div>
                <div className="mt-20">
                    <OurFaqs />
                </div>
            </div>
        </>
    )
}

export default HackathonHome;

