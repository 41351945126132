import { Button, Tab, Tabs, TabsHeader, Typography } from "@material-tailwind/react";
import PageHeader from "../../../common/components/PageHeader";
import { useState } from "react";
import { CourseCard } from "./CourseCard";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";


const MyCourses = () => {
    const [activeTab, setCategoryActiveTab] = useState("category_1");
    const { accessToken } = useSelector((state) => state.authSlice);
    const navigate = useNavigate();
    const arr = [1, 2, 3];
    const tabsData = [
        {
            label: "Category 1",
            value: "category_1",
        },
        {
            label: "Category 2",
            value: "category_2",
        },
        {
            label: "Category 3",
            value: "category_3",
        },
        {
            label: "Category 4",
            value: "category_4",
        },
        {
            label: "Category 5",
            value: "category_5",
        },
    ];

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                {accessToken ?
                    <>
                        <PageHeader
                            spanText="Resume"
                            text="Your Learning Paths"
                            subHead1="complete the courses to sharpen your skill."
                            subHead2="2M+ users already completed these courses."
                        />
                        <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500 mt-20">
                            My Courses
                        </Typography>
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full mt-5">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>
                        <div className="flex items-center justify-center">
                            <Tabs
                                value={activeTab}
                                className="w-[60%] bg-[#ffffff] rounded-lg mt-10"
                            >
                                <TabsHeader
                                    className="bg-[#ffffff] p-2 rounded-lg"
                                    indicatorProps={{
                                        className: "bg-[#2C6DE7] text-[#ffffff]", // Blue sliding indicator
                                    }}
                                >
                                    {tabsData.map(({ label, value }) => (
                                        <Tab
                                            key={value}
                                            value={value}
                                            onClick={() => setCategoryActiveTab(value)} // Use arrow function to directly set the value
                                            className={`px-4 py-2 rounded-lg text-base font-Be_Vietnam_Pro font-medium ${activeTab === value ? "text-[#ffffff]" : "text-text-500"
                                                }`}
                                        >
                                            {label}
                                        </Tab>
                                    ))}
                                </TabsHeader>
                            </Tabs>
                        </div>
                        <div className="">
                            <div className="flex flex-wrap items-center gap-7">
                                {arr.map((item, index) => (
                                    <CourseCard placement="my_courses" key={index} />
                                ))}
                            </div>
                        </div>
                    </>
                    :
                    <div className="flex items-center justify-center flex-col">
                        <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-2xl">
                            Login to view your played hackathons
                        </Typography>
                        <Button
                            onClick={() => navigate("/login")}
                            variant="filled"
                            className="mt-5 normal-case shadow-none hover:shadow-none font-semibold text-base font-Be_Vietnam_Pro py-2 px-6 text-white bg-primaryLight-500 focus:ring-0"
                        >
                            Login
                        </Button>
                    </div>
                }
            </div>
        </>
    )
}

export default MyCourses;