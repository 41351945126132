import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = 'http://13.53.188.135:8080/api/v1/';

// Define an API service
const api = createApi({
    reducerPath: 'api',  // unique key to identify this slice of state
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl }), // Base URL for your API
    endpoints: (builder) => ({
        signup: builder.mutation({ //signup
            query: (params) => {
                return {
                    url: 'auth/signup',
                    method: 'POST',
                    body: params,
                }
            }
        }),
        login: builder.mutation({ //login
            query: (params) => {
                return {
                    url: 'auth/signin',
                    method: 'POST',
                    body: params,
                }
            }
        }),
        googleSignIn: builder.mutation({ //google signin
            query: (params) => {
                return {
                    url: 'auth/google-signin',
                    method: 'POST',
                    body: params,
                }
            }
        }),
    }),
});

// Export hooks for usage in functional components
export const { useSignupMutation, useLoginMutation, useGoogleSignInMutation } = api;
export default api;
