import {
  Avatar,
  Button,
  Input,
  Option,
  Select,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { AddCircle, ArrowDown2, Gallery, Crown1 } from "iconsax-react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEditProfileMutation, useGetUserQuery } from "./profileApiSlice";
import { Country, State } from "country-state-city";
import * as Yup from "yup";
import { useFormik } from "formik";
import './profile.css';
import { setAccessToken, setUserDetails } from "../../Auth/authSlice";
import Loader from "../../../common/components/Loader";
import toast from "react-hot-toast";

const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken, userDetails } = useSelector((state) => state.authSlice);
  const [editProfile, { data: editProfileData, isLoading, isError }] = useEditProfileMutation();
  const { data } = useGetUserQuery({ userId: userDetails?._id, token: accessToken });
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(userDetails?.profileImg || "https://docs.material-tailwind.com/img/face-2.jpg");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [dob, setDob] = useState("")
  const [states, setStates] = useState([]);
  const countries = Country.getAllCountries(); //get all countries

  useEffect(() => {
    if (data) {
      dispatch(setUserDetails(data?.responseData)) //dispatch user details info
    }
  }, [data])

  useEffect(() => {
    setSelectedState(userDetails?.state);
    setDob(userDetails?.dateOfBirth)
  }, []) //set default state for states

  //initial formik values
  const initialValues = {
    fullName: userDetails?.fullName ? userDetails?.fullName : "",
    email: userDetails?.email ? userDetails?.email : "",
    phone: userDetails?.phoneNumber ? userDetails?.phoneNumber : "",
    country: userDetails?.country ? userDetails?.country : "",
    state: selectedState,
    dateOfBirth: dob,
    gender: userDetails?.gender ? userDetails?.gender : "",
    bio: userDetails?.bio ? userDetails?.bio : ""
  };

  //validation schema for formik
  const validationSchema = Yup.object({
    fullName: Yup.string(),
    email: Yup.string().email("Invalid email address"),
    phone: Yup.string(),
    country: Yup.string(),
    state: Yup.string(),
    dateOfBirth: Yup.string(),
    gender: Yup.string(),
    bio: Yup.string(),
  });


  //formik submit 
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //body to be sent in API
      let body = {
        fullName: values.fullName,
        country: values.country,
        dateOfBirth: dob,
        gender: values.gender,
        phoneNumber: values.phone,
        state: selectedState,
        bio: values.bio
      }

      // console.log(body)
      editProfile({
        userId: userDetails?._id,
        body: body,
        token: accessToken
      }); //update profile 
    }
  });

  // console.log(data, userDetails, formik.values)

  //selected states based on country code
  useEffect(() => {
    if (selectedCountry || userDetails?.country) {
      let countryCode = countries.find(
        (country) => country.name === userDetails?.country
      );
      const countryStates = State.getStatesOfCountry(selectedCountry ? selectedCountry : countryCode?.isoCode);
      setStates(countryStates);
    } else {
      setStates([]);
    }
  }, [selectedCountry, formik.values.country]);

  const handleLogout = () => {
    dispatch(setAccessToken(null));
    dispatch(setUserDetails({}));
  }

  useEffect(() => {
    if (editProfileData) {
      toast.success(editProfileData?.message || "Profile edited successfully!")
    }
  }, [editProfileData])

  if (isError) { toast.error('An Error Occurred! Please try again.') };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="flex flex-col gap-4 relative w-fit">
        <Typography className="font-archivo font-bold text-base text-text-500">
          Profile Picture
        </Typography>
        <Avatar
          className="w-[123px] h-[123px] "
          src={imageURL || "https://docs.material-tailwind.com/img/face-2.jpg"}
          alt="avatar"
          size="xxl"
        />
        <label className="cursor-pointer">
          <input
            type="file"
            className="hidden"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => {
              setImage(e.target.files[0]);
              setImageURL(URL.createObjectURL(e.target.files[0]));
            }}
          />
          <Gallery
            size="30"
            className="absolute bottom-3 right-2 bg-white rounded-xl p-1 shadow-black/30 shadow-md"
          />
        </label>
      </div>

      {/* Form Section */}
      <div className="grid gap-8 rounded-xl py-5">
        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-6 border-r border-[#f1f1f3]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="w-full">
                <Input
                  value={formik.values.fullName}
                  name="fullName"
                  onChange={(e) => formik.handleChange(e)}
                  type="outlined"
                  placeholder="Enter Full Name"
                  className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[100px]" }}
                />
              </div>
              <div className="w-full">
                <Input
                  type="outlined"
                  value={formik.values.email}
                  name="email"
                  onChange={(e) => formik.handleChange(e)}
                  placeholder="Enter Email Address"
                  className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:!font-Be_Vietnam_Pro !font-Be_Vietnam_Pro !bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[100px]" }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="w-full">
                <Input
                  type="outlined"
                  value={formik.values.phone}
                  name="phone"
                  onChange={(e) => formik.handleChange(e)}
                  placeholder="Enter Phone Number"
                  className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:!font-Be_Vietnam_Pro !font-Be_Vietnam_Pro !bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[100px]" }}
                />
              </div>
              <div className="w-full">
                <Input
                  type="date"
                  variant="outlined"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  name="dateOfBirth"
                  placeholder="Date of Birth"
                  className="dob_input !border py-6 !rounded-lg !border-[#F1F1F3] placeholder:!font-Be_Vietnam_Pro !font-Be_Vietnam_Pro !bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[100px]" }}
                />
              </div>
            </div>
            <div className={`grid grid-cols-1 ${(states?.length > 0 || userDetails?.country) ? "md:grid-cols-2" : 'md:grid-cols-1'}  gap-6`}>
              <div className="w-full" data-lenis-prevent>
                <Select
                  variant="outlined"
                  placeholder="Enter Country"
                  label={formik.values.country == "" ? "Select Country" : ""}
                  name="country"
                  value={formik.values.country}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue("country", e);

                    // Find the selected country object by name
                    const selectedCountry = countries.find(
                      (country) => country.name === e
                    );

                    if (selectedCountry) {
                      // Set the selected country ISO code
                      setSelectedCountry(selectedCountry.isoCode);
                    }
                  }}
                  className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                  labelProps={{ className: "before:!border-0 after:!border-0 font-Be_Vietnam_Pro text-text-400" }}
                  containerProps={{ className: "min-w-[100px]" }}
                >
                  {countries.map((country) => (
                    <Option className="font-Be_Vietnam_Pro text-text-400 text-sm font-normal" key={country.isoCode} value={country.name}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </div>
              {states.length > 0 &&
                <div className="w-full" data-lenis-prevent>
                  <Select
                    variant="outlined"
                    name="state"
                    label={selectedState == "" ? "Select State" : ""}
                    value={userDetails?.state ? userDetails?.state : selectedState}
                    onChange={(e) => {
                      setSelectedState(e)
                    }}
                    placeholder="Enter State"
                    className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                    labelProps={{ className: "before:!border-0 after:!border-0 font-Be_Vietnam_Pro text-text-400" }}
                    containerProps={{ className: "min-w-[100px]" }}
                  >
                    {states?.map((state) => (
                      <Option className="font-Be_Vietnam_Pro text-text-400 text-sm font-normal" key={state.isoCode} value={state.name}>
                        {state.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              }
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="w-full" data-lenis-prevent>
                <Select
                  variant="outlined"
                  value={formik.values.gender}
                  name="gender"
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue("gender", e);
                  }}
                  className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:!ring-0"
                  labelProps={{ className: "hidden" }}
                  containerProps={{ className: "min-w-[100px]" }}
                >
                  <Option className="font-Be_Vietnam_Pro text-text-400 text-sm font-normal" value="">Select Gender</Option>
                  <Option className="font-Be_Vietnam_Pro text-text-400 text-sm font-normal" value="male">Male</Option>
                  <Option className="font-Be_Vietnam_Pro text-text-400 text-sm font-normal" value="female">Female</Option>
                  <Option className="font-Be_Vietnam_Pro text-text-400 text-sm font-normal" value="others">Others</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
              User Bio
            </Typography>
            <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
              Please add user bio which will be showcase under their profile.
            </Typography>

            <Textarea
              name="bio"
              value={formik.values.bio}
              onChange={(e) => formik.handleChange(e)}
              label="Message"
              placeholder="Enter Bio"
              className="!border py-6 my-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
              labelProps={{
                className: "hidden",
              }}
            />
          </div>

          <Button type="submit" className="shadow-none hover:shadow-none normal-case font-Be_Vietnam_Pro bg-primaryLight-500 text-sm font-medium float-end">
            Save
          </Button>
        </form>
      </div>


      <div className="mt-10">
        <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
          Membership
        </Typography>
        <Typography className="text-text-400 text-sm my-6 font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
          Your current membership status will be shown here.
        </Typography>
      </div>
      <div className="mt-4 mb-10">
        <Typography className="font-Be_Vietnam_Pro font-semibold text-base text-text-500">
          No, active membership currently
        </Typography>
        <Button
          className="bg-[#E7892C] text-white mt-10 flex font-Be_Vietnam_Pro items-center hover:shadow-none normal-case justify-center px-6 py-2 rounded-lg font-medium text-lg"
          onClick={() => {
            navigate("/membership");
            //  setPremium(true)
          }}
        >
          <span className="mr-2">
            <Crown1 />
          </span>{" "}
          Upgrade to Membership
        </Button>
        <Button
          variant="outlined"
          className="normal-case mt-10 border-[3px] border-[#FF4C00] text-[#FF4C00] font-semibold text-xl shadow-none hover:shadow-none ring-0 focus:ring-0"
          onClick={() => {
            handleLogout();
            navigate("/");
          }}
        >
          Logout
        </Button>
      </div>
    </>
  );
};

export default ProfileSettings;
