import { Button, Typography } from "@material-tailwind/react";
import OurFaqs from "../../common/components/OurFaqs"
import OurTestimonials from "../../common/components/OurTestimonials"
import { UpcomingCard } from "./UpcomingCard";


const AllHackathon = () => {

    const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9]

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <div className="">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        All Hackathons
                    </Typography>
                    <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>
                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="flex items-center flex-wrap gap-8">
                        {arr.map((item, index) => (
                            <UpcomingCard key={index} type="upcoming" />
                        ))}
                    </div>


                </div>
                <div className="mt-20">
                    <OurTestimonials />
                </div>
                <div className="mt-20">
                    <OurFaqs />
                </div>
            </div>
        </>
    )
}

export default AllHackathon;