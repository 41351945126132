import { Button, Checkbox, Input, Typography } from "@material-tailwind/react";
import Testimonials from "../../../common/components/Testimonials";
import googleIcon from '../../../assets/images/Icon.png';
import { MdOutlineArrowOutward } from "react-icons/md";
import { useNavigate } from "react-router";
import { useLoginMutation } from "../../../config/Apiconfig";
import { loginSchema } from "../validationSchema";
import { useFormik } from "formik";
import Loader from "../../../common/components/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken, setUserDetails } from "../authSlice";
import GoogleSignIn from "../GoogleSignIn";
import { Eye, EyeSlash } from "iconsax-react";
import toast from "react-hot-toast";

const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isPasswordVisible, setIspasswordVisible] = useState(false);
    const [login, { data, isLoading, isError }] = useLoginMutation();
    const { accessToken, userDetails } = useSelector((state) => state.authSlice);

    // formik handler
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: loginSchema,
        onSubmit: (values) => {
            login(values)
        }
    });

    useEffect(() => {
        if (data) {
            toast.success("User logged in successfully!")
            dispatch(setUserDetails(data?.responseData));
            dispatch(setAccessToken(data?.accessToken));
            navigate("/");
        }
    }, [data])

    if (isError) { toast.error('Login failed! Please try again.') };

    if (isLoading) return <Loader />;

    return (
        <>
            <div className="mt-10 lg:m-20 flex gap-20 h-full items-center justify-center">
                <div className="w-1/2 hidden lg:block">
                    <Testimonials />
                </div>
                <div className="bg-white w-full lg:w-1/2 p-4 md:p-8 lg:p-12 rounded-xl">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="flex flex-col items-center justify-center gap-2">
                            <Typography className="font-Be_Vietnam_Pro text-4xl font-bold text-text-500">
                                Login
                            </Typography>
                            <Typography className="font-Be_Vietnam_Pro text-base font-normal text-text-400">
                                Welcome back! Please log in to access your account.
                            </Typography>
                        </div>

                        <div className="pt-10 flex flex-col gap-6">
                            <div className="w-full">
                                <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                                    Email
                                </Typography>
                                <Input
                                    type="email"
                                    name="email"
                                    placeholder="Email Address"
                                    value={formik.values.email}
                                    onChange={(e) => formik.handleChange(e)}
                                    className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    containerProps={{ className: "min-w-[100px]" }}
                                />
                                {formik.errors.email &&
                                    <Typography className="font-Be_Vietnam_Pro text-xs font-medium text-error-500 mt-3">
                                        {formik.errors.email}
                                    </Typography>
                                }
                            </div>
                            <div className="w-full">
                                <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                                    Password
                                </Typography>
                                <Input
                                    type={!isPasswordVisible ? "password" : "text"}
                                    name="password"
                                    placeholder="Enter your password"
                                    value={formik.values.password}
                                    onChange={(e) => formik.handleChange(e)}
                                    className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    containerProps={{ className: "min-w-[100px]" }}
                                    icon={
                                        !isPasswordVisible ?

                                            <div className="mt-1 cursor-pointer text-[20px]" onClick={() => setIspasswordVisible(true)}>
                                                <Eye variant="Bold" />
                                            </div>
                                            :
                                            <div className="mt-1 cursor-pointer text-[20px]" onClick={() => setIspasswordVisible(false)}>
                                                <EyeSlash variant="Bold" />
                                            </div>
                                    }
                                />
                                {formik.errors.password &&
                                    <Typography className="font-Be_Vietnam_Pro text-xs font-medium text-error-500 mt-3">
                                        {formik.errors.password}
                                    </Typography>
                                }
                            </div>
                        </div>

                        <div className="flex items-end justify-end mt-5 cursor-pointer">
                            <Typography className="font-Be_Vietnam_Pro text-sm text-text-400 font-medium">
                                Forgot Password?
                            </Typography>
                        </div>

                        <Checkbox color="blue" ripple={false}
                            label={
                                <Typography className="font-Be_Vietnam_Pro text-sm text-text-400 font-medium">
                                    Remember me
                                </Typography>
                            }
                        />

                        <Button type="submit"
                            className="w-full bg-primaryLight-500 mt-5 normal-case font-medium text-base font-Be_Vietnam_Pro shadow-none hover:shadow-none"
                        >
                            Login
                        </Button>
                    </form>
                    <div className="relative flex py-5 items-center">
                        <div className="flex-grow border-t border-[#E4E4E7]"></div>
                        <span className="flex-shrink mx-4 text-[#E4E4E7] font-Be_Vietnam_Pro">or</span>
                        <div className="flex-grow border-t border-[#E4E4E7]"></div>
                    </div>
                    <GoogleSignIn />

                    <Typography className="font-Be_Vietnam_Pro text-sm text-text-400 font-medium w-full flex items-center justify-center mt-5 gap-1">
                        Don't have an account?
                        <span className="underline underline-offset-2 font-semibold cursor-pointer" onClick={() => navigate("/signup")}>Sign Up</span>
                        <MdOutlineArrowOutward className="text-base" />
                    </Typography>
                </div>
            </div>
        </>
    )
}

export default Login;