import { Button, Chip, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router";

export const UpcomingCard = ({ type }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="bg-white mt-10 p-3 rounded-xl w-full lg:w-[31.4%]">
                <img className="h-[300px] w-full rounded-xl object-cover" src="https://images.unsplash.com/photo-1510925758641-869d353cecc7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YXJjaGVyeXxlbnwwfHwwfHx8MA%3D%3D" />
                <div className="mt-5 flex items-center justify-between">
                    <div className="flex items-start justify-start gap-2">
                        <Chip value="Beginner" className="w-fit normal-case text-green-500  bg-green-50 font-medium text-xs font-Be_Vietnam_Pro" />
                        <Chip value="4 weeks" className="w-fit normal-case text-text-500  bg-transparent border border-text-50 font-medium text-xs font-Be_Vietnam_Pro" />
                    </div>
                    <Typography className="text-text-500 font-Be_Vietnam_Pro font-medium text-base">
                        By John Smith
                    </Typography>
                </div>

                <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-base mt-5">
                    Cyber Security Hackathon
                </Typography>
                <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-base mt-2">
                    Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                    Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                </Typography>
                {type !== "enrolled" &&
                    <Typography className="text-primaryLight-500 font-Be_Vietnam_Pro font-semibold text-base mt-2">
                        ₹ 5000
                    </Typography>
                }

                {type === "played" || type === "bootcamp" ?
                    <Button
                        onClick={() => type === "played" ? navigate("/hackathon/details/0") : navigate("/bootcamp/details/0")}
                        variant="outlined"
                        className="ring-0 focus:ring-0 border-2 border-primaryLight-500 text-primaryLight-500 text-base font-semibold font-Be_Vietnam_Pro normal-case w-full mt-5"
                    >
                        View Insights
                    </Button>
                    :
                    type === "enrolled" ?
                        <Button
                            // onClick={() => type === "played" ? navigate("/hackathon/details/0") : navigate("/bootcamp/details/0")}
                            variant="filled"
                            className="bg-primaryLight-500/20 text-primaryLight-500 mt-3 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full"
                        >
                            Scheduled on 29.10.24 (09:00 PM)
                        </Button>
                        :
                        <Button
                            onClick={() => navigate("/checkout/hackathon")}
                            variant="outlined"
                            className="ring-0 focus:ring-0 border-2 border-primaryLight-500 text-primaryLight-500 text-base font-semibold font-Be_Vietnam_Pro normal-case w-full mt-5"
                        >
                            Enroll Now
                        </Button>
                }
            </div>
        </>
    )
}