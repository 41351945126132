import hackathonTabSlice from '../containers/Hackathon/hackathonTabSlice';
import bootcampSlice from '../containers/Pages/Bootcamp/bootcampSlice';
import coursesTabSlice from '../containers/Pages/Courses/coursesTabSlice';
import authSlice from '../containers/Auth/authSlice';

const rootReducer = {
    hackathonTabSlice,
    bootcampSlice,
    coursesTabSlice,
    authSlice
};

export default rootReducer;