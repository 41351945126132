import React from "react";
import PageHeader from "../../../common/components/PageHeader";
import { Button, Typography } from "@material-tailwind/react";
import shape from "../../../assets/images/shape-17.svg";
import drona1 from "../../../assets/images/drona1.png";
import drona2 from "../../../assets/images/drona2.png";
import drona3 from "../../../assets/images/drona3.png";
import img1 from '../../../assets/images/Project_Drona-1.jpg';
import img2 from '../../../assets/images/Project_Drona-2.jpg';
import img3 from '../../../assets/images/Drona-8.jpg';
import img4 from '../../../assets/images/Drona-6.jpg';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const ProjectDrona = () => {
  const arr = [
    {
      data: "Awareness Program for online Safety against cyber crimes.",
      images: drona1,
    },
    {
      data: "National outreach measure to bridge the gap between the colleges and the industry.",
      images: drona2,
    },
    {
      data: "Certification Program for various domains of Information security.",
      images: drona3,
    },
  ];
  return (
    <>
      <div className="p-[4vw]">
        <PageHeader
          spanText="Project"
          text="Drona"
          subHead1="“The making of cyber warriors”"
          subHead2="A strategic effort to safeguard India's digital landscape by creating a capable, informed, and industry-ready cybersecurity workforce."
        />
        {/* <div className="flex flex-col lg:flex-row w-full justify-center gap-3 mt-2">
          <Button className="bg-primaryLight-500 text-[#ffffff] font-semibold text-base normal-case shadow-none hover:shadow-none font-Be_Vietnam_Pro w-full lg:w-fit">
            Explore Courses
          </Button>
          <Button className="bg-text-50 text-text-500 font-semibold text-base normal-case border-[1px] shadow-none hover:shadow-none font-Be_Vietnam_Pro border-text-50 w-full lg:w-fit">
            View Pricing
          </Button>
        </div> */}

        <div className="flex flex-col md:flex-row items-start md:items-center justify-between mt-20 border-b border-[#E4E4E7] pb-10 mb-10">
          <Typography className="text-text-500 font-Be_Vietnam_Pro text-4xl font-semibold w-full md:w-1/4">
            Purpose
          </Typography>
          <div className="w-full md:w-3/4 flex flex-col gap-4">
            <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm text-justify leading-[23px]">
              <span className="font-bold">Project Drona</span> is an ambitious initiative by the <span className="font-bold">Council of Information Security (CIS)</span> designed
              to address the growing need for cybersecurity expertise by developing skilled
              professionals, referred to as "cyber warriors." Through a structured, multi-faceted
              approach, Project Drona focuses on empowering individuals with the knowledge and
              skills necessary to tackle today's cyber threats effectively.
            </Typography>
            <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm text-justify leading-[23px]">
              It aligns closely with the frameworks established by the <span className="font-bold">National Skill
                Development Corporation (NSDC)</span> and <span className="font-bold">National Occupational Standards (NOS)</span>,
              ensuring that its training programs meet recognized standards of quality and relevance.
              By aligning with these standards, the project not only provides industry-approved
              training but also ensures that its graduates are well-prepared for real-world challenges.
            </Typography>
          </div>
        </div>

        <div className="my-20">
          <Swiper
            spaceBetween={100}
            navigation={true}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="w-[88.2vw] h-[500px] rounded-xl">
                <img src={img1} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[88.2vw] h-[500px] rounded-xl">
                <img src={img2} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="w-[88.2vw] h-[500px] rounded-xl">
                <img src={img3} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="w-[88.2vw] h-[500px] rounded-xl">
                <img src={img4} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="w-full h-full">
          <Typography className="text-4xl font-medium text-text-500 mb-1 font-Be_Vietnam_Pro">
            Our program includes
          </Typography>
          <Typography className="text-base text-justify font-normal text-text-400 font-Be_Vietnam_Pro">
            Project Drona is structured into several specific programs, each designed to address
            different aspects of cybersecurity awareness, skill development, and professional
            certification.
          </Typography>
        </div>

        <div className="flex flex-wrap items-center gap-7">
          {arr.map((item, index) => (
            <div
              className="bg-white mt-10 rounded-xl w-full lg:w-[31.4%]  border-b-[6px] border-[#2C6DE6A1] border-opacity-65"
              key={index}
            >
              <img
                className="h-[300px] w-full rounded-xl object-contain"
                src={item?.images}
              />

              <div className="px-[2vw] h-[200px] flex justify-center items-center">
                <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-xl">
                  {item.data}
                </Typography>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-20">
          <Typography className="text-4xl font-medium text-text-500 mb-1 font-Be_Vietnam_Pro">
            Why Project Drona Matters
          </Typography>
          <Typography className="text-base text-justify font-normal text-text-400 font-Be_Vietnam_Pro mt-2">
            As cyber threats become more complex and pervasive, having a well-trained
            cybersecurity workforce is essential for protecting national and organizational interests.
            Project Drona plays a crucial role by:
          </Typography>

          <ul className="!list-disc ml-10 flex flex-col gap-2 mt-3">
            <li className="font-Be_Vietnam_Pro text-base text-text-400 text-justify font-normal">Expanding access to cybersecurity education, especially in underserved areas.</li>
            <li className="font-Be_Vietnam_Pro text-base text-text-400 text-justify font-normal">Ensuring training quality through alignment with national standards.</li>
            <li className="font-Be_Vietnam_Pro text-base text-text-400 text-justify font-normal">Building a pipeline of skilled professionals equipped to respond to cyber
              challenges in various sectors.</li>
          </ul>
        </div>
        <div className="bg-white flex flex-col lg:flex-row mb-[4vw] items-center justify-between rounded-xl mt-20">
          <div className="w-full lg:w-2/4 p-10">
            <Typography className="text-text-500 font-Be_Vietnam_Pro text-4xl font-semibold">
              <span className="text-primaryLight-500">Together</span>, let's shape
              the future of cyber world!
            </Typography>

            <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm mt-2">
              Join us on this exciting learning journey and unlock your potential
              in cyber security.
            </Typography>
          </div>
          <img src={shape} className="hidden lg:block" />

          <Button
            variant="filled"
            size="sm"
            className="bg-primaryLight-500 mr-10 mb-10 lg:mb-0 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium text-white py-2 px-4"
          >
            Join Now
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProjectDrona;
