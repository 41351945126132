import { Button, Typography } from "@material-tailwind/react";
import { Flash } from "iconsax-react";
import line from "../../../assets/images/Abstract Line.svg";
import { GoArrowUpRight, GoDotFill } from "react-icons/go";
import OurComponent from "../../../common/components/OurComponent";
import OurTestimonials from "../../../common/components/OurTestimonials";
import OurFaqs from "../../../common/components/OurFaqs";
import OurPricing from "../../../common/components/OurPricing";
import { Card } from "../AboutUs";
import robo from "../../../assets/images/robo.png";
import enlighten from "../../../assets/images/enlighten_world.png";
import aiml from "../../../assets/images/aiml.png";
import rev from "../../../assets/images/rev.png";
import play from "../../../assets/images/play.png";
import { useDispatch } from "react-redux";
import PageHeader from "../../../common/components/PageHeader";
import { setCourseActiveTab } from "../Courses/coursesTabSlice";
import { useNavigate } from "react-router";
import become from '../../../assets/images/becomecismember.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import img1 from '../../../assets/images/CIS-2.jpg';
import img2 from '../../../assets/images/CIS-3.jpg';
import img3 from '../../../assets/images/Drona-5.jpg';
import img4 from '../../../assets/images/CIS-13.jpg';
import img5 from '../../../assets/images/CIS-16.jpg';
import img6 from '../../../assets/images/CIS14.jpg';
import challengeBg from '../../../assets/images/challenges.webp';
import botocampbg from '../../../assets/images/bootcampbg.png';
import callforpaperBg from '../../../assets/images/callforpaper.png';
import submitBg from '../../../assets/images/submitbg.webp';

const SidePlaylistCard = () => {
  return (
    <>
      <div className="flex items-start flex-col sm:flex-row justify-start  gap-5 bg-white rounded-xl w-full p-2 pr-6">
        <img
          className="w-[150px] h-[80px] rounded-xl"
          src="https://images.unsplash.com/photo-1496483353456-90997957cf99?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fHJhbmRvbXxlbnwwfHwwfHx8MA%3D%3D"
        />
        <div className="">
          <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-base">
            Cyber Security Updates
          </Typography>
          <Typography className="flex items-center justify-start gap-2 text-sm text-text-400 font-Be_Vietnam_Pro font-medium">
            3.5M views <GoDotFill /> 1 year ago
          </Typography>
        </div>
      </div>
    </>
  );
};

export const PodcastCard = () => {
  return (
    <>
      <div className="flex items-start flex-col sm:flex-row justify-start gap-5 w-full pb-6 border-b">
        <img
          className="w-[85px] h-[85px] rounded-xl"
          src="https://images.unsplash.com/photo-1496483353456-90997957cf99?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fHJhbmRvbXxlbnwwfHwwfHx8MA%3D%3D"
        />
        <div className="w-3/4 pr-6">
          <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-base">
            Designer's Life eps. 5
          </Typography>
          <Typography className="text-xs text-text-400 font-Be_Vietnam_Pro font-normal">
            by Richard Mile
          </Typography>
          <div className="flex items-end justify-between">
            <Typography className="text-xs text-text-400 font-Be_Vietnam_Pro font-normal">
              12:30/<span className="text-primaryLight-500">25:59</span>
            </Typography>
            <img src={play} />
          </div>
        </div>
      </div>
    </>
  );
};

export const MediaCard = () => {
  return (
    <>
      <div className="flex items-start sm:items-center flex-col sm:flex-row justify-start gap-5 w-full pb-6 border-b">
        <img
          className="w-[85px] h-[85px] rounded-xl"
          src="https://images.unsplash.com/photo-1496483353456-90997957cf99?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fHJhbmRvbXxlbnwwfHwwfHx8MA%3D%3D"
        />
        <div className="w-[90%] pr-6">
          <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-base">
            Himachal gets first 24*7 cybercrime reporting station
          </Typography>
          <Typography className="text-xs text-text-400 font-Be_Vietnam_Pro font-normal mt-2">
            Equipped to handle cybercrime-related complaints more efficiently,
            the CY-Station would also facilitate the blocking or lien marking of
            defrauded amounts through the Citizen Financial Cyber Fraud
            Reporting and Management System (CFCFRMS)
          </Typography>
        </div>
      </div>
    </>
  );
};

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bannerBox1 = [
    {
      name: "New Challenges",
      number: "01",
      redirectUrl: "yes",
      img: challengeBg,
      link: '/hackathon'
    },
    {
      name: "Upcoming Bootcamps",
      number: "02",
      redirectUrl: "yes",
      img: botocampbg,
      link: "/bootcamp"
    },
    {
      name: "Call for Paper",
      number: "03",
      redirectUrl: "yes",
      img: callforpaperBg,
      link : "/call-for-paper"
    },
    {
      name: "Submit Your Article and win!",
      number: "04",
      redirectUrl: "yes",
      img: submitBg,
      link : "/submit-article-win"
    },
  ];

  const bannerBox2 = [
    {
      name: "Individual",
      img: "https://media.istockphoto.com/id/1369745788/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=2048x2048&w=is&k=20&c=XxZtwneAHjl0zdQ1YcvkT7PUL8WkpYRDsprz7kNZ3Sk=",
    },
    {
      name: "University",
      img: "https://media.istockphoto.com/id/1369745788/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=2048x2048&w=is&k=20&c=XxZtwneAHjl0zdQ1YcvkT7PUL8WkpYRDsprz7kNZ3Sk=",
    },
    {
      name: "Corporate",
      img: "https://media.istockphoto.com/id/1369745788/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=2048x2048&w=is&k=20&c=XxZtwneAHjl0zdQ1YcvkT7PUL8WkpYRDsprz7kNZ3Sk=",
    },
    {
      name: "Open",
      img: "https://media.istockphoto.com/id/1369745788/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=2048x2048&w=is&k=20&c=XxZtwneAHjl0zdQ1YcvkT7PUL8WkpYRDsprz7kNZ3Sk=",
    },
  ];

  const products = [
    {
      img: <img src={enlighten} />,
      heading: "Enlighten World",
      text: "Enlighten World is a non profit, registered trust which has an aim of assisting people live their dreams.",
    },
    {
      img: <img src={aiml} />,
      heading: "AIML Global",
      text: "AIML Global, an initiative by the Council of Information Security (CIS), is dedicated to advancing education and innovation in Artificial Intelligence and Machine Learning.",
    },
    {
      img: <img src={rev} />,
      heading: "Cyber Revolution",
      text: "Initiative is committed to the eradication of cyber-slavery and the establishment of digital autonomy through equitable development and utilization of artificial intelligence (AI).",
    },
    {
      img: <img src={robo} />,
      heading: "AiRobo",
      text: "AiRobo, an initiative by the Council of Information Security (CIS), focuses on fostering innovation and education in the fields of Artificial Intelligence and Robotics.",
    },
  ];

  const arr = [1, 2, 3, 4, 5, 6, 7];

  return (
    <div className="w-full h-full flex flex-wrap justify-center items-center lg:px-[4vw] md:px-[4vw] sm:px-[2vw] px-[2vw]">
      <div className="h-full w-full py-[2vh] sm:py-[4vh] md:py-[6vh] lg:py-[10vh] flex flex-wrap gap-[5vw] justify-center max-w-[1596px]">
        <div>
          <PageHeader spanText="Unlock" text="Your Creative Potential" />
          {/* <div className="flex items-center justify-center flex-col lg:flex-row mt-10 gap-5">
            <Button
              onClick={() => navigate("/courses")}
              className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
            >
              Explore Courses
            </Button>
            <Button
              onClick={() => navigate("/membership")}
              className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
            >
              View Pricing
            </Button>
          </div> */}
        </div>

        {/* <div className="w-full h-full">
          <img
            src="https://i.imgur.com/vV04ZZN.gif"
            alt="map"
            className="w-full h-full rounded-2xl"
          />
        </div> */}

        <div className="w-full h-full">
          <Swiper
            spaceBetween={100}
            navigation={true}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="w-[88.2vw] h-[600px] rounded-xl">
                <img src={img1} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[88.2vw] h-[600px] rounded-xl">
                <img src={img2} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[88.2vw] h-[600px] rounded-xl">
                <img src={img3} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[88.2vw] h-[600px] rounded-xl">
                <img src={img4} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[88.2vw] h-[600px] rounded-xl">
                <img src={img5} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[88.2vw] h-[600px] rounded-xl">
                <img src={img6} className="w-full h-full object-cover rounded-xl" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>


        <div
          className="w-full h-full grid gap-5"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          }}
        >
          {bannerBox1.map((item, index) => {
            return (
              <div
                onClick={() => navigate(item.link)}
                key={index}
                className="cursor-pointer w-full h-full min-h-[350px] bg-[#ffffff] rounded-[12px] shadow-none px-8 py-6 flex flex-col justify-between"
                style={{
                  backgroundImage: `url(${item.img})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {/* <Typography className="text-7xl font-bold text-text-500 font-Be_Vietnam_Pro mb-10">
                  {item?.number}
                </Typography> */}
                {/* <div className="flex flex-row justify-between items-end text-3xl font-semibold text-text-500 font-Be_Vietnam_Pro">
                  {item?.name}{" "}
                  <div className="p-4 bg-text-100 flex justify-center items-center rounded-lg text-primaryLight-500 cursor-pointer">
                    <GoArrowUpRight />
                  </div>
                </div> */}
              </div>
            );
          })}
        </div>

        <div className="w-full h-full">
          <Typography className="text-4xl font-medium text-text-500 mb-1 font-Be_Vietnam_Pro">
            Our Programs
          </Typography>
          <div className="flex w-full justify-between flex-col md:flex-row lg:flex-row xl:flex-row mb-16 gap-[3vw]">
            <Typography className="text-base font-Be_Vietnam_Pro text-justify font-normal text-text-400 max-w-6xl">
              Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam
              eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac
              cum eget habitasse in velit fringilla feugiat senectus in.
            </Typography>
            <Button className="bg-[#fcfcfd] text-text-500 font-Be_Vietnam_Pro font-medium text-base normal-case shadow-none hover:shadow-none min-w-[112px] max-h-[56px]">
              View All
            </Button>
          </div>
          <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-rows-4 md:grid-rows-2 lg:grid-rows-1 gap-5">
            {bannerBox2.map((item, index) => {
              return <OurComponent data={item} key={index} />;
            })}
          </div>
        </div>

        <div className="">
          <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
            Our Products
          </Typography>
          <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm w-3/4 mt-2">
            Our commitment to excellence has led us to achieve significant
            milestones along our journey. Here are some of our notable
            achievements
          </Typography>

          <div className="mt-10 flex flex-wrap gap-8">
            {products.map((item, index) => (
              <Card
                key={index}
                img={item.img}
                heading={item.heading}
                text={item.text}
              />
            ))}
          </div>
        </div>

        <div className="w-full">
          <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
            Video Spotlight
          </Typography>
          <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2">
            <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
              Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam
              eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac
              cum eget habitasse in velit fringilla feugiat senectus in.
            </Typography>

            <Button className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit">
              View All
            </Button>
          </div>

          <div className="mt-14 flex flex-col lg:flex-row items-start justify-between gap-10 w-full">
            <iframe
              className="rounded-xl w-full lg:w-2/3 h-[300px] lg:h-[480px]"
              width="847"
              height="480"
              src="https://www.youtube.com/embed/v-H-pxNRq-U"
              title="The making of Cyber Warriors by CIS | Council of Information Security"
              // frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>

            <div
              data-lenis-prevent
              className="w-full lg:w-1/3 flex flex-col items-start justify-start gap-5 overflow-auto max-h-[480px]"
            >
              {arr.map((item, index) => (
                <SidePlaylistCard key={index} />
              ))}
            </div>
          </div>
        </div>

        <div className="flex items-start justify-between flex-col lg:flex-row gap-10 w-full">
          <div className="w-full lg:w-1/3 ">
            <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
              Podcast
            </Typography>

            <div
              data-lenis-prevent
              className="bg-white rounded-xl p-5 mt-6 w-full flex flex-col items-start justify-start gap-5 overflow-auto max-h-[520px]"
            >
              {arr.map((item, index) => (
                <PodcastCard key={index} />
              ))}
            </div>
          </div>
          <div className="w-full lg:w-2/3">
            <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
              Media & Events
            </Typography>
            <div
              data-lenis-prevent
              className="bg-white rounded-xl p-5 mt-6 w-full flex flex-col items-start justify-start gap-5 overflow-auto max-h-[520px]"
            >
              {arr.map((item, index) => (
                <MediaCard key={index} />
              ))}
            </div>
          </div>
        </div>

        <OurTestimonials />
        <div className="">
          <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
            Become a CIS Member
          </Typography>

          <img src={become} className="rounded-xl mt-10" />
        </div>
        {/* <OurPricing /> */}
        <OurFaqs />
      </div>
    </div>
  );
};

export default LandingPage;
