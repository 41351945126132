import PageHeader from "../../../common/components/PageHeader";
import bg from '../../../assets/images/Group.svg';
import { Button, Input } from "@material-tailwind/react";
import { Key } from "iconsax-react";
import { useNavigate } from "react-router";

const Certification = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">  
                <PageHeader
                    spanText="Verify"
                    text="Your Certificate"
                    subHead1=""
                    subHead2="Enter the serial number provided on your certificate to verify it"
                />

                <div className="flex items-center justify-center my-14 flex-col">
                    <img src={bg} />
                </div>

                <div className="items-center flex w-full justify-center">
                    <div className="relative flex w-full gap-2 md:w-max">
                        <Input
                            type="text"
                            placeholder="Enter Serial Number"
                            containerProps={{
                                className:
                                    "min-w-[350px]",
                            }}
                            className="!pl-12 !border py-6 !rounded-lg !border-text-400 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-text-400 focus:ring-0"
                            labelProps={{
                                className: "hidden",
                            }}
                        />
                        <div className="!absolute left-3 top-[15px] pr-2 border-r border-text-400/50">
                            <Key size="18" className="text-text-400" />
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-center mt-5 lg:mt-10">
                    <Button onClick={() => navigate("/certificate/verification")}
                        className="w-full lg:w-2/4 bg-primaryLight-500 mt-5 normal-case font-medium text-base font-Be_Vietnam_Pro shadow-none hover:shadow-none"
                    >
                        Get Started
                    </Button>
                </div>

            </div>
        </>
    )
}

export default Certification;