import { Button, Typography } from "@material-tailwind/react"
import { MdArrowOutward } from "react-icons/md"

export const BenefitCard = ({ index, heading, text }) => {
    return (
        <>
            <div className="bg-white w-full lg:w-[31.4%] p-10 rounded-xl">
                <Typography className="text-5xl text-text-500 font-Be_Vietnam_Pro font-bold text-right">
                    0{index + 1}
                </Typography>
                <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-lg mt-3">
                    {heading}
                </Typography>
                <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm mt-2">
                    {text}
                </Typography>
                <div className="flex items-end justify-end mt-5">
                    <Button variant="outlined" className="text-primaryLight-500 border border-text-50 bg-text-100 text-base ring-0 focus:ring-0 p-3">
                        <MdArrowOutward />
                    </Button>
                </div>
            </div>
        </>
    )
}