import React, { useState } from "react";
import { Typography, Input, Textarea, Button } from "@material-tailwind/react";
import { IoEye } from "react-icons/io5";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { FaEnvelope } from "react-icons/fa";
import { DocumentUpload } from "iconsax-react";

const CareerPage = () => {
  const [file, setFile] = useState();
  // const [fileURL, setFileURL] = useState();
  return (
    <div className="p-[4vw]">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between border-b border-[#E4E4E7] pb-10">
        <Typography className="text-text-500 font-Be_Vietnam_Pro text-4xl font-semibold w-full md:w-1/2 lg:w-1/2 sm:w-full">
          Career
        </Typography>
        <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm w-full md:w-1/2 lg:w-1/2 sm:w-full">
          CIS is more than just a workplace – it’s a community of like-minded professionals driven by a shared purpose. Here, you’ll have the opportunity to work on meaningful projects, collaborate with industry experts, and develop skills that propel your career forward. We believe in nurturing talent and providing our team with the resources, training, and support needed to succeed in an ever-evolving cybersecurity landscape. Join us and grow with a team that’s dedicated to making a difference.
        </Typography>
      </div>

      {/* Form Section */}
      <div className="mt-20 grid grid-cols-1 md:grid-cols-2 gap-8 rounded-xl bg-white">
        <div className="space-y-6 p-[3vw] border-r border-[#f1f1f3]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="w-full">
              <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                First Name
              </Typography>
              <Input
                type="outlined"
                placeholder="First Name"
                className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                labelProps={{
                  className: "hidden",
                }}
                containerProps={{ className: "min-w-[100px]" }}
              />
            </div>
            <div className="w-full">
              <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                Last Name
              </Typography>
              <Input
                type="outlined"
                placeholder="Last Name"
                className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                labelProps={{
                  className: "hidden",
                }}
                containerProps={{ className: "min-w-[100px]" }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="w-full">
              <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                Email
              </Typography>
              <Input
                type="email"
                placeholder="Email Address"
                className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                labelProps={{
                  className: "hidden",
                }}
                containerProps={{ className: "min-w-[100px]" }}
              />
            </div>
            <div className="w-full">
              <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                Phone Number
              </Typography>
              <Input
                type="text"
                placeholder="Enter your phone number"
                className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                labelProps={{
                  className: "hidden",
                }}
                containerProps={{ className: "min-w-[100px]" }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div className="w-full">
              <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                Job Role
              </Typography>
              <Input
                type="text"
                placeholder="Enter your Job Role"
                className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                labelProps={{
                  className: "hidden",
                }}
                containerProps={{ className: "min-w-[100px]" }}
              />
            </div>
          </div>

          <div className="w-full">
            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
              Upload Resume/CV
            </Typography>

            <div className="w-full flex items-center my-6">
              <label className="cursor-pointer w-full ">
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <div className="!border py-6 !rounded-lg !border-[#F1F1F3] placeholder:font-Be_Vietnam_Pro font-Be_Vietnam_Pro !bg-[#FCFCFD] text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0 flex justify-center items-center flex-col">
                  <DocumentUpload
                    size="37"
                    className="text-text-400 p-2 pb-0 rounded-full"
                  />
                  <Typography className="font-Be_Vietnam_Pro font-medium text-text-200 text-sm mt-2">
                    Upload or Drop PDF file here
                  </Typography>
                </div>
              </label>
            </div>
          </div>
        </div>

        {/* Contact Info Section */}
        <div className="space-y-6 py-[3vw] h-full w-full flex flex-col justify-between items-center">
          <div className="flex items-center justify-center space-x-2 w-full h-full">
            <div className="flex flex-col items-center justify-center text-text-400 gap-5 py-5 lg:max-w-[60%] md:max-w-[75%] sm:max-w-[55%] max-w-[90%] w-full px-5 h-full border-2 rounded-xl border-[#f1f1f3] bg-[#fcfcfd]">
              <div className="text-2xl bg-text-100 p-3 rounded-lg">
                <FaEnvelope className="text-text-400" />
              </div>
              <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm">
                support@councilinfosec.org
              </Typography>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-2 w-full h-full">
            <div className="flex flex-col items-center justify-center text-text-400 gap-5 py-5 lg:max-w-[60%] md:max-w-[75%] sm:max-w-[55%] max-w-[90%] w-full px-5 h-full border-2 rounded-xl border-[#f1f1f3] bg-[#fcfcfd]">
              <div className="text-2xl bg-text-100 p-3 rounded-lg">
                <IoCall className="text-text-400" />
              </div>
              <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm">
                +91 00000 00000
              </Typography>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-2 w-full h-full">
            <div className="flex flex-col items-center justify-center text-text-400 gap-5 py-5 lg:max-w-[60%] md:max-w-[75%] sm:max-w-[55%] max-w-[90%] w-full px-5 h-full border-2 rounded-xl border-[#f1f1f3] bg-[#fcfcfd]">
              <div className="text-2xl bg-text-100 p-3 rounded-lg">
                <IoLocationSharp className="text-text-400" />
              </div>
              <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm">
                Somewhere in the World
              </Typography>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-2 w-full h-full">
            <div className="flex flex-col items-center justify-center text-text-400 gap-5 py-5 lg:max-w-[60%] md:max-w-[75%] sm:max-w-[55%] max-w-[90%] w-full px-5 h-full border-2 rounded-xl border-[#f1f1f3] bg-[#fcfcfd]">
              <div className="flex items-start gap-2">
                <div className="text-2xl bg-text-100 p-3 rounded-lg">
                  <FaFacebook className="text-text-400" />
                </div>
                <div className="text-2xl bg-text-100 p-3 rounded-lg">
                  <FaTwitter className="text-text-400" />
                </div>
                <div className="text-2xl bg-text-100 p-3 rounded-lg">
                  <FaLinkedin className="text-text-400" />
                </div>
              </div>
              <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm">
                Social Profiles
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerPage;
