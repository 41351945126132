import { Button, Typography } from "@material-tailwind/react";
import PageHeader from "../../../common/components/PageHeader";
import lpu from '../../../assets/images/lpu-logo-png-image.svg';
import iit from '../../../assets/images/iit.png';
import amity from '../../../assets/images/amity.png';
import cept from '../../../assets/images/CEPT_new_logo.png';
import faces from '../../../assets/images/Vector.svg';
import { Card } from "../AboutUs";
import { FaCrown, FaMedal } from "react-icons/fa";
import { PiShieldStarFill } from "react-icons/pi";
import { BsBackpackFill, BsPuzzleFill } from "react-icons/bs";
import { ImBook } from "react-icons/im";
import { MdWbTwighlight } from "react-icons/md";
import shape from '../../../assets/images/shape-17.svg';
import circle from '../../../assets/images/univ.svg';

const Universities = () => {

    const achievements = [
        {
            icon: <FaCrown className="text-primaryLight-500 text-lg" />,
            heading: "Trusted by Thousands",
            text: "We have successfully served thousands of students, helping them unlock their potential and achieve their career goals."
        },
        {
            icon: <FaMedal className="text-primaryLight-500 text-lg" />,
            heading: "Award-Winning Courses",
            text: "Our courses have received recognition and accolades in the industry for their quality, depth of content, and effective teaching methodologies."
        },
        {
            icon: <img src={faces} />,
            heading: "Positive Hackathon Feedbacks",
            text: "We take pride in the positive feedback we receive from our users, who appreciate the practicality and relevance of our Hackathons."
        },
        {
            icon: <PiShieldStarFill className="text-primaryLight-500 text-lg" />,
            heading: "Trusted by Thousands",
            text: "We have successfully served thousands of students, helping them unlock their potential and achieve their career goals."
        },
    ];

    const goals = [
        {
            icon: <BsBackpackFill className="text-primaryLight-500 text-lg" />,
            heading: "Provide Practical Skills",
            text: "We focus on delivering practical skills that are relevant to the current industry demands. Our courses are designed to equip learners with the knowledge and tools needed to excel in their chosen field."
        },
        {
            icon: <ImBook className="text-primaryLight-500 text-lg" />,
            heading: "Foster Creative Problem-Solving",
            text: "We encourage creative thinking and problem-solving abilities, allowing our students to tackle real-world challenges with confidence and innovation."
        },
        {
            icon: <BsPuzzleFill className="text-primaryLight-500 text-lg" />,
            heading: "Promote Collaboration and Community",
            text: "We believe in the power of collaboration and peer learning. Our platform fosters a supportive and inclusive community where learners can connect, share insights, and grow together."
        },
        {
            icon: <MdWbTwighlight className="text-primaryLight-500 text-lg" />,
            heading: "Stay Ahead of the Curve",
            text: "The digital landscape is constantly evolving, and we strive to stay at the forefront of industry trends. We regularly update our course content to ensure our students receive the latest knowledge and skills."
        },
    ];

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <PageHeader
                    spanText="Collaborated"
                    text="with Universities"
                    subHead2="We are trusted by one of the top universities through out the India. Helped them seamless experience of learning and management through our platform."
                />

                <div className="flex flex-col lg:flex-row flex-wrap items-start lg:items-center justify-between gap-5 lg:gap-0 mt-14">
                    <div className="flex items-start justify-start flex-col w-full lg:max-w-[30%] gap-4">
                        <hr className="bg-primaryLight-500 h-2 w-14 rounded-xl" />
                        <Typography className="text-text-400 font-Be_Vietnam_Pro font-semibold text-lg">
                            See why over 100 Universities trust our platform.
                        </Typography>
                    </div>
                    <div className="flex-wrap flex items-center justify-around gap-10 w-full lg:w-[60%]">
                        <img src={lpu} className="w-[120px]" />
                        <img src={iit} className="w-[80px]" />
                        <img src={amity} className="w-[120px]" />
                        <img src={cept} className="w-[120px]" />
                    </div>
                </div>

                <div className="mt-20 flex items-center justify-between flex-col-reverse lg:flex-row gap-14 lg:gap-0">
                    <div className="max-w-[50%] lg:w-[600px] lg:h-[600px]">
                        <img src={circle} className="w-full h-full object-contain" />
                    </div>
                    <div className="flex items-start justify-start flex-col w-full lg:max-w-[40%] gap-4">
                        <Typography className="text-text-400 font-Be_Vietnam_Pro font-semibold text-lg">
                            Save time with Automations
                        </Typography>
                        <hr className="bg-primaryLight-500 h-2 w-14 rounded-xl" />
                        <Typography className="text-text-400 font-Be_Vietnam_Pro font-normal text-lg">
                            Automate the repetitive work in seconds so you can avoid human error and focus on what matters.
                            It gives the impression of software that its highly automated which implies that it is good for client for who want to save time and manage team members easily.
                        </Typography>
                    </div>
                </div>

                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Achievements
                    </Typography>
                    <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm w-full md:w-3/4 mt-2">
                        Our commitment to excellence has led us to achieve significant milestones along our journey. Here are some of our notable achievements.
                    </Typography>

                    <div className="mt-10 flex flex-wrap gap-8">
                        {achievements.map((item, index) => (
                            <Card key={index}
                                icon={item.icon}
                                heading={item.heading}
                                text={item.text}
                            />
                        ))}
                    </div>
                </div>

                <div className="my-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Our Goals
                    </Typography>
                    <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm w-full md:w-3/4 mt-2">
                        At CIS, our goal is to empower individuals from all backgrounds to thrive in the world of design and development. We believe that education should be accessible and transformative, enabling learners to pursue their passions and make a meaningful impact.
                    </Typography>

                    <div className="mt-10 flex flex-wrap gap-8">
                        {goals.map((item, index) => (
                            <Card key={index}
                                icon={item.icon}
                                heading={item.heading}
                                text={item.text}
                            />
                        ))}
                    </div>
                </div>

                <div className="bg-white flex flex-col lg:flex-row items-center justify-between rounded-xl" >
                    <div className="w-full lg:w-2/4 p-10">
                        <Typography className="text-text-500 font-Be_Vietnam_Pro text-4xl font-semibold">
                            <span className="text-primaryLight-500">Together</span>,
                            let's shape the future of cyber world!
                        </Typography>

                        <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm mt-2">
                            Join us on this exciting learning journey and unlock your potential in cyber security.
                        </Typography>
                    </div>
                    <img src={shape} className="hidden lg:block" />

                    <Button variant="filled" size="sm" className="bg-primaryLight-500 mr-10 mb-10 lg:mb-0 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium text-white py-2 px-4">
                        Join Now
                    </Button>
                </div>

            </div>
        </>
    )
}

export default Universities;