import { Button, Chip, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router";
import { UpcomingCard } from "./UpcomingCard";

const UpcomingHackathon = ({ type }) => {

    const prev = [1, 2, 3];
    const navigate = useNavigate();

    return (
        <>
            <div className="">
                <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                    {type} Hackathons
                </Typography>
                <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2" >
                    <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                        Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                        Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                    </Typography>

                    <Button
                        className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        View All
                    </Button>
                </div>

                <div className="bg-white mt-10 p-3 rounded-xl">
                    <img className="w-full h-[320px] rounded-xl object-cover" src="https://plus.unsplash.com/premium_photo-1666466251196-e165803742be?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8NGslMjBzdGFyc3xlbnwwfHwwfHx8MA%3D%3D" />

                    <div className="mt-5 flex items-center justify-between">
                        <div className="flex items-start justify-start gap-2">
                            <Chip value="Beginner" className="w-fit normal-case text-green-500  bg-green-50 font-medium text-xs font-Be_Vietnam_Pro" />
                            <Chip value="4 weeks" className="w-fit normal-case text-text-500  bg-transparent border border-text-50 font-medium text-xs font-Be_Vietnam_Pro" />
                        </div>
                        <Typography className="text-text-500 font-Be_Vietnam_Pro font-medium text-base">
                            By John Smith
                        </Typography>
                    </div>

                    <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-base mt-5">
                        Cyber Security Hackathon
                    </Typography>
                    <Typography className="text-primaryLight-500 font-Be_Vietnam_Pro font-semibold text-base mt-2">
                        ₹ 5000
                    </Typography>
                    <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-base mt-2">
                        Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                        Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                    </Typography>

                    <Button
                        onClick={() => navigate("/hackathon/details/0")}
                        variant="outlined"
                        className="ring-0 focus:ring-0 border-2 border-primaryLight-500 text-primaryLight-500 text-base font-semibold font-Be_Vietnam_Pro normal-case w-full mt-5"
                    >
                        View Insights
                    </Button>
                </div>
                <div className="flex flex-wrap items-center justify-center gap-7">
                    {prev.map((item, index) => (
                        <UpcomingCard key={index} type="played" />
                    ))}
                </div>
            </div>
        </>
    )
}

export default UpcomingHackathon;