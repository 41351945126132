import { Button, Typography } from "@material-tailwind/react"
import { Calendar } from "iconsax-react";
import { FaRegClock } from "react-icons/fa";
import { IoDocumentsOutline } from "react-icons/io5";
import { TbChartArcs3 } from "react-icons/tb";
import { useNavigate } from "react-router"


const ExpandedHackathon = ({ placement, type }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="bg-white p-10 rounded-xl">
                <div className="flex items-center justify-between mb-5 flex-col lg:flex-row gap-2 lg:gap-0">
                    <Typography className="text-text-500 text-lg font-semibold font-Be_Vietnam_Pro">
                        HackFusion Hackathon
                    </Typography>
                    {placement === "home" &&
                        <Button
                            onClick={() => navigate("/hackathon/details/0")}
                            className="bg-text-100 text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View Hackathon
                        </Button>
                    }
                    {placement === "individual" && type === "upcoming" &&
                        <Button
                            onClick={() => navigate("/checkout/hackathon")}
                            className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            Enroll Now
                        </Button>
                    }
                    {placement === "myhackathon" &&
                        <Button
                            // onClick={() => navigate("/checkout/hackathon")}
                            className="bg-primaryLight-500/20 text-primaryLight-500 lg:max-w-[250px] flex items-center justify-center flex-wrap shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            Scheduled on
                            29.10.24 (09:00 PM)
                        </Button>
                    }
                </div>

                <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro">
                    Join us for a dynamic and challenging hackathon organized by Rangestorm, where participants will engage in solving multiple-choice questions (MCQs) designed to test their knowledge and problem-solving skills across various domains. This event is crafted to push the boundaries of your critical thinking and technical prowess, offering a unique platform to showcase your expertise and compete with fellow enthusiasts. Dive into a stimulating environment filled with thought-provoking questions,
                    and demonstrate your ability to navigate complex problems swiftly and accurately.
                </Typography>

                <div className="flex items-center justify-center flex-col lg:flex-row gap-5 mt-5">
                    <div className="w-full lg:w-1/3 h-[300px] rounded-xl">
                        <img className="w-full h-full object-cover rounded-xl" src="https://plus.unsplash.com/premium_photo-1690303193653-0418179e5512?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aGFja2F0aG9ufGVufDB8fDB8fHww" />
                    </div>
                    <div className="w-full lg:w-1/3 h-[300px]">
                        <img className="w-full h-full object-cover rounded-xl" src="https://plus.unsplash.com/premium_photo-1705010649877-f561dd47dee9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8cHJvamVjdHxlbnwwfHwwfHx8MA%3D%3D" />
                    </div>
                    <div className="w-full lg:w-1/3 h-[300px]">
                        <img className="w-full h-full object-cover rounded-xl" src="https://plus.unsplash.com/premium_photo-1683121790394-0893c2ccf578?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjl8fHByb2plY3R8ZW58MHx8MHx8fDA%3D" />
                    </div>
                </div>

                <div className="mt-5 flex items-center justify-between flex-col lg:flex-row">
                    <div className="flex items-center justify-start gap-5 flex-col lg:flex-row flex-wrap">
                        <Button
                            variant="outlined"
                            className="py-2 px-4 ring-0 focus:ring-0 border border-text-50 normal-case bg-text-white text-base font-Be_Vietnam_Pro font-normal text-text-500"
                        >
                            3 Stages
                        </Button>

                        <Typography className="font-Be_Vietnam_Pro text-text-500 text-base font-normal flex items-center justify-center gap-1">
                            <FaRegClock className="text-primaryLight-500" /> Time <span className="font-semibold">20 min</span>
                        </Typography>

                        <Typography className="font-Be_Vietnam_Pro text-text-500 text-base font-normal flex items-center justify-center gap-1">
                            <TbChartArcs3 className="text-primaryLight-500" /> Score <span className="font-semibold">200 points</span>
                        </Typography>

                        <Typography className="font-Be_Vietnam_Pro text-text-500 text-base font-normal flex items-center justify-center gap-1">
                            <IoDocumentsOutline className="text-primaryLight-500" /> Questions <span className="font-semibold">50</span>
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro text-text-500 text-base font-normal flex items-center justify-center gap-1">
                            <Calendar size="16" className="text-primaryLight-500" /> 29.10.2024 <span className="font-semibold">(9:00 PM)</span>
                        </Typography>
                    </div>

                    <Typography className="font-Be_Vietnam_Pro text-text-500 text-base font-medium">
                        By University of California
                    </Typography>
                </div>

                <div className="border border-text-50 rounded-xl mt-5">
                    <Typography className="font-Be_Vietnam_Pro text-text-500 text-base font-medium p-6 border-b border-text-50">
                        Stages
                    </Typography>

                    <div className="p-6 flex items-start lg:items-center flex-col lg:flex-row gap-5">
                        <Typography className="font-Be_Vietnam_Pro flex flex-col text-text-500 text-4xl font-bold border-r border-text-50 lg:pr-10 lg:w-[300px]">
                            01
                            <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                Engaging Multiple-Choice Questions
                            </span>
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro flex flex-col text-text-500 text-4xl font-bold border-r border-text-50 lg:px-10 lg:w-[300px]">
                            02
                            <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                Capture the flag (CTF)
                            </span>
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro flex flex-col text-text-500 text-4xl font-bold lg:pl-10">
                            03
                            <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                Final Knock-off
                            </span>
                        </Typography>
                    </div>
                </div>

                {placement !== "home" && placement !== "myhackathon" &&
                    <div className="border border-text-50 rounded-xl mt-5">
                        <Typography className="font-Be_Vietnam_Pro text-text-500 text-base font-medium p-6 border-b border-text-50">
                            Analytics
                        </Typography>

                        <div className="p-6  flex items-start lg:items-center flex-col lg:flex-row gap-5">
                            <Typography className="font-Be_Vietnam_Pro flex flex-col text-text-500 text-4xl font-bold border-r border-text-50 lg:pr-10 lg:w-[300px]">
                                24
                                <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                    Total Participants
                                </span>
                            </Typography>
                            <Typography className="font-Be_Vietnam_Pro flex flex-col text-text-500 text-4xl font-bold border-r border-text-50 lg:px-10 lg:w-[300px]">
                                02:23 min
                                <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                    Avg. Time
                                </span>
                            </Typography>
                            {placement === "individual" && type === "upcoming" ?
                                <Typography className="font-Be_Vietnam_Pro flex flex-col text-text-500 text-4xl font-bold lg:pl-10">
                                    03
                                    <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                        Final Knock-off
                                    </span>
                                </Typography>
                                :
                                <Typography className="font-Be_Vietnam_Pro flex flex-col text-primaryLight-500 text-4xl font-bold pl-10">
                                    #10
                                    <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                        Your Rank
                                    </span>
                                </Typography>
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default ExpandedHackathon;