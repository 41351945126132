import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import { Navigate, useMatch, useNavigate } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCourseActiveTab } from "./coursesTabSlice";
import CoursesHome from "./CoursesHome";
import AllCourses from "./AllCourses";
import IndividualCourse from "./IndividualCourse";
import IndividualModule from "./IndividualModule";
import MyCourses from "./MyCourses";

const Courses = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isDetailsRoute = useMatch("/courses/details/:id");
    const isModuleDetailsRoute = useMatch("/courses/module/:courseName/:id");
    const { courseActiveTab } = useSelector((state) => state.coursesTabSlice);
    const { accessToken } = useSelector((state) => state.authSlice);

    const data = [
        {
            label: "Home",
            value: "home",
            el: <CoursesHome />
        },
        {
            label: "All Courses",
            value: "all",
            el: <AllCourses />
        },
        {
            label: "My Courses",
            value: "my_courses",
            el: <MyCourses />
        },
    ];

    useEffect(() => { }, [courseActiveTab])

    return (
        <>
            <Tabs aria-hidden="true" key={courseActiveTab} value={courseActiveTab} className="mt-5">
                <TabsHeader
                    className="bg-transparent flex items-center justify-center"
                    indicatorProps={{
                        className: "bg-gray-200 shadow-none !text-text-400 py-2 px-6 ",
                    }}
                >
                    {data.map(({ label, value }) => (
                        <Tab
                            onClick={() => {
                                dispatch(setCourseActiveTab(value));
                                navigate("/courses")
                            }}
                            key={value} value={value} className=" font-Be_Vietnam_Pro text-sm font-medium text-text-400 py-2 px-6 !max-w-fit">
                            {label}
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody>
                    {data.map(({ value, el }) => (
                        <TabPanel key={value} value={value} className="w-full">
                            {!isDetailsRoute && !isModuleDetailsRoute ? el :
                                isDetailsRoute && !isModuleDetailsRoute ? <IndividualCourse /> :
                                    accessToken ? <IndividualModule /> : <Navigate to="/login" />}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </>
    )
}

export default Courses;