import { Button, Typography } from "@material-tailwind/react";
import PageHeader from "../../../common/components/PageHeader";
import mockup from '../../../assets/images/mockup.png';
import OurTestimonials from "../../../common/components/OurTestimonials";
import OurFaqs from "../../../common/components/OurFaqs";
import { Award, Briefcase, Flash, Global, Notepad2, People, ShieldSecurity, Teacher, TickCircle, Weight } from "iconsax-react";
import { useNavigate } from "react-router";

const InstructorCard = ({ index }) => {
    return (
        <>
            <div className="bg-white w-[48%] p-10 rounded-xl">
                <img
                    className="h-[400px] w-full object-cover rounded-xl"
                    src="https://images.unsplash.com/photo-1676439777386-d67cd2b32e7b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cG9ydHJhaXQlMjBibGFjayUyMGFuZCUyMHdoaXRlfGVufDB8fDB8fHww" />

                <div className="flex items-center justify-between mt-5">
                    <div className="">
                        <Typography className="font-Be_Vietnam_Pro font-semibold text-base text-text-500">
                            Col Vivek
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro text-text-400 text-xs font-medium mt-1">
                            Instructor
                        </Typography>
                    </div>
                    <Typography className="font-Be_Vietnam_Pro text-text-500 text-5xl font-bold">
                        0{index + 1}
                    </Typography>
                </div>
            </div>
        </>
    )
}

const Grid = ({ text, icon, bgColor, color }) => {
    return (
        <>
            <Typography
                style={{
                    backgroundColor: bgColor,
                    color: color ? "white" : "#262626"
                }}
                className="rounded-xl p-4 pl-10 w-[32%] border border-primaryLight-500 flex items-start justify-start gap-2 font-Be_Vietnam_Pro text-base font-medium">
                {icon}{text}
            </Typography>
        </>
    )
}

const StructureCard = ({ text, spanText, subtext, index, text2, spantext2 }) => {
    return (
        <>
            <div className={`w-[24%] border border-primaryLight-500 text-text-500 p-10 rounded-xl flex flex-col items-start h-full min-h-[200px] 
                ${index === 1 && "bg-primaryLight-500 text-white"}`
            }
            >
                <div className="flex">
                    <Typography className="font-Be_Vietnam_Pro text-5xl font-semibold flex items-start justify-start gap-1">
                        {text} <span className="text-base">{spanText}</span>
                    </Typography>
                    {index === 4 &&
                        <Typography className="font-Be_Vietnam_Pro text-5xl font-semibold flex items-start justify-start gap-1">
                            - {text2} <span className="text-base">{spantext2}</span>
                        </Typography>
                    }
                </div>
                <Typography className={`font-Be_Vietnam_Pro text-sm font-medium mt-4 max-w-[70%] text-text-400 ${index === 1 && "text-white"}`}>
                    {subtext}
                </Typography>
            </div>
        </>
    )
}

const IndividualBootcamp = () => {
    const navigate = useNavigate();
    const arr = [1, 2];

    return (
        <>
            <div className="p-20">
                <PageHeader
                    spanText="Cyber Warrior"
                    text="Bootcamps"
                    subHead1="Master Cybersecurity Skills with Hands-On Training"
                    subHead2="Join our immersive bootcamp to unlock a rewarding career in cybersecurity"
                />

                <div className="flex items-center justify-center mt-10 gap-5">
                    <Button
                        // onClick={() => dispatch(setActiveTab("all"))}
                        className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium"
                    >
                        Explore Bootcamps
                    </Button>
                    <Button
                        // onClick={() => dispatch(setActiveTab("all"))}
                        className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium"
                    >
                        View Pricing
                    </Button>
                </div>

                <div className="mt-20 w-[1000px]] h-[600px]">
                    <img
                        className="rounded-xl w-full h-full object-cover"
                        src="https://plus.unsplash.com/premium_photo-1682464651356-3c6780eff00c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YWklMjBiYWNrZ3JvdW5kfGVufDB8fDB8fHww" />
                </div>

                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        About
                    </Typography>
                    <div className="flex items-start justify-between mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="flex items-center justify-between gap-10 mt-14 h-[400px]">
                        <div className="w-1/2">
                            <Typography className="text-text-400 text-base font-Be_Vietnam_Pro font-normal">
                                The CIS Cyber Security Bootcamp aims to bridge the skills gap in the cybersecurity industry by providing students with a transformative learning experience. <br />
                                <br />
                                This real-time hands-on learning with industry project is designed to empower
                                the next generation talent to become the Cyber Warriors. In this intensive
                                program, students will delve into the dynamic world of cybersecurity through a
                                comprehensive curriculum, practical exercises, and real-world simulations
                                through CIS Platform. The CIS Cyber Security Bootcamp aims to bridge the skills gap in the cybersecurity industry by providing students with a transformative. <br />
                                <br />
                                In this intensive program, students will delve into the dynamic world of cybersecurity through a
                                comprehensive curriculum, practical exercises, and real-world simulations
                                through CIS Platform
                            </Typography>
                        </div>
                        <div className="w-1/2 h-full">
                            <img src={mockup} className="w-full h-full object-cover" />
                        </div>
                    </div>


                </div>


                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Cyber Security Instructors
                    </Typography>
                    <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-3/4">
                        Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                        Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                    </Typography>

                    <div className="flex items-center flex-wrap mt-14 gap-10">
                        {arr.map((item, index) => (
                            <InstructorCard key={index} index={index} />
                        ))}
                    </div>
                </div>

                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Cyber Security Bootcamp
                    </Typography>
                    <div className="flex items-start justify-between mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="mt-14 flex flex-col gap-5">
                        <div className="flex items-center justify-between">
                            <Grid icon={<Flash />} text="Hands-on Immersive Learning" bgColor="#2C6DE7" color="white" />
                            <Grid icon={<ShieldSecurity />} text="Cyber Security CTF Challenge" />
                            <Grid icon={<Teacher />} text="Highly Experienced experts & Mentors" />
                        </div>
                        <div className="flex items-center justify-between">
                            <Grid icon={<Weight />} text="Practice CTFs on CIS Platform" />
                            <Grid icon={<Global />} text="Work on real world projects" bgColor="#2C6DE7" color="white" />
                            <Grid icon={<Briefcase />} text="Internship and job opportunities" />
                        </div>
                        <div className="flex items-center justify-between">
                            <Grid icon={<Notepad2 />} text="Career Guidance & Mentorship support" />
                            <Grid icon={<People />} text="Networking Opportunities" />
                            <Grid icon={<Award />} text="Completion Certificate" bgColor="#2C6DE7" color="white" />
                        </div>
                    </div>
                </div>

                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Structure & Duration
                    </Typography>
                    <div className="flex items-start justify-between mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="mt-14" >
                        <div className="flex items-center justify-center gap-7">
                            <StructureCard text="2" spanText="Weeks" subtext="Online Cybersecurity Bootcamp Program for Beginner level" index={1} />
                            <StructureCard text="12" spanText="Hours" subtext="Live Interactive Session on Saturday and Sunday" index={2} />
                            <StructureCard text="12" spanText="Hours" subtext="Hands-on labs on CIS Platform" index={3} />
                            <StructureCard text="1" text2="31" spanText="May" spantext2="May" subtext="Bootcamp Program Batch Date" index={4} />
                        </div>
                    </div>

                </div>

                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Cyber Security Bootcamp
                    </Typography>
                    <div className="flex items-start justify-between mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="flex items-center justify-center mt-14">
                        <div className="bg-white !rounded-xl max-w-3xl min-w-[600px]">
                            <div className="p-5 pb-5 border-b ">
                                <Typography className="font-Be_Vietnam_Pro text-lg font-semibold text-text-500 text-center">
                                    Course Fee
                                </Typography>
                                <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 text-center">
                                    INR 2000 <span className="text-text-400/50 font-normal"> / user</span>
                                </Typography>
                            </div>
                            <div className="flex flex-col gap-2 items-center justify-center p-5 bg-[#FCFCFD] rounded-xl">
                                <Typography className="font-Be_Vietnam_Pro font-medium text-xs text-text-400 flex items-center justify-center gap-1 ">
                                    <TickCircle size="14" /> Access to all bootcamp benefits
                                </Typography>
                                <Typography className="font-Be_Vietnam_Pro font-semibold text-base text-text-500 flex items-center justify-center gap-1 ">
                                    Early Bird Discount
                                    <span className="bg-primaryLight-500 text-white p-2 py-1 rounded-lg">-25%</span>
                                    <span className="text-xs font-medium">Till 22 March 2024</span>
                                </Typography>
                                <Button onClick={() => navigate("/bootcamp/registration")} className="shadow-none hover:shadow-none p-2 px-4 normal-case bg-white font-Be_Vietnam_Pro font-semibold text-base border-2 border-primaryLight-500 text-primaryLight-500">
                                    Enroll Now
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-20">
                    <OurTestimonials />
                </div>
                <div className="mt-20">
                    <OurFaqs />
                </div>

            </div>
        </>
    )
}

export default IndividualBootcamp;