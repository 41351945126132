import { Avatar, Button, Typography } from '@material-tailwind/react';
import React from 'react'
import { MdArrowOutward } from "react-icons/md";
import { useNavigate } from 'react-router';

const BlogsCard = ({ index, heading, text }) => {
    const navigate = useNavigate();

    return (
      <>
        <div className="bg-white w-full md:w-[50%] lg:w-[31.5%] p-[3vw] rounded-xl">
          <Typography className="text-5xl text-text-500 font-Be_Vietnam_Pro font-bold text-right">
            0{index + 1}
          </Typography>
          <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-lg mt-3">
            {heading}
          </Typography>
          <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm mt-2">
            {text}
          </Typography>
          <div className="flex items-end justify-between mt-5">
          <div className="flex items-center -space-x-4 bg-white">
        <Avatar
          variant="circular"
          alt="user 1"
          className="border-2 border-white hover:z-10 focus:z-10"
          src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
        />
        <Avatar
          variant="circular"
          alt="user 2"
          className="border-2 border-white hover:z-10 focus:z-10"
          src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"
        />
        <Avatar
          variant="circular"
          alt="user 3"
          className="border-2 border-white hover:z-10 focus:z-10"
          src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1288&q=80"
        />
        <Avatar
          variant="circular"
          alt="user 4"
          className="border-[7px] border-white hover:z-10 focus:z-10 m-1"
          src={`https://dummyimage.com/40x40/FFFFFF/212121&text=${"99%2B"}`}
          />
      </div>
            <Button
              variant="outlined"
              onClick={()=>navigate(`/blogs/details/${index+1}`)}
              className="text-primaryLight-500 border border-text-50 bg-text-100 text-base ring-0 focus:ring-0 p-3"
            >
              <MdArrowOutward />
            </Button>
          </div>
        </div>
      </>
    );
  };

export default BlogsCard