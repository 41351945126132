import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeStep: 0,
};

const bootcampSlice = createSlice({
    name: 'bootcampSlice',
    initialState,
    reducers: {
        setActiveStep: (state, { payload }) => {
            state.activeStep = payload;
        },
    }
});

export const { setActiveStep } = bootcampSlice.actions;
export default bootcampSlice.reducer;
