import { Button, Tab, Tabs, TabsHeader, Typography } from "@material-tailwind/react";
import PageHeader from "../../../common/components/PageHeader";
import { useDispatch } from "react-redux";
import { setCourseActiveTab } from "./coursesTabSlice";
import { useState } from "react";
import { CourseCard } from "./CourseCard";
import ExpandedCourseCard from "./ExpandedCourseCard";


const AllCourses = () => {
    const dispatch = useDispatch();
    const [activeTab, setCategoryActiveTab] = useState("category_1");
    const arr = [1, 2, 3];
    const tabsData = [
        {
            label: "Category 1",
            value: "category_1",
        },
        {
            label: "Category 2",
            value: "category_2",
        },
        {
            label: "Category 3",
            value: "category_3",
        },
        {
            label: "Category 4",
            value: "category_4",
        },
        {
            label: "Category 5",
            value: "category_5",
        },
    ];

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <PageHeader
                    spanText="Unlock"
                    text="Your Creative Potential"
                    subHead1="with Online Design and Development Courses."
                    subHead2="Learn from Industry Experts and Enhance Your Skills."
                />
                <div className="flex items-center justify-center flex-col lg:flex-row mt-10 gap-5">
                    <Button
                        onClick={() => dispatch(setCourseActiveTab("all"))}
                        className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        Explore Courses
                    </Button>
                    <Button
                        onClick={() => dispatch(setCourseActiveTab("all"))}
                        className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        View Pricing
                    </Button>
                </div>
                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500 mt-20">
                        All Courses
                    </Typography>
                    <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full mt-5">
                        Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                        Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                    </Typography>
                    <div className="flex items-center justify-center">
                        <Tabs
                            value={activeTab}
                            className="w-[60%] bg-[#ffffff] rounded-lg mt-10"
                        >
                            <TabsHeader
                                className="bg-[#ffffff] p-2 rounded-lg"
                                indicatorProps={{
                                    className: "bg-[#2C6DE7] text-[#ffffff]", // Blue sliding indicator
                                }}
                            >
                                {tabsData.map(({ label, value }) => (
                                    <Tab
                                        key={value}
                                        value={value}
                                        onClick={() => setCategoryActiveTab(value)} // Use arrow function to directly set the value
                                        className={`px-4 py-2 rounded-lg text-base font-Be_Vietnam_Pro font-medium ${activeTab === value ? "text-[#ffffff]" : "text-text-500"
                                            }`}
                                    >
                                        {label}
                                    </Tab>
                                ))}
                            </TabsHeader>
                        </Tabs>
                    </div>
                    <div className="">
                        <div className="flex flex-wrap items-center gap-7">
                            {arr.map((item, index) => (
                                <CourseCard placement="all" key={index} />
                            ))}
                        </div>
                    </div>
                    <ExpandedCourseCard />
                    <div className="">
                        <div className="flex flex-wrap items-center gap-7">
                            {arr.map((item, index) => (
                                <CourseCard placement="all" key={index} />
                            ))}
                        </div>
                    </div>
                    <ExpandedCourseCard />
                    <div className="">
                        <div className="flex flex-wrap items-center gap-7">
                            {arr.map((item, index) => (
                                <CourseCard placement="all" key={index} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-full h-[600px] mt-20">
                    <img className="w-full h-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1552960504-34e1e1be3f53?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                </div>
            </div>
        </>
    )
}

export default AllCourses;