

import { Avatar, Typography } from '@material-tailwind/react';
import logo from '../../../assets/images/CI_Logo.png';
import { HiMail } from 'react-icons/hi';
import { IoCall } from 'react-icons/io5';
import { FaLocationDot } from 'react-icons/fa6';
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useNavigate } from 'react-router';

const Footer = () => {
    const navigate = useNavigate();


    const links = [
        {
            header: "Home", subHeads: [
                { text: "Hackathons", link: "/hackathon" },
                { text: "Bootcamps", link: "/bootcamp" },
                { text: "Courses", link: "/courses" },
                // { text: "CIS Edu", link: "" },
                // { text: "CIS Certificate", link: "" },
                { text: "Validation", link: "/certificate" },
                { text: "Universities", link: "/universities" },
            ]
        },
        {
            header: "Company", subHeads: [
                { text: "About Us", link: "/about-us" },
                { text: "Contact Us", link: "/contact-us" },
                { text: "Careers", link: "/career" },
            ]
        },
        {
            header: "Cyber Awareness", subHeads: [
                { text: "Awareness", link: "/awareness" },
                { text: "Project Drona", link: "/project-drona" },
                { text: "Research Paper", link: "/research-paper" },
                { text: "Blogs", link: "/blogs" },
            ]
        },
    ]

    return (
        <>
            <div className="bg-white p-20 pb-0">
                <div className='flex flex-col lg:flex-row items-start lg:items-center justify-between border-b pb-12 gap-5 lg:gap-0'>
                    <div className="flex flex-col gap-3">
                        <Avatar
                            src={logo}
                        />
                        <Typography className='font-Be_Vietnam_Pro text-base font-normal text-text-500 flex items-center justify-center w-fit gap-1'>
                            <HiMail />
                            support@councilinfosec.org
                        </Typography>
                        <Typography className='font-Be_Vietnam_Pro text-base font-normal text-text-500 flex items-center justify-center w-fit gap-1'>
                            <IoCall />
                            +91 91813 23 2309
                        </Typography>
                        <Typography className='font-Be_Vietnam_Pro text-base font-normal text-text-500 flex items-center justify-center w-fit gap-1'>
                            <FaLocationDot />
                            Somewhere in the World
                        </Typography>
                    </div>

                    <div className='flex flex-wrap flex-row items-start justify-start lg:justify-around w-full lg:w-2/3 gap-5 md:gap-20 lg:gap-0'>
                        {links.map((item, index) => (
                            <div key={index} className='flex flex-col items-start gap-1'>
                                <Typography className='font-Be_Vietnam_Pro font-semibold text-xl mb-1'>{item.header}</Typography>
                                {item.subHeads.map((subHead, index) => (
                                    <Typography
                                        className='font-Be_Vietnam_Pro font-normal text-base text-text-400 cursor-pointer'
                                        key={index}
                                        onClick={()=>{navigate(subHead?.link)}}
                                    >
                                        {subHead.text}
                                    </Typography>
                                ))}
                            </div>
                        ))}
                        <div className=''>
                            <Typography className='font-Be_Vietnam_Pro font-semibold text-xl mb-1'>Social Profiles</Typography>
                            <div className='flex items-start gap-2'>
                                <div className='text-2xl bg-text-100 p-3 rounded-lg'>
                                    <FaFacebook className='text-primaryLight-500'/>
                                </div>
                                <div className='text-2xl bg-text-100 p-3 rounded-lg'>
                                    <FaTwitter className='text-primaryLight-500'/>
                                </div>
                                <div className='text-2xl bg-text-100 p-3 rounded-lg'>
                                    <FaLinkedin className='text-primaryLight-500' />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex items-center justify-center'>
                    <Typography className='py-12 font-Be_Vietnam_Pro font-normal text-base text-[#656567]'>
                        © 2024 Council of Information Security (CIS). All rights reserved.
                    </Typography>
                </div>
            </div>
        </>
    )
}

export default Footer;