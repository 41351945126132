
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
    Typography,
    Avatar,
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import logo from '../../../assets/images/CI_Logo.png';
import { useMatch, useNavigate } from "react-router";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../containers/Hackathon/hackathonTabSlice";
import { setCourseActiveTab } from "../../../containers/Pages/Courses/coursesTabSlice";
import { User } from "iconsax-react";
import { setAccessToken, setUserDetails } from "../../../containers/Auth/authSlice";
import toast from "react-hot-toast";

const Navbar = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { accessToken, userDetails } = useSelector((state) => state.authSlice);
    // console.log("accessToken", accessToken)
    const isLoginPage = useMatch("/login");
    const isSignPage = useMatch("/signup");

    const [open, setOpen] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleOpen = (value) => setOpen(value);

    const navLinks = [
        {
            text: "Cywar", options: [
                {
                    text: "Hackathon", link: "/hackathon"
                },
                {
                    text: "Bootcamp", link: "/bootcamp"
                },
            ]
        },
        {
            text: "Knowledge Center", options: [
                {
                    text: "Research Paper", link: "/research-paper"
                },
                {
                    text: "Blogs", link: "/blogs"
                },
            ]
        },
        {
            text: "Certifications", options: [
                {
                    text: "Courses", link: "/courses"
                },
                {
                    text: "Validation", link: "/certificate"
                },
            ]
        },
        {
            text: "Awareness", link: "/awareness"
        },
        {
            text: "Project Drona", link: "/project-drona"
        },
        // {
        //     text: "CIS Cert", link: ""
        // },
        {
            text: "About Us", link: "/about-us"
        },
    ];

    const handleLogout = () => {
        toast.success('User logged out')
        dispatch(setAccessToken(null));
        dispatch(setUserDetails({}));
        navigate("/");
        setMenuOpen(false);
    }

    return (
        <>
            <div className="sticky top-0 z-30 border-b py-2 px-20 bg-text-100 w-full hidden lg:flex items-center justify-between ease-in transition-transform shadow-sm rounded-none">
                <div className="w-3/4 flex items-center justify-center">
                    <Avatar className="mr-20 cursor-pointer w-20 h-20 object-contain" onClick={() => navigate("/")}
                        src={logo}
                    />
                    <div className="">
                        {navLinks.map((item, index) => (
                            (item.text !== "About Us" && item.text !== "Awareness" && item.text !== "Project Drona") ?
                                <Menu key={index} placement="bottom-start"
                                    animate={{
                                        mount: { y: 0 },
                                        unmount: { y: 25 },
                                    }}
                                >
                                    <MenuHandler>
                                        <Button
                                            ripple={false}
                                            className="!bg-transparent text-text-500 shadow-none hover:shadow-none focus:outline-0 normal-case font-Be_Vietnam_Pro text-lg font-normal py-0 px-4"
                                        >
                                            {item.text}
                                        </Button>
                                    </MenuHandler>
                                    {item.options?.length > 0 &&
                                        <MenuList>
                                            {item?.options?.map((option, index) => (
                                                <MenuItem key={index}
                                                    onClick={() => {
                                                        navigate(option.link);
                                                        option.text === "Hackathon" && dispatch(setActiveTab("home"));
                                                        option.text === "Courses" && dispatch(setCourseActiveTab("home"));
                                                    }}
                                                    className="font-Be_Vietnam_Pro text-text-500 font-normal text-sm">{option.text}</MenuItem>
                                            ))
                                            }
                                        </MenuList>
                                    }
                                </Menu>
                                :
                                <Button
                                    key={index}
                                    onClick={() => navigate(item.link)}
                                    ripple={false}
                                    className="!bg-transparent shadow-none text-text-500 hover:shadow-none focus:outline-0 normal-case font-Be_Vietnam_Pro text-lg font-normal py-0 px-4"
                                >
                                    {item.text}
                                </Button>
                        ))}
                    </div>
                </div>

                <div className="flex gap-4">
                    {!userDetails?.is?.member &&
                        <Button onClick={() => navigate("/membership")}
                            variant="outlined"
                            className="normal-case font-semibold text-base font-Be_Vietnam_Pro py-2 px-6 text-text-500 border-text-500 focus:ring-0"
                        >
                            Become a member
                        </Button>
                    }
                    {!isLoginPage && !isSignPage && !accessToken &&
                        <Button
                            onClick={() => navigate("/login")}
                            variant="filled"
                            className="normal-case shadow-none hover:shadow-none font-semibold text-base font-Be_Vietnam_Pro py-2 px-6 text-white bg-primaryLight-500 focus:ring-0"
                        >
                            Login
                        </Button>
                    }
                    {accessToken &&
                        <Menu placement="bottom-start"
                            animate={{
                                mount: { y: 0 },
                                unmount: { y: 25 },
                            }}
                        >
                            <MenuHandler>
                                <Button
                                    // onClick={() => navigate("/login")}
                                    ripple={false}
                                    className="normal-case shadow-none hover:shadow-none font-semibold text-base font-Be_Vietnam_Pro p-2 text-white bg-primaryLight-500 focus:ring-0"
                                >
                                    <User variant="Bold" />
                                </Button>
                            </MenuHandler>
                            <MenuList>
                                <MenuItem onClick={() => navigate("/profile")} className="font-Be_Vietnam_Pro text-text-500 font-normal text-sm">Profile Settings</MenuItem>
                                <MenuItem onClick={handleLogout} className="font-Be_Vietnam_Pro text-text-500 font-normal text-sm">Logout</MenuItem>
                            </MenuList>
                        </Menu>
                    }
                </div>
            </div>

            <div className="flex lg:hidden bg-primaryLight-500 w-full p-6 items-center justify-between">
                <Avatar className="mr-20 cursor-pointer" onClick={() => navigate("/")}
                    src={logo}
                />
                <Menu
                    open={menuOpen}
                    handler={setMenuOpen}
                    animate={{
                        mount: { y: 0 },
                        unmount: { y: 25 },
                    }}
                    dismiss={{
                        itemPress: false,
                    }}
                >
                    <MenuHandler>
                        <Button
                            ripple={false}
                            className="!bg-transparent shadow-none hover:shadow-none focus:outline-0 normal-case font-Be_Vietnam_Pro text-lg font-normal py-0 px-4"
                        >
                            <GiHamburgerMenu className="text-white text-2xl" />
                        </Button>
                    </MenuHandler>
                    <MenuList className="w-[97vw]">
                        {navLinks?.map((item, index) => (
                            (item.text !== "About Us" && item.text !== "Awareness" && item.text !== "Project Drona") ?
                                <MenuItem key={index} className="font-Be_Vietnam_Pro text-text-500 font-normal text-sm">
                                    <Accordion open={open === index ? true : false}>
                                        <AccordionHeader onClick={() => handleOpen(index)}
                                            className="!bg-transparent shadow-none hover:shadow-none focus:outline-0 normal-case font-Be_Vietnam_Pro text-lg font-normal py-0 px-4"
                                        >
                                            {item.text}
                                        </AccordionHeader>
                                        {item.options?.length > 0 && item.options?.map((option, index) => (
                                            <AccordionBody
                                                onClick={() => {
                                                    navigate(option.link);
                                                    option.text === "Hackathon" && dispatch(setActiveTab("home"))
                                                    setOpen(false)
                                                    setMenuOpen(false);
                                                }}
                                                key={index}
                                                className="font-Be_Vietnam_Pro text-text-500 font-normal text-sm">
                                                {option.text}
                                            </AccordionBody>
                                        ))}
                                    </Accordion>
                                </MenuItem>
                                :
                                <Typography
                                    key={index}
                                    onClick={() => {navigate(item.link); setMenuOpen(false);}}
                                    className="ml-3 text-text-400 border-b py-1 border-blue-gray-100 !bg-transparent shadow-none hover:shadow-none focus:outline-0 normal-case font-Be_Vietnam_Pro text-lg font-normal px-4"
                                >
                                    {item.text}
                                </Typography>
                        ))
                        }
                        <div className="flex gap-4 mt-5 ml-5">
                            {!userDetails?.is?.member &&
                                <Button onClick={() => { navigate("/membership"); setMenuOpen(false); }}
                                    variant="outlined"
                                    className="w-full normal-case font-semibold text-base font-Be_Vietnam_Pro py-2 px-6 text-text-500 border-text-500 focus:ring-0"
                                >
                                    Become a member
                                </Button>
                            }
                            {!isLoginPage && !isSignPage && !accessToken &&
                                <Button
                                    onClick={() => { navigate("/login"); setMenuOpen(false); }}
                                    variant="filled"
                                    className="w-full normal-case shadow-none hover:shadow-none font-semibold text-base font-Be_Vietnam_Pro py-2 px-6 text-white bg-primaryLight-500 focus:ring-0"
                                >
                                    Login
                                </Button>
                            }
                            {accessToken &&
                                <Menu placement="bottom-start"
                                    animate={{
                                        mount: { y: 0 },
                                        unmount: { y: 25 },
                                    }}
                                >
                                    <MenuHandler>
                                        <Button
                                            // onClick={() => navigate("/login")}
                                            ripple={false}
                                            className="normal-case shadow-none hover:shadow-none font-semibold text-base font-Be_Vietnam_Pro p-2 text-white bg-primaryLight-500 focus:ring-0"
                                        >
                                            <User variant="Bold" />
                                        </Button>
                                    </MenuHandler>
                                    <MenuList>
                                        <MenuItem onClick={() => { navigate("/profile"); setMenuOpen(false); }} className="font-Be_Vietnam_Pro text-text-500 font-normal text-sm">Profile Settings</MenuItem>
                                        <MenuItem onClick={handleLogout} className="font-Be_Vietnam_Pro text-text-500 font-normal text-sm">Logout</MenuItem>
                                    </MenuList>
                                </Menu>
                            }
                        </div>
                    </MenuList>
                </Menu>

            </div>
        </>
    )
}

export default Navbar;