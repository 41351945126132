import PageHeader from "../../../common/components/PageHeader";
import certificate from '../../../assets/images/cis_certificate.png';
import { Button, Input, Typography } from "@material-tailwind/react";
import { Book1, Calendar, UserSquare } from "iconsax-react";
import { useNavigate } from "react-router";

const CertificateDetails = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="p-0 py-10 sm:p-5 lg:p-20">
                <PageHeader
                    spanText="Verified"
                    text=""
                    subHead1=""
                    subHead2="Congratulations! Your certificate is verified."
                />
                <div className="flex items-center justify-center w-full">
                    <div className="flex items-center justify-center my-14 w-[650px] h-[500px]">
                        <img src={certificate} className="w-full h-full object-contain" />
                    </div>
                </div>

                <div className="flex items-center justify-center flex-col lg:flex-row gap-10 lg:gap-5 w-full">

                    <div className="relative flex w-full gap-2 md:w-max">
                        <Input
                            type="text"
                            readOnly
                            placeholder="Yash Sharma"
                            containerProps={{
                                className:
                                    "min-w-[350px]",
                            }}
                            className="!pl-12 !border pt-10 pb-5 !rounded-lg !border-text-400 placeholder:font-bold font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-500 placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-text-400 focus:ring-0"
                            labelProps={{
                                className: "hidden",
                            }}
                        />
                        <div className="!absolute left-3 top-[15px] h-[70%] pr-2 border-r border-text-400/70 flex items-center justify-center">
                            <UserSquare size="18" className="text-text-400/70" />
                        </div>
                        <div className="!absolute left-[50px] top-2">
                            <Typography className="text-text-400/70 font-bold font-Be_Vietnam_Pro text-sm">Name</Typography>
                        </div>
                    </div>
                    <div className="relative flex w-full gap-2 md:w-max">
                        <Input
                            type="text"
                            readOnly
                            placeholder="Beginner guide to cyber security"
                            containerProps={{
                                className:
                                    "min-w-[350px]",
                            }}
                            className="!pl-12 !border pt-10 pb-5 !rounded-lg !border-text-400 placeholder:font-bold font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-500 placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-text-400 focus:ring-0"
                            labelProps={{
                                className: "hidden",
                            }}
                        />
                        <div className="!absolute left-3 top-[15px] h-[70%] pr-2 border-r border-text-400/70 flex items-center justify-center">
                            <Book1 size="18" className="text-text-400/70" />
                        </div>
                        <div className="!absolute left-[50px] top-2">
                            <Typography className="text-text-400/70 font-bold font-Be_Vietnam_Pro text-sm">Course</Typography>
                        </div>
                    </div>
                    <div className="relative flex w-full gap-2 md:w-max">
                        <Input
                            type="text"
                            readOnly
                            placeholder="May 12, 2023"
                            containerProps={{
                                className:
                                    "min-w-[350px]",
                            }}
                            className="!pl-12 !border pt-10 pb-5 !rounded-lg !border-text-400 placeholder:font-bold font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-500 placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-text-400 focus:ring-0"
                            labelProps={{
                                className: "hidden",
                            }}
                        />
                        <div className="!absolute left-3 top-[15px] h-[70%] pr-2 border-r border-text-400/70 flex items-center justify-center">
                            <Calendar size="18" className="text-text-400/70" />
                        </div>
                        <div className="!absolute left-[50px] top-2">
                            <Typography className="text-text-400/70 font-bold font-Be_Vietnam_Pro text-sm">Date of completion</Typography>
                        </div>
                    </div>

                </div>

                <div className="flex items-center justify-center mt-5 lg:mt-10">
                    <Button onClick={() => navigate("/courses")}
                        className="w-full lg:w-2/4 bg-primaryLight-500 mt-5 normal-case font-medium text-base font-Be_Vietnam_Pro shadow-none hover:shadow-none"
                    >
                        View Courses
                    </Button>
                </div>
            </div>
        </>
    )
}

export default CertificateDetails;