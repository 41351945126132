import logo from './logo.svg';
import './App.css';
import Router from './routes';
import SmoothScrolling, { useScrollToTop  } from './utils/Smoothscrolling';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const scrollToTop = useScrollToTop();

  useEffect(() => {
    scrollToTop(); // Use the Lenis scroll-to-top function here
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <SmoothScrolling>
        <ScrollToTop />
        <Router />
      </SmoothScrolling>
    </>
  );
}

export default App;
