import api from "../../../config/Apiconfig";

const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['GETUSER'] })

const profileApiSlice = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        editProfile: builder.mutation({ // edit profile 
            query: (params) => {
                return {
                    url: `users/update-user?userId=${params?.userId}`,
                    method: 'POST',
                    body: params?.body,
                    headers: {
                        Authorization: params?.token,  // Send token in Authorization header
                    },
                }
            },
            invalidatesTags: (result, error, { params }) => [{ type: 'GETUSER', userId: params?.userId }],
        }),
        getUser: builder.query({
            query: (params) => { // get individual user
                return {
                    url: `users/user?userId=${params?.userId}`,
                    headers: {
                        Authorization: params?.token,  // Send token in Authorization header
                    },
                }
            },
            providesTags: (result, error, { params }) => [{ type: 'GETUSER', userId: params?.userId }],
        }),
    })
})

export const { useEditProfileMutation, useGetUserQuery } = profileApiSlice;