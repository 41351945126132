import { Typography } from "@material-tailwind/react";
import React from "react";
import checkIcon from "../../../assets/images/checkIcon.png";
import closeIcon from "../../../assets/images/closeIcon.png";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const OurPricingCard = ({ data, index }) => {

  const { accessToken } = useSelector((state) => state.authSlice);
  const navigate = useNavigate();

  const staticData = [
    { text: "Access to selected free courses.", available: true },
    { text: "Limited course materials and resources.", available: true },
    { text: "Basic community support.", available: true },
    { text: "No certification upon completion.", available: true },
    { text: "Ad-supported platform.", available: true },
    {
      text: "Access to exclusive Pro Plan community forums.",
      available: data?.access,
    },
    {
      text: "Early access to new courses and updates.",
      available: data?.earlyAccess,
    },
  ];

  return (
    <div
      key={index}
      className="bg-[#fcfcfd] border-[1px] p-[2vw] pt-[3vw] border-text-50 rounded-[12px] w-full"
    >
      <div className="border-[1px] border-primaryLight-500 bg-primaryLight-500 bg-opacity-20 w-full rounded-md py-3 px-6">
        <Typography className="text-center font-medium text-lg font-Be_Vietnam_Pro text-text-500">
          {data.plan} Plan
        </Typography>
      </div>
      <div className="py-[3vw]">
        <Typography className="text-center font-semibold text-5xl text-text-500 font-Be_Vietnam_Pro ">
          ${data?.price}
          <span className="font-medium text-xl text-text-400">/month</span>
        </Typography>
      </div>
      <div className="w-full bg-[#ffffff] border-[1px] border-[#f1f1f3]  p-[2vw]">
        <Typography className="text-center font-medium text-lg text-text-500 font-Be_Vietnam_Pro">
          Available Features
        </Typography>
        <div className="w-full p-[2vw] pb-0 gap-5 flex flex-col">
          {staticData?.map((item, index) => {
            return (
              <div
                key={index}
                className="border-[1px] border-[#f1f1f3] rounded-lg p-3"
              >
                <Typography className="font-normal text-base font-Be_Vietnam_Pro text-text-400 flex flex-row items-center gap-3">
                  <img
                    src={item?.available ? checkIcon : closeIcon}
                    alt="icon"
                    className="w-[32px] h-[32px]"
                  />
                  {item?.text}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
      <Typography onClick={() => !accessToken ? navigate("/login") : navigate("/checkout/membership")} className="font-Be_Vietnam_Pro w-full bg-primaryLight-500 rounded-b-lg text-center text-white font-semibold text-lg py-5 px-6 cursor-pointer">
        Get Started
      </Typography>
    </div>
  );
};

export default OurPricingCard;
