import { Button, Typography } from "@material-tailwind/react";
import PageHeader from "../../../common/components/PageHeader";
import divImg from '../../../assets/images/Container_aboutus.png';
import { FaCrown, FaMedal } from "react-icons/fa";
import faces from '../../../assets/images/Vector.svg';
import { PiShieldStarFill } from "react-icons/pi";
import { BsBackpackFill, BsPuzzleFill } from "react-icons/bs";
import { ImBook } from "react-icons/im";
import { MdWbTwighlight } from "react-icons/md";
import shape from '../../../assets/images/shape-17.svg';
import robo from '../../../assets/images/robo.png';
import enlighten from '../../../assets/images/enlighten_world.png';
import aiml from '../../../assets/images/aiml.png';
import rev from '../../../assets/images/rev.png';
import prof from '../../../assets/images/prof.webp';
import research from '../../../assets/images/research.webp';
import comm from '../../../assets/images/comm.webp';
import policy from '../../../assets/images/policy.webp';
import benefits from '../../../assets/images/memberbenefits.png';
import { useEffect, useState } from "react";

export const Card = ({ img, icon, heading, text, bgImg, bgColor }) => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        const handleResize = () => setIsLargeScreen(window.innerWidth >= 1024);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={`${bgColor ? bgColor : "bg-white"} w-full sm:!bg-right-top lg:w-[48.3%] rounded-xl p-12 flex flex-col gap-4 items-start justify-start`}
            style={{
                backgroundImage: isLargeScreen && bgImg ? `url(${bgImg})` : 'none',
                backgroundSize: "33% 100%",
                backgroundRepeat: "no-repeat",
                // backgroundPositionX: "100%",
            }}

        >
            {!bgImg && (icon ?
                <div className="bg-primaryLight-500/20 border border-primaryLight-500 rounded-lg w-fit p-2">
                    {icon}
                </div>
                : img ?
                    <div className="rounded-full w-[70px] p-2 h-[70px]">
                        {img}
                    </div>
                    : null
            )}
            <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-lg">
                {heading}
            </Typography>
            <Typography className={`text-text-400 font-Be_Vietnam_Pro font-medium text-sm text-justify ${bgImg && isLargeScreen ? "max-w-[63%]" : "w-full"}`}>
                {text}
            </Typography>
        </div>
    )
}

const AboutUs = () => {

    const products = [
        {
            img: <img src={enlighten} />,
            heading: "Mission",
            text: "At CIS, our mission is to advance cybersecurity knowledge, best practices, and collaboration among professionals and organizations. We aim to support the creation of a safer digital landscape by promoting continuous learning, providing industry-recognized certifications, and advocating for progressive cybersecurity policies.",
            bgColor: "bg-red-500/10"
        },
        {
            img: <img src={aiml} />,
            heading: "Community",
            text: "At CIS, we believe in the power of collaboration. We bring together cybersecurity professionals, thought leaders, researchers, and students to share knowledge, solve challenges, and support one another in a rapidly evolving field. Our community thrives on innovation, inclusivity, and a shared commitment to excellence in information security.",
            bgColor: "bg-yellow-500/10"
        },
        {
            img: <img src={rev} />,
            heading: "Vision",
            text: "We envision a world where digital safety is not a privilege but a standard. By fostering education, awareness, and strong cybersecurity practices, CIS strives to be at the forefront of global efforts to make cyberspace a safe, trusted, and empowering environment.",
            bgColor: "bg-blue-500/10"
        },
        {
            img: <img src={robo} />,
            heading: "What We Do",
            text: "CIS serves as a hub for education, certification, and community-building in the field of information security. Our programs are designed to equip individuals and organizations with the knowledge and skills needed to combat emerging cyber threats and to excel in an increasingly complex security landscape.",
            bgColor: "bg-green-500/10"
        },
    ]

    const achievements = [
        {
            icon: <FaCrown className="text-primaryLight-500 text-lg" />,
            heading: "Professionals Trained",
            text: "Over 5,000 individuals have completed CIS's training programs, equipping them with essential cybersecurity skills. More than 3,000 certifications have been granted, validating the expertise of professionals in various cybersecurity domains.",
            bgImg: prof
        },
        {
            icon: <FaMedal className="text-primaryLight-500 text-lg" />,
            heading: "Research and Publications",
            text: "CIS has released over 20 research papers and whitepapers, providing insights into emerging threats and security trends. The organization has submitted 15 policy recommendations to governmental bodies, influencing national cybersecurity strategies.",
            bgImg: research
        },
        {
            icon: <img src={faces} />,
            heading: "Community Engagement",
            text: "CIS has hosted over 50 events, including seminars, webinars, and workshops, fostering knowledge sharing and collaboration. These events have engaged approximately 10,000 participants, raising awareness and promoting best practices in cybersecurity.",
            bgImg: comm
        },
        {
            icon: <PiShieldStarFill className="text-primaryLight-500 text-lg" />,
            heading: "Advocacy and Policy Influence",
            text: "CIS has partnered with 10 government agencies to develop and implement cybersecurity policies. The council has established 25 partnerships with industry leaders to promote cybersecurity standards and practices.",
            bgImg: policy
        },
    ];

    const goals = [
        {
            icon: <BsBackpackFill className="text-primaryLight-500 text-lg" />,
            heading: "Building a Skilled Cybersecurity Workforce",
            text: "CIS provides certifications, boot camps, and specialized training for cybersecurity professionals. This creates a robust workforce capable of identifying, mitigating, and responding to cyber threats effectively. Programs like the Chief Information Security Officer (CISO) certification prepare executives to oversee and strengthen cybersecurity in organizations, from small businesses to critical infrastructure.",
            bgColor: "bg-red-500/10"
        },
        {
            icon: <ImBook className="text-primaryLight-500 text-lg" />,
            heading: "Raising Awareness and Promoting Cyber Hygiene",
            text: "CIS organizes webinars, seminars, and public events to educate people about safe online practices, phishing risks, and data protection. These initiatives help citizens, businesses, and institutions recognize and prevent common cyber threats. Through educational resources, CIS promotes daily practices that reduce vulnerability to cyber attacks, such as secure password management, data backup, and cautious online behavior.",
            bgColor: "bg-yellow-500/10"
        },
        {
            icon: <BsPuzzleFill className="text-primaryLight-500 text-lg" />,
            heading: "Strengthening Organizational Security",
            text: "CIS emphasizes adopting international best practices, frameworks, and standards, which improve security protocols across sectors. This approach reduces the risk of data breaches, ransomware attacks, and other cyber incidents. By equipping organizations with the skills and resources to respond to cyber incidents, CIS ensures quicker recovery from attacks, limiting their impact on individuals, businesses, and critical services.",
            bgColor: "bg-blue-500/10"
        },
        {
            icon: <MdWbTwighlight className="text-primaryLight-500 text-lg" />,
            heading: "Innovation and Continuous Improvement",
            text: "Through research, hackathons, and innovation programs, CIS drives the development of new tools and techniques to combat emerging cyber threats. This keeps Indian organizations at the forefront of cybersecurity technology. CIS’s regular updates to its training and certifications ensure that cybersecurity professionals stay informed about new attack vectors and defensive technologies, making them better prepared to protect India from evolving threats.",
            bgColor: "bg-green-500/10"
        },
    ]

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <PageHeader
                    spanText="Welcome"
                    text="to Council of Information Security"
                    subHead1="your own online Cyber Range"
                    subHead2="Learn cutting-edge skills from industry experts and enhance your cybersecurity knowledge."
                />

                <div className="flex flex-col md:flex-row items-start md:items-center justify-between mt-20 border-b border-[#E4E4E7] pb-10">
                    <Typography className="text-text-500 font-Be_Vietnam_Pro text-4xl font-semibold w-full md:w-1/4">
                        About CIS
                    </Typography>
                    <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm w-full md:w-3/4">
                        Welcome to the <span className="font-bold">Council of Information Security (CIS)</span>, a leading organization
                        dedicated to fostering a secure and resilient digital environment. We are a community of
                        cybersecurity professionals, practitioners, and enthusiasts united by a shared vision to
                        protect and empower individuals, businesses, and governments in the fight against
                        cyber threats.
                    </Typography>
                </div>

                <div className="my-20 w-full h-full">
                    <img src={divImg} className="w-full h-full object-cover" />
                </div>

                <div className="mb-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Our Values
                    </Typography>
                    <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm w-full md:w-3/4 mt-2">
                        Empowering Cybersecurity. Shaping the Future.
                    </Typography>

                    <div className="mt-10 flex flex-wrap gap-5 lg:gap-7 2xl:gap-11">
                        {products.map((item, index) => (
                            <Card key={index}
                                // img={item.img}
                                heading={item.heading}
                                text={item.text}
                                bgColor={item.bgColor}
                            />
                        ))}
                    </div>
                </div>

                <div className="mb-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Achievements
                    </Typography>
                    <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm w-full md:w-3/4 mt-2">
                        Our commitment to excellence has led us to achieve significant milestones along our journey. Here are some of our notable achievements.
                    </Typography>

                    <div className="mt-10 flex flex-wrap gap-5 lg:gap-7 2xl:gap-11">
                        {achievements.map((item, index) => (
                            <Card key={index}
                                icon={item.icon}
                                heading={item.heading}
                                text={item.text}
                                bgImg={item.bgImg}
                            />
                        ))}
                    </div>
                </div>


                <div className="mb-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Our Goals
                    </Typography>
                    <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm w-full md:w-3/4 mt-2">
                        At CIS, our goal is to empower individuals from all backgrounds to thrive in the world of design and development. We believe that education should be accessible and transformative, enabling learners to pursue their passions and make a meaningful impact.
                    </Typography>

                    <div className="mt-10 flex flex-wrap gap-5 lg:gap-7 2xl:gap-11">
                        {goals.map((item, index) => (
                            <Card key={index}
                                // icon={item.icon}
                                heading={item.heading}
                                text={item.text}
                                bgColor={item.bgColor}
                            />
                        ))}
                    </div>
                </div>

                <img src={benefits} className="my-20 rounded-xl" />

                <div className="bg-white flex flex-col lg:flex-row items-center justify-between rounded-xl" >
                    <div className="w-full lg:w-2/4 p-10">
                        <Typography className="text-text-500 font-Be_Vietnam_Pro text-4xl font-semibold">
                            <span className="text-primaryLight-500">Together</span>,
                            let's shape the future of cyber world!
                        </Typography>

                        <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm mt-2">
                            Join us on this exciting learning journey and unlock your potential in cyber security.
                        </Typography>
                    </div>
                    <img src={shape} className="hidden lg:block" />

                    <Button variant="filled" size="sm" className="bg-primaryLight-500 mr-10 mb-10 lg:mb-0 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium text-white py-2 px-4">
                        Join Now
                    </Button>
                </div>

            </div>
        </>
    )
}

export default AboutUs;