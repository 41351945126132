import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    accessToken: null,
    userDetails: {},
};

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setAccessToken: (state, { payload }) => {
            state.accessToken = payload
        },
        setUserDetails: (state, { payload }) => {
            state.userDetails = payload
        }
    }
});

export const { setAccessToken, setUserDetails } = authSlice.actions;
export default authSlice.reducer;
