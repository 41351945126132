import { Button, Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import HackathonHome from "./Home";
import { Outlet, useMatch, useNavigate } from "react-router";
import IndividualHackathon from "./IndividualHackathon";
import PlayedHackathon from "./Playedhackathons";
import { useEffect, useState } from "react";
import AllHackathon from "./AllHackathon";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "./hackathonTabSlice";

const Hackathon = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isDetailsRoute = useMatch("/hackathon/details/:id");
    const { activeTab } = useSelector((state) => state.hackathonTabSlice);

    const data = [
        {
            label: "Home",
            value: "home",
            el: <HackathonHome />
        },
        {
            label: "All Hackathons",
            value: "all",
            el: <AllHackathon />
        },
        {
            label: "My Hackathons",
            value: "played",
            el: <PlayedHackathon />
        },
    ];

    useEffect(() => { }, [activeTab])

    return (
        <>
            <Tabs key={activeTab} value={activeTab} className="mt-5">
                <TabsHeader
                    className="bg-transparent flex items-center justify-center"
                    indicatorProps={{
                        className: "bg-gray-200 shadow-none !text-text-400 py-2 px-6 ",
                    }}
                >
                    {data.map(({ label, value }) => (
                        <Tab onClick={() => { dispatch(setActiveTab(value)); navigate("/hackathon") }} key={value} value={value} className=" font-Be_Vietnam_Pro text-sm font-medium text-text-400 py-2 px-6 !max-w-fit">
                            {label}
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody>
                    {data.map(({ value, el }) => (
                        <TabPanel key={value} value={value} className="w-full">
                            {!isDetailsRoute ? el : <IndividualHackathon type={activeTab === "home" ? "upcoming" : "played"} />}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </>
    )
}

export default Hackathon;