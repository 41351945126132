import { Button, Chip, Typography } from "@material-tailwind/react";
import PageHeader from "../../../common/components/PageHeader";
import { useNavigate } from "react-router";
import video from '../../../assets/videos/cyber_awareness.mp4'
import { MediaCard, PodcastCard } from "../LandingPage";

const NewsCard = ({ item }) => {
    return (
        <>
            <div className="bg-white w-full lg:w-[31.4%] p-9 rounded-xl">
                <img className="w-full h-full rounded-xl" src="https://images.unsplash.com/photo-1639004643331-9526630beb3d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXJ0aWZpY2lhbCUyMEludGVsbGlnZW5jZSUyMFByb2Nlc3NvciUyMENvbmNlcHQufGVufDB8fDB8fHww" />
                <Typography className="text-text-500 font-Be_Vietnam_Pro font-bold text-base mt-5">
                    {item.title}
                </Typography>
                <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-base mt-2">
                    {item.desc}
                </Typography>
            </div>
        </>
    )
}

const CVECard = ({ item }) => {
    return (
        <>
            <div className={`bg-white w-full lg:w-[31.4%] p-9 rounded-xl`}>
                <Typography className="text-text-500 font-Be_Vietnam_Pro font-bold text-base flex items-center justify-between">
                    {item.title} <Chip value={item.level} className={`${item.level === "high" ? "bg-error-500" : item.level === "medium" ? "bg-orange-500" : "bg-yellow-500 text-text-400"}`} />
                </Typography>
                <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-base mt-2">
                    {item.desc}
                </Typography>
            </div>
        </>
    )
}

const Awareness = () => {
    const navigate = useNavigate();
    const arr = [1, 2, 3];

    const data = [
        {
            title: "Synology Urges Patch for Critical Zero-Click RCE Flaw Affecting Millions of NAS Devices",
            desc: 'Taiwanese network-attached storage (NAS) appliance maker Synology has addressed a critical security flaw impacting DiskStation and BeePhotos that could lead to remote code execution.'
        },
        {
            title: "Canadian Suspect Arrested Over Snowflake Data Breach and Extortion Attacks",
            desc: 'Canadian law enforcement authorities have arrested an individual who is suspected to have conducted a series of hacks stemming from the breach of cloud data warehousing platform Snowflake earlier this year.'
        },
        {
            title: "Massive Git Config Breach Exposes 15,000 Credentials; 10,000 Private Repos Cloned",
            desc: 'Cybersecurity researchers have flagged a "massive" campaign that targets exposed Git configurations to siphon credentials, clone private repositories, and even extract cloud credentials from the source code.'
        },
    ];

    const CVEData = [
        {
            title: "CVE-2024-7876",
            desc: 'The Appointment Booking Calendar — Simply Schedule Appointments Booking Plugin WordPress plugin before 1.6.7.55 does not sanitise and escape some of its Appointment Type settings, which could allow high privilege users such as admin to perform Cross-Site Scripting attacks even when unfiltered_html is disallowed.',
            level: "medium"
        },
        {
            title: "CVE-2024-9632",
            desc: 'A flaw was found in the X.org server. Due to improperly tracked allocation size in _XkbSetCompatMap, a local attacker may be able to trigger a buffer overflow condition via a specially crafted payload, leading to denial of service or local privilege escalation in distributions where the X.org server is run with root privileges.',
            level: "high"
        },
        {
            title: "CVE-2024-10806",
            desc: 'A vulnerability was found in PHPGurukul Hospital Management System 4.0. It has been declared as problematic. This vulnerability affects unknown code of the file betweendates-detailsreports.php. The manipulation of the argument fromdate/todate leads to cross site scripting. The attack can be initiated remotely. The exploit has been disclosed to the public and may be used.',
            level: "low"
        },
    ]

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <PageHeader
                    spanText="Cyber"
                    text="Awareness"
                />
                <div className="flex items-center justify-center flex-col lg:flex-row gap-5 mt-5">
                    <Button
                        className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        Book a Session
                    </Button>
                    <Button
                        className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        View Details
                    </Button>
                </div>

                <video
                    muted
                    autoPlay
                    loop
                    className="rounded-xl w-full h-[300px] lg:h-[720px] object-cover mt-20"
                    src={video}
                >
                </video>

                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Latest in Cyber World
                    </Typography>
                    <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        {/* <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View All
                        </Button> */}
                    </div>

                    <div className="mt-10 flex flex-wrap items-center justify-start gap-7">
                        {data.map((item, index) => (
                            <NewsCard key={index} item={item} />
                        ))}
                    </div>

                </div>

                <div
                    className={`mt-20 rounded-xl shadow-[0px_5px_20px_5px_rgba(44,_109,_231,_0.15)] p-[5vw] flex flex-col sm:flex-col md:flex-col lg:flex-row items-end justify-between gap-[4vw]`}
                >
                    <div className="w-full h-full">
                        <img
                            src="https://img.freepik.com/premium-photo/person-is-using-laptop-with-blog-screen-visible-accompanied-by-cup-coffee-wicker-table_132358-96220.jpg?w=1380"
                            alt="blog"
                            className="w-full h-full"
                        />
                    </div>
                    <div className="w-full h-full flex justify-between flex-col">
                        <div className="w-full h-full">
                            <Typography className="font-Be_Vietnam_Pro font-semibold text-3xl text-text-500 mb-4">
                                Want to write a Blog and share your thoughts with everyone!
                            </Typography>
                            <Typography className="font-Be_Vietnam_Pro font-normal text-lg text-text-400 mb-4">
                                Be the first to hear of our exciting blogs and research
                                papers!
                            </Typography>
                        </div>
                        <Button className="bg-primaryLight-500 py-[1vw] mt-14 text-white shadow-none hover:shadow-none normal-case text-lg font-Be_Vietnam_Pro font-medium" onClick={() => { navigate("/blogs/create-blog") }}>
                            Add Blogs
                        </Button>
                    </div>
                </div>

                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Latest CVEs
                    </Typography>
                    <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                            Common Vulnerabilities and Exposures (CVE) is a publicly accessible database that identifies and catalogs known security vulnerabilities in software and hardware. Each vulnerability is assigned a unique ID, making it easier for organizations to share information, prioritize fixes, and protect their systems.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="mt-10 flex flex-wrap items-stretch justify-stretch gap-7">
                        {CVEData.map((item, index) => (
                            <CVECard key={index} item={item} />
                        ))}
                    </div>
                </div>

                <div className="flex items-start justify-between flex-col lg:flex-row gap-10 w-full mt-20">
                    <div className="w-full lg:w-1/3 ">
                        <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                            Podcast
                        </Typography>

                        <div className="bg-white rounded-xl p-5 mt-6 w-full flex flex-col items-start justify-start gap-5 overflow-auto max-h-[520px]">
                            {arr.map((item, index) => (
                                <PodcastCard key={index} />
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:w-2/3">
                        <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                            Media & Events
                        </Typography>
                        <div className="bg-white rounded-xl p-5 mt-6 w-full flex flex-col items-start justify-start gap-5 overflow-auto max-h-[520px]">
                            {arr.map((item, index) => (
                                <MediaCard key={index} />
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Awareness;