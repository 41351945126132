import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeTab: "home",
};

const hackathonTabSlice = createSlice({
    name: 'hackathonTabSlice',
    initialState,
    reducers: {
        setActiveTab: (state, { payload }) => {
            state.activeTab = payload;
        },
    }
});

export const { setActiveTab } = hackathonTabSlice.actions;
export default hackathonTabSlice.reducer;
