import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    courseActiveTab: "home",
};

const coursesTabSlice = createSlice({
    name: 'coursesTabSlice',
    initialState,
    reducers: {
        setCourseActiveTab: (state, { payload }) => {
            state.courseActiveTab = payload;
        },
    }
});

export const { setCourseActiveTab } = coursesTabSlice.actions;
export default coursesTabSlice.reducer;
