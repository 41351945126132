import { useSelector } from "react-redux";
import OurFaqs from "../../common/components/OurFaqs";
import OurTestimonials from "../../common/components/OurTestimonials";
import UpcomingHackathon from "./UpcomingHackathon"
import { Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router";
import { UpcomingCard } from "./UpcomingCard";
import ExpandedHackathon from "./Expandedhackathon";


const PlayedHackathon = () => {
    const { accessToken } = useSelector((state) => state.authSlice);
    const navigate = useNavigate();
    const prev = [1, 2, 3]
    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                {accessToken ?
                    <>
                        <div className="mb-10 flex flex-col items-start justify-start gap-2">
                            <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                                Scheduled Hackathons
                            </Typography>
                            <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full">
                                Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                                Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                            </Typography>
                        </div>

                        <ExpandedHackathon placement="myhackathon" />

                        <div className="flex flex-wrap items-center justify-center gap-7 mb-20">
                            {prev.map((item, index) => (
                                <UpcomingCard key={index} type="enrolled" />
                            ))}
                        </div>
                        <UpcomingHackathon type="Played" />
                        <div className="mt-20">
                            <OurTestimonials />
                        </div>
                        <div className="mt-20">
                            <OurFaqs />
                        </div>
                    </>
                    :
                    <div className="flex items-center justify-center flex-col">
                        <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-2xl">
                            Login to view your played hackathons
                        </Typography>
                        <Button
                            onClick={() => navigate("/login")}
                            variant="filled"
                            className="mt-5 normal-case shadow-none hover:shadow-none font-semibold text-base font-Be_Vietnam_Pro py-2 px-6 text-white bg-primaryLight-500 focus:ring-0"
                        >
                            Login
                        </Button>
                    </div>
                }
            </div>
        </>
    )
}


export default PlayedHackathon;