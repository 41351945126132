import React from "react";
import { UpcomingCard } from "../../Hackathon/UpcomingCard";
import { Button, Typography } from "@material-tailwind/react";

const Hackathon = () => {
  const prev = [1, 2, 3];

  return (
    <>
      <div className="mt-20">
        <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
          Upcoming Hackathon’s
        </Typography>
        <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2">
          <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget
            elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac cum
            eget habitasse in velit fringilla feugiat senectus in.
          </Typography>

          <Button
            //   onClick={() => dispatch(setCourseActiveTab("all"))}
            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
          >
            View All
          </Button>
        </div>

        <div className="flex flex-wrap items-center justify-center gap-7">
          {prev.map((item, index) => (
            <UpcomingCard key={index} type="not-played" />
          ))}
        </div>
      </div>
      <div className="mt-20">
        <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
          Enrolled Hackathon’s
        </Typography>
        <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2">
          <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget
            elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac cum
            eget habitasse in velit fringilla feugiat senectus in.
          </Typography>

          <Button
            //   onClick={() => dispatch(setCourseActiveTab("all"))}
            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
          >
            View All
          </Button>
        </div>

        <div className="flex flex-wrap items-center justify-center gap-7">
          {prev.map((item, index) => (
            <UpcomingCard key={index} type="played" />
          ))}
        </div>
      </div>
    </>
  );
};

export default Hackathon;
