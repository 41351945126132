import { Typography } from "@material-tailwind/react";
import React from "react";

const OurTestimonialsCard = ({ data, index }) => {
  return (
    <div
      key={index}
      className="grid-cols-1 bg-[#ffffff] rounded-[12px] justify-between"
    >
      <Typography className="text-base font-Be_Vietnam_Pro font-normal text-text-400 p-12 border-b">
        {data?.text}
      </Typography>
      <div className="flex flex-row justify-start items-center text-base font-semibold text-text-500 font-Be_Vietnam_Pro bg-[#FCFCFD] px-10 py-6 rounded-b-[12px]">
        <div className="!w-[60px] !h-[60px] bg-[#f1f1f3] flex justify-center items-center rounded-lg mr-2">
         <img src={data?.img} alt="avatar" className="w-full h-full rounded-[8px] object-cover object-right" />
        </div>{" "}
        {data?.name}
      </div>
    </div>
  );
};

export default OurTestimonialsCard;
