import { Button, Typography } from "@material-tailwind/react";
import mockup from '../../../assets/images/mockup.png';
import { ShoppingCart, TickCircle } from "iconsax-react";

const BootcampPayment = ({ handleNext }) => {
    return (
        <>
            <div className="bg-white w-full lg:w-1/2 p-4 md:p-8 lg:p-12 rounded-xl">
                <Typography className="font-Be_Vietnam_Pro text-3xl font-bold text-text-500">
                    Checkout Summary
                </Typography>
                <Typography className="font-Be_Vietnam_Pro text-sm font-semibold text-text-400/50 mt-5">
                    Bootcamp 2024
                </Typography>

                <img src={mockup} className="mt-5 max-h-[300px] w-full" />

                <div className="flex items-center justify-between mt-5">
                    <Typography className="font-Be_Vietnam_Pro text-sm font-semibold text-text-400">
                        CIS cyber security bootcamp
                    </Typography>
                    <Typography className="font-Be_Vietnam_Pro text-sm font-bold text-text-400">
                        ₹ 2667
                    </Typography>
                </div>
                <div className="flex items-start justify-between mt-2 gap-5">
                    <Typography className="w-[80%] pb-2 text-left font-Be_Vietnam_Pro text-sm font-semibold text-primaryLight-500 flex items-center justify-start gap-1 border-b">
                        25% Early Bird Discount <TickCircle size="15" />
                    </Typography>
                    <Typography className="font-Be_Vietnam_Pro text-sm font-bold text-primaryLight-500">
                        -₹ 667
                    </Typography>
                </div>
                <div className="flex items-start justify-between mt-2 gap-5">
                    <Typography className="font-Be_Vietnam_Pro text-lg font-semibold text-text-400 flex items-start justify-start gap-1">
                        Total Amount
                    </Typography>
                    <Typography className="font-Be_Vietnam_Pro text-lg font-bold text-text-400">
                        ₹ 2000
                    </Typography>
                </div>

                <Button
                    onClick={handleNext}
                    className="w-full flex items-center justify-center gap-2 bg-primaryLight-500 mt-5 normal-case font-medium text-base font-Be_Vietnam_Pro shadow-none hover:shadow-none"
                >
                    <ShoppingCart /> Complete Checkout
                </Button>
            </div>
        </>
    )
}

export default BootcampPayment;