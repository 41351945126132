import { Button, Chip, Typography } from "@material-tailwind/react";
import { ArrowRight, Import } from "iconsax-react";
import ModuleCard from "./ModuleCard";
import { useLocation } from "react-router";

const IndividualModule = () => {

    const location = useLocation();

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <div className="flex items-center justify-between border-b border-text-400/10 pb-10">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500 w-1/2">
                        Module 1 / Unit 1
                    </Typography>
                    <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                        Welcome to our UI/UX Design course! This comprehensive program will equip you with the knowledge and skills to create exceptional user interfaces (UI) and enhance user experiences (UX).
                        Dive into the world of design thinking, wireframing, prototyping, and usability testing. Below is an overview of the curriculum
                    </Typography>
                </div>
                <div className="w-full h-[600px] mt-20">
                    <img className="w-full h-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1552960504-34e1e1be3f53?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                </div>
                <div className="mt-5 flex items-start justify-start gap-5">
                    <Typography className="font-bold text-lg font-Be_Vietnam_Pro text-text-500">
                        The Design System
                    </Typography>
                    <Chip value="Unit 1" className="w-fit normal-case text-green-500  bg-green-50 font-medium text-sm font-Be_Vietnam_Pro" />
                </div>
                <Typography className="text-text-400 text-base font-medium font-Be_Vietnam_Pro w-full text-justify mt-5">
                    Welcome to our UI/UX Design course! This comprehensive program will equip you with the knowledge and skills to create exceptional user interfaces (UI) and enhance user experiences (UX). Dive into the world of design thinking, wireframing, prototyping, and usability testing. Below is an overview of the curriculum. Welcome to our UI/UX Design course! This comprehensive program will equip you with the knowledge and skills to create exceptional user interfaces (UI) and enhance user experiences (UX). Dive into the world of design thinking, wireframing, prototyping, and usability testing. Below is an overview of the curriculum. Welcome to our UI/UX Design course! This comprehensive program will equip you with the knowledge and skills to create exceptional user interfaces (UI) and enhance user experiences (UX).
                    Dive into the world of design thinking, wireframing, prototyping, and usability testing. Below is an overview of the curriculum
                </Typography>
                <Button className="px-4 py-2 rounded-xl flex items-center justify-center gap-1 text-text-500 bg-primaryLight-500/40 normal-case shadow-none hover:shadow-none text-sm font-medium font-Be_Vietnam_Pro mt-5">
                    <Import />  Download File
                </Button>
                <Button variant="outlined" className="float-end px-4 py-2 rounded-xl ring-0 focus:ring-0 flex border-2 border-primaryLight-500 items-center justify-center gap-1 text-primaryLight-500 bg-text-100 normal-case shadow-none hover:shadow-none text-sm font-medium font-Be_Vietnam_Pro mt-5">
                    Next Unit <ArrowRight size="15" />
                </Button>
                <div className="mt-20 w-[85vw] overflow-auto flex items-start justify-start gap-7 pb-3">
                    {location.state.courseData.map((item, index) => (
                        <ModuleCard placement="moduleDetails" courseData={location.state.courseData} courseName="UI_UX_Design_Course" data={item} index={index} key={index} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default IndividualModule;