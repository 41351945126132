import { Navigate, useRoutes } from "react-router";
import HomeLayout from "../common/layout/HomeLayout";
import LandingPage from "../containers/Pages/LandingPage";
import Login from "../containers/Auth/Login";
import Signup from "../containers/Auth/Signup";
import AboutUs from "../containers/Pages/AboutUs";
import Hackathon from "../containers/Hackathon";
import IndividualHackathon from "../containers/Hackathon/IndividualHackathon";
import Checkout from "../containers/Pages/Checkout";
import Bootcamp from "../containers/Pages/Bootcamp";
import IndividualBootcamp from "../containers/Pages/Bootcamp/IndividualBootcamp";
import BootcampRegistration from "../containers/Pages/Bootcamp/BootcampRegistration";
import Courses from "../containers/Pages/Courses";
import IndividualCourse from "../containers/Pages/Courses/IndividualCourse";
import IndividualModule from "../containers/Pages/Courses/IndividualModule";
import ResearchPage from "../containers/ResearchPage";
import ResearchPaperCard from "../containers/ResearchPage/ResearchPaperCard";
import IndividualResearchPaper from "../containers/ResearchPage/IndividualResearchPaper";
import ResearchPaperPayment from "../containers/ResearchPage/ResearchPaperPayment";
import BlogsPage from "../containers/Pages/BlogsPage";
import IndividualBlog from "../containers/Pages/BlogsPage/IndividualBlog";
import CreateBlog from "../containers/Pages/BlogsPage/CreateBlog";
import BlogsPreview from "../containers/Pages/BlogsPage/BlogsPreview";
import Certification from "../containers/Pages/Cerification";
import CertificateDetails from "../containers/Pages/Cerification/CertificateDetails";
import Awareness from "../containers/Pages/Awareness";
import ContactUs from "../containers/Pages/ContactUs";
import CareerPage from "../containers/Pages/CareersPage";
import ClientProfile from "../containers/Pages/ClientProfile";
import { useSelector } from "react-redux";
import ProjectDrona from "../containers/Pages/ProjectDrona";
import Universities from "../containers/Pages/Universities";
import CallForPaper from "../containers/Pages/CallForPaper";
import SubmitArticle from "../containers/Pages/SubmitArticle";

const Router = () => {

    const { accessToken } = useSelector((state) => state.authSlice);

    return useRoutes([
        {
            path: "/", element: <HomeLayout />,
            children: [
                { path: "", element: <LandingPage /> },
                { path: '/login', element: <Login /> },
                { path: '/signup', element: <Signup /> },
                { path: '/about-us', element: <AboutUs /> },
                { path: '/contact-us', element: <ContactUs /> },
                { path: '/career', element: <CareerPage /> },
                { path: '/call-for-paper', element: <CallForPaper /> },
                { path: '/submit-article-win', element: <SubmitArticle /> },
                { path: '/project-drona', element: <ProjectDrona /> },
                { path: '/profile', element: accessToken ? <ClientProfile /> : <Navigate to="/" /> },
                {
                    path: '/hackathon',
                    element: <Hackathon />,
                    children: [
                        { path: 'details/:id', element: <IndividualHackathon /> } // nested under hackathon
                    ]
                },
                { path: '/checkout/:type', element: accessToken ? <Checkout /> : <Navigate to="/login" />},
                {
                    path: '/bootcamp',
                    children: [
                        { index: true, element: <Bootcamp /> },
                        { path: 'details/:id', element: <IndividualBootcamp /> },
                        { path: 'registration', element: <BootcampRegistration /> },
                    ]
                },
                {
                    path: '/courses',
                    element: <Courses />,
                    children: [
                        { path: 'details/:id', element: <IndividualCourse /> }, // nested under courses
                        { path: 'module/:courseName/:id', element: accessToken ? <IndividualModule /> : <Navigate to="/login" /> }, // nested under courses
                    ]
                },
                {
                    path: '/research-paper',
                    element: <ResearchPage />,
                    children: [
                        { path: 'details/:id', element: <IndividualResearchPaper /> },
                    ]
                },
                { path: 'membership', element: <ResearchPaperPayment /> },
                {
                    path: '/blogs',
                    element: <BlogsPage />,
                    children: [
                        { path: 'details/:id', element: <IndividualBlog /> },
                        { path: 'create-blog', element: <CreateBlog /> },
                        { path: 'preview', element: <BlogsPreview /> }
                    ]
                },
                {
                    path: '/certificate',
                    children: [
                        { index: true, element: <Certification /> },
                        { path: 'verification', element: <CertificateDetails /> }
                    ]
                },
                { path: "/awareness", element: <Awareness /> },
                { path: "/universities", element: <Universities /> },

            ]
        }
    ]);
}

export default Router;