import { Typography, Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router";

const quillContent = {
  html: `
    <p><strong>This is bold text</strong> and <em>this is italic text</em>.</p>
    <h2>This is a heading</h2>
    <p>Here’s a list of items:</p>
    <ul>
      <li>First item</li>
      <li>Second item</li>
      <li>Third item</li>
    </ul>
    <p>And here’s a <a href="https://example.com">link</a> to an external site.</p>
    <blockquote>This is a blockquote text.</blockquote>
  `,
};

const BlogsPreview = () => {
const navigate = useNavigate();

  const body = [
    {
      title: "Tennessee Titans",
      bannerImg:
        "https://as2.ftcdn.net/v2/jpg/02/89/72/95/1000_F_289729500_nFn3uaP1yPIuRYwb1wSiRLk6rPHRIjWw.jpg",
      media:
        "https://img.freepik.com/free-photo/hockey-players_654080-1960.jpg?t=st=1729226651~exp=1729230251~hmac=346b1b4f30b6a0f83947e62d579560a05816603521b60a2d1adfdc4ca82f0727&w=1380",
      description: quillContent,
    },
  ];

  return (
    <div className="relative w-full flex justify-center items-center">
      <div className="flex flex-col items-center w-full max-w-3xl justify-center gap-8 py-8">
        <Typography className="text-center font-Be_Vietnam_Pro text-4xl font-bold text-text-600">
          {body[0]?.title}
        </Typography>
        <img
          src={body[0]?.bannerImg}
          alt="imag"
          className="w-full max-w-2xl rounded-lg"
        />
        <div
          className="prose !max-w-sm"
          dangerouslySetInnerHTML={{ __html: body[0]?.description?.html }}
        ></div>

        <div className="relative w-full max-w-md">
          <img src={body[0]?.media} alt="media" className="w-full rounded-lg" />

          {/* Hover effect div */}
          <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-60 opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
            <Button
              variant="outlined"
              color="white"
              className="border-white text-white focus:ring-0 ring-0 shadow-none hover:shadow-none rounded-full w-fit normal-case"
            >
              Delete Photo
            </Button>
          </div>
        </div>
      </div>

       {/* Media Upload (Toggled div) */}
       <div className="lg:fixed md:fixed w-full flex justify-center mb-6 left-1/3 bottom-1/4">
        <div className="flex space-x-4">
          <Button
            variant="outlined"
            color="blue"
            className="normal-case font-Be_Vietnam_Pro bg-[#F7F7F8]"
          >
            Save as draft
          </Button>
          <Button
            variant="filled"
            color="blue"
            className="normal-case font-Be_Vietnam_Pro hover:shadow-none shadow-none"
            onClick={()=>{navigate('/blogs')}}
          >
            Post
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BlogsPreview;
