import { Avatar, Button, Chip, Input, Typography } from "@material-tailwind/react";
import { useParams } from "react-router";

const ItemCard = () => {
    return (
        <>
            <div className="bg-white !rounded-xl w-full">
                <div className="p-10 pb-10 border-b">
                    <Typography className="font-Be_Vietnam_Pro text-base font-semibold text-text-500">
                        Hackathon
                    </Typography>
                    <Typography className="font-Be_Vietnam_Pro text-base font-normal text-text-400 mt-2">
                        Learn the fundamentals of web design, including HTML, CSS, and responsive design principles.
                    </Typography>

                    <div className="mt-5 flex gap-4 md:gap-0 items-start md:items-center justify-between flex-col md:flex-row">
                        <div className="flex items-start justify-start gap-2">
                            <Chip value="Beginner" className="w-fit normal-case text-green-500  bg-green-50 font-medium text-sm font-Be_Vietnam_Pro" />
                            <Chip value="4 weeks" className="w-fit normal-case text-text-500  bg-transparent border border-text-50 font-medium text-sm font-Be_Vietnam_Pro" />
                        </div>
                        <Typography className="text-text-500 font-Be_Vietnam_Pro font-medium text-base">
                            By John Smith
                        </Typography>
                    </div>
                    <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-base mt-2">
                        ₹ 5000
                    </Typography>
                </div>
                <div className="flex items-start gap-4 md:gap-0 md:items-center flex-col md:flex-row justify-between p-10 bg-[#FCFCFD] rounded-xl">
                    <div className="flex items-center  gap-2">
                        <Avatar
                            className="!rounded-lg"
                            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                        />
                        <Typography className="font-Be_Vietnam_Pro font-semibold text-base text-text-500">John Smith</Typography>
                    </div>
                    <Button className="bg-text-100 shadow-none hover:shadow-none normal-case font-Be_Vietnam_Pro font-semibold text-base text-text-500">
                        Delete
                    </Button>
                </div>
            </div>
        </>
    )
}

const Checkout = () => {

    const params = useParams();

    return (
        <>
            <div className="flex justify-between flex-col lg:flex-row p-0 lg:p-20 gap-24">
                <div className="w-full lg:w-[60%]">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Cart
                    </Typography>
                    <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro mt-4">
                        Here is the {params.type} which you selected to enroll!
                    </Typography>

                    <div className="mt-10 flex flex-col items-center justify-center gap-10">
                        <ItemCard />
                        <ItemCard />
                    </div>
                </div>
                <div className="bg-white w-full lg:w-[40%] p-4 md:p-8 lg:p-12 rounded-xl">
                    <div className="flex flex-col items-center justify-center gap-2">
                        <Typography className="font-Be_Vietnam_Pro text-4xl font-bold text-text-500">
                            Checkout
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro text-base font-normal text-text-400">
                            Welcome to the payment! Please enter your details to make payment.
                        </Typography>
                    </div>

                    <div className="pt-10 pb-5 flex flex-col gap-10">
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                                Full Name
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter your Full Name"
                                className="!border py-6 !rounded-lg !border-[#F1F1F3] font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                                Phone Number
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter your Phone Number"
                                className="!border py-6 !rounded-lg !border-[#F1F1F3] font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                                Address
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter your Address"
                                className="!border py-6 !rounded-lg !border-[#F1F1F3] font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                        <div className="flex gap-5 flex-col lg:flex-row">
                            <div className="w-full">
                                <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                                    Country
                                </Typography>
                                <Input
                                    type="text"
                                    placeholder="Enter Country"
                                    className="!border py-6 !rounded-lg !border-[#F1F1F3] font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    containerProps={{ className: "min-w-[100px]" }}
                                />
                            </div>
                            <div className="w-full">
                                <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                                    State
                                </Typography>
                                <Input
                                    type="text"
                                    placeholder="Enter State"
                                    className="!border py-6 !rounded-lg !border-[#F1F1F3] font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    containerProps={{ className: "min-w-[100px]" }}
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-500 mb-2">
                                Coupons
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter Coupon Code"
                                className="!border py-6 !rounded-lg !border-[#F1F1F3] font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:text-text-400 placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                    </div>


                    <Button
                        className="w-full bg-primaryLight-500 mt-5 normal-case font-medium text-base font-Be_Vietnam_Pro shadow-none hover:shadow-none"
                    >
                        Checkout
                    </Button>


                </div>
            </div>
        </>
    )
}

export default Checkout;