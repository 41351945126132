import { Outlet } from "react-router";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Toaster } from "react-hot-toast";

const HomeLayout = () => {
    return (
        <>
            <Navbar />
            <div className="p-6 pt-2">
                <Outlet />
            </div>
            <Footer />

            <Toaster
                position="top-center"
                toastOptions={{
                    success: {
                        style: {
                            borderBottom: "4px solid green",
                            color: "green",
                            borderRadius: "5px",
                        },
                    },
                    error: {
                        style: {
                            borderBottom: "4px solid red",
                            color: "red",
                            borderRadius: "5px",
                        },
                        icon: "❌",
                        iconTheme: {
                            primary: "red",
                            secondary: "red",
                        },
                    },
                }}
            />
        </>
    )
}

export default HomeLayout;