import { Button, Input, Typography } from "@material-tailwind/react";


const RegistrationForm = ({ handleNext }) => {
    return (
        <>
            <div className="bg-white w-full lg:w-1/2 p-4 md:p-8 lg:p-12 rounded-xl">
                <Typography className="font-Be_Vietnam_Pro text-3xl font-bold text-text-500">
                    Registration Form
                </Typography>

                <div className="pt-10 flex flex-col gap-10">

                    {/* ---------------------full name and phone number ---------------- */}
                    <div className="flex items-center justify-center gap-5 flex-col lg:flex-row">
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                                Full Name
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter your full name"
                                className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                                Phone Number
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter your phone number"
                                className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                    </div>

                    {/* ---------------email-------------- */}
                    <div className="w-full">
                        <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                            Email Address
                        </Typography>
                        <Input
                            type="email"
                            placeholder="Email Address"
                            className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                            labelProps={{
                                className: "hidden",
                            }}
                            containerProps={{ className: "min-w-[100px]" }}
                        />
                    </div>

                    {/* ---------------------college name------------------- */}
                    <div className="w-full">
                        <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                            College Name
                        </Typography>
                        <Input
                            type="text"
                            placeholder="Enter your college name"
                            className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"

                            labelProps={{
                                className: "hidden",
                            }}
                            containerProps={{ className: "min-w-[100px]" }}
                        />
                    </div>

                    {/* ---------------------passing year and education degree---------------- */}
                    <div className="flex items-center justify-center gap-5 flex-col lg:flex-row">
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                                Passing Year
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter your passing year"
                                className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                                Education Degree
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter your education degree"
                                className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                    </div>

                    {/* ---------------------country and state---------------- */}
                    <div className="flex items-center justify-center gap-5 flex-col lg:flex-row">
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                                Country
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter your country"
                                className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"

                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                        <div className="w-full">
                            <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                                State
                            </Typography>
                            <Input
                                type="text"
                                placeholder="Enter your state"
                                className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                                labelProps={{
                                    className: "hidden",
                                }}
                                containerProps={{ className: "min-w-[100px]" }}
                            />
                        </div>
                    </div>

                    {/* ---------------------platform------------------- */}
                    <div className="w-full">
                        <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                            Got to know about this program from
                        </Typography>
                        <Input
                            type="text"
                            placeholder="Select your platform"
                            className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                            labelProps={{
                                className: "hidden",
                            }}
                            containerProps={{ className: "min-w-[100px]" }}
                        />
                    </div>

                    {/* ---------------------LinkedIn Profile------------------- */}
                    <div className="w-full">
                        <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2">
                            LinkedIn Profile
                        </Typography>
                        <Input
                            type="text"
                            placeholder="Enter your LinkedIn Profile"
                            className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                            labelProps={{
                                className: "hidden",
                            }}
                            containerProps={{ className: "min-w-[100px]" }}
                        />
                    </div>

                    {/* ---------------------Why do you want to join? ------------------- */}
                    <div className="w-full">
                        <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2 flex items-center justify-between">
                            Why do you want to join? <span>(Optional)</span>
                        </Typography>
                        <Input
                            type="text"
                            placeholder="Type your answer"
                            className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                            labelProps={{
                                className: "hidden",
                            }}
                            containerProps={{ className: "min-w-[100px]" }}
                        />
                    </div>

                    {/* ---------------------Referral Code------------------- */}
                    <div className="w-full">
                        <Typography className="font-Be_Vietnam_Pro text-base font-medium text-text-400 mb-2 flex items-center justify-between">
                            Referral Code<span>(Optional)</span>
                        </Typography>
                        <Input
                            type="text"
                            placeholder="Enter Code"
                            className="!border py-6 !rounded-lg !border-text-400/50 font-Be_Vietnam_Pro bg-white text-text-500 shadow-none ring-0 ring-transparent placeholder:!text-text-400/50 placeholder:font-semibold placeholder:font-Be_Vietnam_Pro placeholder:opacity-100 focus:!border-[#F1F1F3] focus:!border-t-[#F1F1F3] focus:ring-0"
                            labelProps={{
                                className: "hidden",
                            }}
                            containerProps={{ className: "min-w-[100px]" }}
                        />
                    </div>

                </div>

                <Button onClick={handleNext}
                    className="float-end bg-primaryLight-500 mt-5 normal-case font-medium text-base font-Be_Vietnam_Pro shadow-none hover:shadow-none"
                >
                    Continue
                </Button>

            </div>
        </>
    )
}

export default RegistrationForm;