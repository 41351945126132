import { Button, Chip, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router";

export const CourseCard = ({ placement }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="bg-white mt-10 p-3 rounded-xl w-full lg:w-[31.4%]">
                <div className="cursor-pointer" onClick={() => navigate("/courses/details/0")}>
                    <img className="h-[300px] w-full rounded-xl object-cover" src="https://plus.unsplash.com/premium_photo-1661299224680-8418572c658e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c3RpY2t5JTIwbm90ZXN8ZW58MHx8MHx8fDA%3D" />
                    <div className="mt-5 flex items-center justify-between">
                        <div className="flex items-start justify-start gap-2">
                            <Chip value="Beginner" className="w-fit normal-case text-green-500  bg-green-50 font-medium text-xs font-Be_Vietnam_Pro" />
                            <Chip value="4 weeks" className="w-fit normal-case text-text-500  bg-transparent border border-text-50 font-medium text-xs font-Be_Vietnam_Pro" />
                        </div>
                        <Typography className="text-text-500 font-Be_Vietnam_Pro font-medium text-base">
                            By John Smith
                        </Typography>
                    </div>

                    <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-base mt-5">
                        Web Design Fundamentals
                    </Typography>
                    <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-base mt-2">
                        Learn the fundamentals of web design, including HTML, CSS, and responsive design principles.
                    </Typography>
                    {placement !== "home" &&
                        <Typography className="text-primaryLight-500 font-Be_Vietnam_Pro font-semibold text-base mt-2">
                            ₹ 5000
                        </Typography>
                    }
                </div>


                {placement !== "my_courses" ?
                    <Button
                        onClick={() => navigate("/checkout/courses")}
                        variant="outlined"
                        className="ring-0 focus:ring-0 border-2 border-primaryLight-500 text-primaryLight-500 text-base font-semibold font-Be_Vietnam_Pro normal-case w-full mt-5"
                    >
                        Get It Now
                    </Button>
                    :
                    <Button
                        onClick={() => navigate("/courses/details/0")}
                        variant="outlined"
                        className="ring-0 focus:ring-0 border-2 border-primaryLight-500 text-primaryLight-500 text-base font-semibold font-Be_Vietnam_Pro normal-case w-full mt-5"
                    >
                        Resume Course
                    </Button>
                }
            </div>
        </>
    )
}