import { Step, Stepper, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "./bootcampSlice";
import RegistrationForm from "./RegistrationForm";
import BootcampPayment from "./BootcampPayment";
import PaymentResult from "./PaymentResult";


const BootcampRegistration = () => {

    const { activeStep } = useSelector((state) => state.bootcampSlice);
    const dispatch = useDispatch();
    const [isLastStep, setIsLastStep] = useState(false);
    const [isFirstStep, setIsFirstStep] = useState(false);

    const handleNext = () => !isLastStep && dispatch(setActiveStep(activeStep + 1));
    const handlePrev = () => !isFirstStep && dispatch(setActiveStep(activeStep - 1));

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20 flex items-center justify-center flex-col gap-14 ">
                <div className="w-full lg:w-[550px] overflow-auto lg:overflow-visible">
                    <Stepper
                        activeStep={activeStep}
                        lineClassName="bg-text-400/50"
                        activeLineClassName="bg-primaryLight-500"
                        isLastStep={(value) => setIsLastStep(value)}
                        isFirstStep={(value) => setIsFirstStep(value)}
                    >
                        <Step
                            activeClassName="!text-primaryLight-500"
                            completedClassName="!text-primaryLight-500"
                            className="!bg-transparent !text-text-400/50 cursor-pointer flex flex-wrap lg:grid"
                            onClick={() => dispatch(setActiveStep(0))}
                        >
                            <div className="absolute w-max text-center bg-text-100 px-2 lg:px-6">
                                <Typography
                                    color="inherit"
                                    className={`font-medium font-Be_Vietnam_Pro text-base`}
                                >
                                    Fill Information
                                </Typography>
                            </div>
                        </Step>
                        <Step
                            activeClassName="!text-primaryLight-500"
                            completedClassName="!text-primaryLight-500"
                            className="!bg-transparent !text-text-400/50 cursor-pointer flex flex-wrap lg:grid"
                            onClick={() => dispatch(setActiveStep(1))}
                        >
                            <div className="absolute w-max text-center bg-text-100 px-2 lg:px-6">
                                <Typography color="inherit"
                                    className={`font-medium font-Be_Vietnam_Pro text-base`}
                                >
                                    Make Payment
                                </Typography>
                            </div>
                        </Step>
                        <Step
                            activeClassName="!text-primaryLight-500"
                            completedClassName="!text-primaryLight-500"
                            className="!bg-transparent text-text-400/50 cursor-pointer flex flex-wrap lg:grid"
                            onClick={() => dispatch(setActiveStep(2))}
                        >
                            <div className="absolute w-max text-center bg-text-100 px-2 lg:px-6">
                                <Typography color="inherit"
                                    className={`font-medium font-Be_Vietnam_Pro text-base`}
                                >
                                    Successful
                                </Typography>
                            </div>
                        </Step>
                    </Stepper>
                </div>

                {activeStep === 0 && <RegistrationForm handleNext={handleNext} />}
                {activeStep === 1 && <BootcampPayment handleNext={handleNext} />}
                {activeStep === 2 && <PaymentResult handleNext={handleNext} />}


            </div>
        </>
    )
}

export default BootcampRegistration;