import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import { createContext, useContext } from "react";

// Create a context to provide the scrollToTop function
const ScrollContext = createContext(() => {});

export const useScrollToTop = () => useContext(ScrollContext);

function SmoothScrolling({ children }) {
    const lenis = useLenis();

    const scrollToTop = () => {
        if (lenis) {
            lenis.scrollTo(0, {
                duration: 1.7,
                immediate: false,
            });
        }
    };

    return (
        <ScrollContext.Provider value={scrollToTop}>
            <ReactLenis root options={{ duration: 1.7, smoothTouch: false, smooth: true }}>
                {children}
            </ReactLenis>
        </ScrollContext.Provider>
    );
}

export default SmoothScrolling;
