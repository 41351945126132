import { Typography } from "@material-tailwind/react";
import ModuleCard from "./ModuleCard";


const IndividualCourse = () => {

    const courseModules = [
        {
            title: "Introduction to UI/UX Design",
            lessons: [
                {
                    title: "Understanding UI/UX Design Principles",
                    time: "45 minutes"
                },
                {
                    title: "Importance of User-Centered Design",
                    time: "1 Hour"
                },
                {
                    title: "The Role of UI/UX Design in Product Development",
                    time: "45 minutes"
                },
            ]
        },
        {
            title: "User Research and Analysis",
            lessons: [
                {
                    title: "Conducting User Research and Interviews",
                    time: "1 Hour"
                },
                {
                    title: "Analyzing User Needs and Behavior",
                    time: "1 Hour"
                },
                {
                    title: "Creating User Personas and Scenarios",
                    time: "45 minutes"
                },
            ]
        },
        {
            title: "Wireframing and Prototyping",
            lessons: [
                {
                    title: "Introduction to Wireframing Tools and Techniques",
                    time: "45 minutes"
                },
                {
                    title: "Creating Low-Fidelity Wireframes",
                    time: "1 Hour"
                },
                {
                    title: "Prototyping and Interactive Mockups",
                    time: "45 minutes"
                },
            ]
        },
        {
            title: "Visual Design and Branding",
            lessons: [
                {
                    title: "Color Theory and Typography in UI Design",
                    time: "45 minutes"
                },
                {
                    title: "Visual Hierarchy and Layout Design",
                    time: "1 Hour"
                },
                {
                    title: "Creating a Strong Brand Identity",
                    time: "45 minutes"
                },
            ]
        },
        {
            title: "Usability Testing and Iteration",
            lessons: [
                {
                    title: "Usability Testing Methods and Techniques",
                    time: "45 minutes"
                },
                {
                    title: "Analyzing Usability Test Results",
                    time: "1 Hour"
                },
                {
                    title: "Iterating and Improving UX Designs",
                    time: "45 minutes"
                },
            ]
        },
    ]

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <div className="flex items-center justify-between border-b border-text-400/10 pb-10">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500 w-1/2">
                        UI/UX Design Course
                    </Typography>
                    <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                        Welcome to our UI/UX Design course! This comprehensive program will equip you with the knowledge and skills to create exceptional user interfaces (UI) and enhance user experiences (UX).
                        Dive into the world of design thinking, wireframing, prototyping, and usability testing. Below is an overview of the curriculum
                    </Typography>
                </div>

                <div className="w-full h-[600px] mt-20">
                    <img className="w-full h-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1552960504-34e1e1be3f53?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                </div>

                <div className="mt-20 flex items-start justify-start flex-wrap gap-7">
                    {courseModules.map((item, index) => (
                        <ModuleCard placement="courseDetails" courseData={courseModules} courseName="UI_UX_Design_Course" data={item} index={index} key={index} />
                    ))}
                </div>

            </div>
        </>
    )
}

export default IndividualCourse;