import React, { useState } from "react";
import PageHeader from "../../../common/components/PageHeader";
import searchIcon from "../../../assets/images/Search icon.png";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Button, Input, Typography } from "@material-tailwind/react";
import { Send, LoginCurve } from "iconsax-react";
import BlogsCard from "./BlogsCard";

const BlogsPage = () => {
  // const [loginAccess, setLoginAccess] = useState(false);
  const [inputValue, setInputValue] = useState("Search Blog");
  const location = useLocation();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    let value = e.target.value;

    // If the input value is exactly "Search Blog", prevent backspacing
    if (
      value === "Search Blog" &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return; // Prevent any backspace action
    }

    // If "Search Blog" is part of the value, remove it
    if (value.includes("Search Blog")) {
      value = value.replace(/^Search Blog\s*/, ""); // Removes "Search Blog" from the beginning
    }

    // If the user clears the field, set it back to "Search Blog"
    if (
      value.trim() === "" ||
      value.trim() === "Search Blog" ||
      value.trim() === "Search Blo"
    ) {
      setInputValue("Search Blog");
    } else {
      setInputValue(value);
    }
  };

  const handleFocus = () => {
    if (inputValue === "Search Blog") {
      setInputValue("");
    }
  };

  const handleBlur = () => {
    if (inputValue.trim() === "") {
      setInputValue("Search Blog");
    }
  };
  const reserchPapers = [
    {
      heading: "Flexible Learning Schedule",
      text: "Fit your coursework around your existing commitments and obligations.",
    },
    {
      heading: "Expert Instruction",
      text: "Learn from industry experts who have hands-on experience in design and development.",
    },
    {
      heading: "Diverse Course Offerings",
      text: "Explore a wide range of design and development courses covering various topics.",
    },
    {
      heading: "Updated Curriculum",
      text: "Access courses with up-to-date content reflecting the latest trends and industry practices.",
    },
    {
      heading: "Practical Projects and Assignments",
      text: "Develop a portfolio showcasing your skills and abilities to potential employers.",
    },
    {
      heading: "Interactive Learning Environment",
      text: "Collaborate with fellow learners, exchanging ideas and feedback to enhance your understanding.",
    },
  ];

  return (
    <>
      {location.pathname === "/blogs" ? (
        <>
          <div className="p-[4vw]">
            <PageHeader
              spanText="Unlock"
              text="Your Creative Potential"
              subHead1="with Online Design and Development Courses."
              subHead2="Learn from Industry Experts and Enhance Your Skills."
            />
            <div className="flex items-center justify-center mt-10 gap-5">
              <Button className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium">
                Explore Blogs
              </Button>
              <Button className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium">
                View Research Paper
              </Button>
            </div>
          </div>
          <div className="items-center gap-x-2 flex w-full justify-center">
            <div className="relative flex w-full gap-2 md:w-max">
              <Input
                type="search"
                placeholder="Search Blog"
                value={inputValue}
                onFocus={handleFocus}
                onBlur={handleBlur}
                containerProps={{
                  className:
                    "min-w-[350px] sm:min-w-[400px] md:min-w-[500px] lg:min-w-[596px] !h-[64px] h-full w-full",
                }}
                className="bg-white shadow-[0px_5px_20px_5px_rgba(44,_109,_231,_0.15)] !border-none pl-[60px] pt-[10px] placeholder:!text-text-500 rounded-[18px] h-[64px] placeholder:!font-Be_Vietnam_Pro placeholder:!font-normal placeholder:!text-2xl w-full !text-text-500 !font-Be_Vietnam_Pro !font-normal !text-2xl"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                onChange={handleInputChange}
              />
              <div className="!absolute left-3 top-[15px]">
                <img src={searchIcon} alt="search icon" />
              </div>
              <Button
                size="md"
                className="!absolute right-3 top-[8px] bg-primaryLight-500 !p-1 !m-1 text-white rounded-[7px] shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium"
              >
                <Send size="32" />
              </Button>
            </div>
          </div>

          <div className="relative w-full h-full">
            {/* --------------------------static Top Trending Research Papers section---------- */}
            <div className={`mt-10 p-[4vw]`}>
              <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                Top Trending Blogs
              </Typography>
              <div className="flex items-start justify-between mt-2 gap-2 flex-col sm:flex-col md:flex-row lg:flex-row">
                <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-3/4">
                  Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam
                  eget elit id imperdiet et. Cras eu sit dignissim lorem nibh
                  et. Ac cum eget habitasse in velit fringilla feugiat senectus
                  in.
                </Typography>

                <Button className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium">
                  View All
                </Button>
              </div>

              <div className="mt-10 flex flex-wrap items-center justify-center gap-7">
                {reserchPapers.map((item, index) => (
                  <BlogsCard
                    key={index}
                    index={index}
                    heading={item.heading}
                    text={item.text}
                  />
                ))}
              </div>
            </div>

            <div
              className={`mt-10 rounded-xl shadow-[0px_5px_20px_5px_rgba(44,_109,_231,_0.15)] p-[5vw] m-[3vw] flex flex-col sm:flex-col md:flex-col lg:flex-row items-end justify-between gap-[4vw]`}
            >
              <div className="w-full h-full">
                <img
                  src="https://img.freepik.com/premium-photo/person-is-using-laptop-with-blog-screen-visible-accompanied-by-cup-coffee-wicker-table_132358-96220.jpg?w=1380"
                  alt="blog"
                  className="w-full h-full"
                />
              </div>
              <div className="w-full h-full flex justify-between flex-col">
                <div className="w-full h-full">
                  <Typography className="font-Be_Vietnam_Pro font-semibold text-3xl text-text-500 mb-4">
                    Want to write a Blog and share your thoughts with everyone!
                  </Typography>
                  <Typography className="font-Be_Vietnam_Pro font-normal text-lg text-text-400 mb-4">
                    Be the first to hear of our exciting blogs and research
                    papers!
                  </Typography>
                </div>
                <Button className="bg-primaryLight-500 py-[1vw] mt-14 text-white shadow-none hover:shadow-none normal-case text-lg font-Be_Vietnam_Pro font-medium" onClick={()=>{navigate("/blogs/create-blog")}}>
                  Add Blogs
                </Button>
              </div>
            </div>

            {/* --------------------------static Explore all section---------- */}
            <div className={`mt-10 p-[4vw]`}>
              <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                Explore all
              </Typography>
              <div className="flex items-start justify-between mt-2 gap-2 flex-col sm:flex-col md:flex-row lg:flex-row">
                <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-3/4">
                  Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam
                  eget elit id imperdiet et. Cras eu sit dignissim lorem nibh
                  et. Ac cum eget habitasse in velit fringilla feugiat senectus
                  in.
                </Typography>

                <Button className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium">
                  View All
                </Button>
              </div>

              <div className="mt-10 flex flex-wrap items-center justify-center gap-7">
                {reserchPapers.map((item, index) => (
                  <BlogsCard
                    key={index}
                    index={index}
                    heading={item.heading}
                    text={item.text}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default BlogsPage;
