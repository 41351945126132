import React, { useState } from "react";
import { Button, Input, Card, IconButton } from "@material-tailwind/react";
import { AiOutlinePlus } from "react-icons/ai";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.css";
import imgIcon from "../../../assets/images/imageicon.png";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { CiMicrophoneOn } from "react-icons/ci";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { ImFacebook2 } from "react-icons/im";
import { FaSquareInstagram } from "react-icons/fa6";
import { useNavigate } from "react-router";

const CreateBlog = () => {
  const [showMediaUpload, setShowMediaUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const handleImageUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(URL.createObjectURL(uploadedFile));
    }
  };

  const toggleMediaUpload = () => {
    setShowMediaUpload(!showMediaUpload);
  };

  const modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ align: [] }],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
  ];

  return (
    <div className="relative w-full h-full">
      <div className="bg-transparent min-h-[50vh] h-full flex flex-col justify-start gap-6 py-[4vw]">
        {/* Title Input */}
        <div className="mb-6">
          <Input
            type="text"
            placeholder="Title..."
            className="!border-0 py-6 min-h-[70px] text-center placeholder:text-center placeholder:text-6xl !text-6xl !rounded-lg font-Be_Vietnam_Pro placeholder:font-Be_Vietnam_Pro text-text-400 shadow-none ring-0 ring-transparent placeholder:text-text-200 placeholder:opacity-100 focus:ring-0"
            labelProps={{
              className: "hidden",
            }}
            containerProps={{ className: "w-full" }}
          />
        </div>

        {/* Banner Image Upload */}
        <div className="w-full flex justify-center mb-6">
          <Card
            shadow={false}
            className="border-dashed border-2 border-gray-300 mb-6 flex justify-center items-center bg-transparent max-w-xl w-full"
          >
            <div className="flex flex-col justify-center items-center p-4 w-full">
              {file ? (
                <img
                  src={file}
                  alt="Uploaded Showcase"
                  className="w-full h-auto max-h-60 object-cover"
                />
              ) : (
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer text-gray-500 py-2 px-4 rounded-lg"
                >
                  <div className="flex justify-center gap-4 items-center my-4">
                    <img
                      src={imgIcon}
                      alt="icon"
                      className="w-[55px] h-[36px]"
                    />
                    Add Showcase Image
                    <input
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageUpload}
                    />
                  </div>
                </label>
              )}
            </div>
          </Card>
        </div>

        {/* Text Editor (Simulated with textarea for simplicity) */}
        {/* <div className="w-full flex justify-center mb-6">
          <div className="mb-6 max-w-xl w-full border rounded-lg border-none">
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              className="custom-quill"
              placeholder="Tell your story..."
              style={{
                //   height: "30vw",
                //   maxHeight: "200px",
                minHeight: "200px",
              }}
            />
          </div>
        </div> */}

        {/* Text Editor */}
        <div className="w-full flex justify-center mb-6">
          <div className="mb-6 max-w-xl w-full border rounded-lg border-none">
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              modules={modules}
              formats={formats}
              className="custom-quill"
              placeholder="Tell your story..."
              style={{ minHeight: "200px" }}
            />
          </div>
        </div>

        {/* <div className="w-full flex justify-center mb-6">
          <div className="flex flex-row max-w-xl justify-between items-start">
            {!showMediaUpload && (
              <div className="h-fit w-fit flex justify-center mb-6">
                <Button
                  variant="outlined"
                  color="black"
                  onClick={toggleMediaUpload}
                  className="focus:ring-0 ring-0 border-2 !py-1 !px-3 !m-1"
                >
                  <AiOutlinePlus className="inline-block w-fit" />
                </Button>
              </div>
            )}
            {showMediaUpload && (
              <div className="h-fit w-fit flex justify-center mb-6">
                <Button
                  variant="text"
                  color="black"
                  onClick={toggleMediaUpload}
                  className="focus:ring-0 ring-0 !py-1 !px-3 !m-1"
                >
                  <IoCloseSharp className="inline-block h-[24px] w-[24px]" />
                </Button>
              </div>
            )}

            {showMediaUpload && (
              <div className="w-full flex justify-center mb-6">
                <div className="px-[2.5vw] py-[1.5vw] border rounded-lg border-gray-300 bg-white">
                  <h4 className="text-lg font-bold mb-4">Upload Media</h4>
                  <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-4">
                    <Button
                      variant="filled"
                      className="hover:shadow-none shadow-none flex items-center flex-col normal-case bg-[#F5F7F9] text-[#7E7F81]"
                    >
                      <MdOutlinePhotoSizeSelectActual
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#141A28",
                        }}
                      />
                      Photo
                    </Button>
                    <Button
                      variant="filled"
                      className="hover:shadow-none shadow-none flex items-center flex-col normal-case bg-[#F5F7F9] text-[#7E7F81]"
                    >
                      <FaRegPlayCircle
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#141A28",
                        }}
                      />
                      Video
                    </Button>
                    <Button
                      variant="filled"
                      className="hover:shadow-none shadow-none flex items-center flex-col normal-case bg-[#F5F7F9] text-[#7E7F81]"
                    >
                      <CiMicrophoneOn
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#141A28",
                        }}
                      />
                      Audio
                    </Button>
                    <Button
                      variant="filled"
                      className="hover:shadow-none shadow-none flex items-center flex-col normal-case bg-[#F5F7F9] text-[#7E7F81]"
                    >
                      <FaTwitter
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#141A28",
                        }}
                      />
                      Twitter
                    </Button>
                    <Button
                      variant="filled"
                      className="hover:shadow-none shadow-none flex items-center flex-col normal-case bg-[#F5F7F9] text-[#7E7F81]"
                    >
                      <ImFacebook2
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#141A28",
                        }}
                      />
                      Facebook
                    </Button>
                    <Button
                      variant="filled"
                      className="hover:shadow-none shadow-none flex items-center flex-col normal-case bg-[#F5F7F9] text-[#7E7F81]"
                    >
                      <FaSquareInstagram
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#141A28",
                        }}
                      />
                      Instagram
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div> */}
      </div>
      {/* Media Upload (Toggled div) */}
      <div className="lg:sticky md:sticky w-full flex justify-center lg:justify-end md:justify-end mb-6 pr-[5vw] bottom-1/4">
        <div className="flex space-x-4">
          <Button
            variant="outlined"
            color="blue"
            className="normal-case font-Be_Vietnam_Pro bg-[#F7F7F8]"
          >
            Save as draft
          </Button>
          <Button
            variant="filled"
            color="blue"
            className="normal-case font-Be_Vietnam_Pro hover:shadow-none shadow-none"
            onClick={() => {
              navigate("/blogs/preview");
            }}
          >
            Preview
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateBlog;
