import { Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router";


const ExpandedCourseCard = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="bg-white mt-10 p-10 rounded-xl w-full">
                <Typography className="text-text-500 text-base font-semibold font-Be_Vietnam_Pro">
                    Mobile App Development
                </Typography>

                <div className="flex items-start justify-between flex-col lg:flex-row mt-2 gap-5 lg:gap-0" >
                    <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                        Dive into the world of mobile app development. Learn to build native iOS and Android applications using industry-leading frameworks like Swift and Kotlin.
                    </Typography>

                    <Button
                        onClick={() => navigate("/courses/details/0")}
                        className="bg-text-100 text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        View Course
                    </Button>
                </div>
                <div className="flex items-center justify-center flex-col lg:flex-row gap-5 mt-5">
                    <div className="w-full lg:w-1/3 h-[300px] rounded-xl">
                        <img className="w-full h-full object-cover rounded-xl" src="https://plus.unsplash.com/premium_photo-1690303193653-0418179e5512?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aGFja2F0aG9ufGVufDB8fDB8fHww" />
                    </div>
                    <div className="w-full lg:w-1/3 h-[300px]">
                        <img className="w-full h-full object-cover rounded-xl" src="https://plus.unsplash.com/premium_photo-1705010649877-f561dd47dee9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8cHJvamVjdHxlbnwwfHwwfHx8MA%3D%3D" />
                    </div>
                    <div className="w-full lg:w-1/3 h-[300px]">
                        <img className="w-full h-full object-cover rounded-xl" src="https://plus.unsplash.com/premium_photo-1683121790394-0893c2ccf578?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjl8fHByb2plY3R8ZW58MHx8MHx8fDA%3D" />
                    </div>
                </div>

                <div className="mt-5 flex items-center justify-between flex-col lg:flex-row">
                    <div className="flex items-center justify-start gap-5 flex-col lg:flex-row flex-wrap">
                        <Button
                            variant="outlined"
                            className="py-2 px-4 ring-0 focus:ring-0 border border-text-50 normal-case bg-text-white text-base font-Be_Vietnam_Pro font-normal text-text-500"
                        >
                            8 Weeks
                        </Button>
                        <Button
                            variant="outlined"
                            className="py-2 px-4 ring-0 focus:ring-0 border border-text-50 normal-case bg-text-white text-base font-Be_Vietnam_Pro font-normal text-text-500"
                        >
                            Intermediate
                        </Button>
                    </div>

                    <Typography className="font-Be_Vietnam_Pro text-text-500 text-base font-medium">
                        By David Brown
                    </Typography>
                </div>

                <div className="border border-text-50 rounded-xl mt-5">
                    <Typography className="font-Be_Vietnam_Pro text-text-500 text-base font-medium p-6 border-b border-text-50">
                        Curriculum
                    </Typography>

                    <div className="p-6 flex items-start lg:items-center flex-col lg:flex-row gap-5">
                        <Typography className="font-Be_Vietnam_Pro flex flex-col items-start text-text-500 text-4xl font-bold lg:border-r border-text-50 lg:pr-8 lg:w-[300px]">
                            01
                            <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                Introduction to Mobile App Development
                            </span>
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro flex flex-col items-start text-text-500 text-4xl font-bold lg:border-r border-text-50 lg:pr-8 lg:w-[300px]">
                            02
                            <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                Fundamentals of Swift Programming (iOS)
                            </span>
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro flex flex-col items-start text-text-500 text-4xl font-bold lg:border-r border-text-50 lg:pr-8 lg:w-[300px]">
                            03
                            <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                Fundamentals of Kotlin Programming (Android)
                            </span>
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro flex flex-col items-start text-text-500 text-4xl font-bold lg:border-r border-text-50 lg:pr-8 lg:w-[300px]">
                            04
                            <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                Building User Interfaces
                            </span>
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro flex flex-col items-start text-text-500 text-4xl font-bold">
                            05
                            <span className="text-sm font-medium font-Be_Vietnam_Pro text-text-400 mt-2">
                                App Deployment and Testing
                            </span>
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpandedCourseCard;