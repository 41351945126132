import { Tab, Tabs, TabsHeader, Typography } from '@material-tailwind/react';
import React, { useState } from 'react'
import OurPricingCard from '../OurPricingCard';

const OurPricing = () => {
    const [activeTab, setActiveTab] = useState("monthly");

    const bannerBox4 = [
        {
          plan: "Free",
          price: 0,
          access: false,
          earlyAccess: false,
        },
        {
          plan: "Pro",
          price: 79,
          access: true,
          earlyAccess: true,
        },
      ];

    const tabsData = [
        {
          label: "Monthly",
          value: "monthly",
        },
        {
          label: "Yearly",
          value: "yearly",
        }
      ];


  return (
    <div className="w-full h-full">
    <Typography className="text-4xl font-medium text-text-500 font-Be_Vietnam_Pro mb-1">
      Our Pricing
    </Typography>
    <div className="flex w-full justify-between flex-col md:flex-row lg:flex-row xl:flex-row mb-16 gap-[3vw]">
      <Typography className="text-base font-Be_Vietnam_Pro font-normal text-text-400 max-w-6xl text-justify">
        Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam
        eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac
        cum eget habitasse in velit fringilla feugiat senectus in.
      </Typography>
      <div>
        <Tabs
          value={activeTab}
          className="w-full bg-[#ffffff] rounded-lg"
        >
          <TabsHeader
            className="bg-[#ffffff] p-2 rounded-lg"
            indicatorProps={{
              className: "bg-[#2C6DE7] text-[#ffffff]", // Blue sliding indicator
            }}
          >
            {tabsData.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setActiveTab(value)} // Use arrow function to directly set the value
                className={`px-4 py-2 rounded-lg text-base font-Be_Vietnam_Pro font-medium ${
                  activeTab === value ? "text-[#ffffff]" : "text-text-500"
                }`}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>
      </div>
    </div>
    <div className="w-full h-full flex flex-col md:flex-col lg:flex-row gap-8 p-[3vw] bg-[#ffffff] rounded-xl">
      {bannerBox4.map((item, index) => {
        return <OurPricingCard data={item} key={index} />;
      })}
    </div>
  </div>
  )
}

export default OurPricing