import { Button, Typography } from "@material-tailwind/react";
import PageHeader from "../../../common/components/PageHeader";
import { UpcomingCard } from "../../Hackathon/UpcomingCard";
import { BenefitCard } from "../../Hackathon/BenefitCard";
import OurTestimonials from "../../../common/components/OurTestimonials";
import OurFaqs from "../../../common/components/OurFaqs";
import { Outlet } from "react-router";


const Bootcamp = () => {

    const arr = [1, 2, 3];
    const benefits = [
        {
            heading: "Flexible Learning Schedule",
            text: "Fit your coursework around your existing commitments and obligations."
        },
        {
            heading: "Expert Instruction",
            text: "Learn from industry experts who have hands-on experience in design and development."
        },
        {
            heading: "Diverse Course Offerings",
            text: "Explore a wide range of design and development courses covering various topics."
        },
        {
            heading: "Updated Curriculum",
            text: "Access courses with up-to-date content reflecting the latest trends and industry practices."
        },
        {
            heading: "Practical Projects and Assignments",
            text: "Develop a portfolio showcasing your skills and abilities to potential employers."
        },
        {
            heading: "Interactive Learning Environment",
            text: "Collaborate with fellow learners, exchanging ideas and feedback to enhance your understanding."
        },
    ]


    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <PageHeader
                    spanText="Live"
                    text="Bootcamps"
                    subHead1="Master Cybersecurity Skills with Hands-On Training"
                    subHead2="Join our immersive bootcamp to unlock a rewarding career in cybersecurity"
                />

                <div className="flex items-center justify-center flex-col md:flex-row mt-10 gap-5">
                    <Button
                        // onClick={() => dispatch(setActiveTab("all"))}
                        className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        Explore Bootcamps
                    </Button>
                    <Button
                        // onClick={() => dispatch(setActiveTab("all"))}
                        className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                    >
                        View Pricing
                    </Button>
                </div>

                {/* --------------------------upcoming bootcamp section---------- */}
                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Upcoming Bootcamps
                    </Typography>
                    <div className="flex items-start justify-between flex-col md:flex-row mt-2 gap-5 md:gap-0" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full md:w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="mt-10 flex flex-wrap items-center justify-center gap-7">
                        {arr.map((item, index) => (
                            <UpcomingCard key={index} type="bootcamp" />
                        ))}
                    </div>
                </div>

                {/* ------------------------benefits section-------------------- */}
                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Benefits
                    </Typography>
                    <div className="flex items-start justify-between flex-col lg:flex-row gap-5 lg:gap-0 mt-2" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="mt-10 flex flex-wrap items-center justify-center gap-7">
                        {benefits.map((item, index) => (
                            <BenefitCard key={index} index={index} heading={item.heading} text={item.text} />
                        ))}
                    </div>
                </div>
                <div className="mt-20">
                    <OurTestimonials />
                </div>
                <div className="mt-20">
                    <OurFaqs />
                </div>


            </div>
            <Outlet />
        </>
    )
}

export default Bootcamp;