import React from "react";
import PageHeader from "../../common/components/PageHeader";
import { Button, Typography } from "@material-tailwind/react";
import OurPricing from "../../common/components/OurPricing";
import OurTestimonials from "../../common/components/OurTestimonials";
import { MdArrowOutward } from "react-icons/md";
import { useNavigate } from "react-router";
import OurFaqs from "../../common/components/OurFaqs";
import { PlayCircle } from "iconsax-react";

const BenefitCard = ({ index, heading, text }) => {
  return (
    <>
      <div className="bg-white w-[31.5%] p-10 rounded-xl">
        <Typography className="text-5xl text-text-500 font-Be_Vietnam_Pro font-bold text-right">
          0{index + 1}
        </Typography>
        <Typography className="text-text-500 font-Be_Vietnam_Pro font-semibold text-lg mt-3">
          {heading}
        </Typography>
        <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm mt-2">
          {text}
        </Typography>
        <div className="flex items-end justify-end mt-5">
          <Button
            variant="outlined"
            className="text-primaryLight-500 border border-text-50 bg-text-100 text-base ring-0 focus:ring-0 p-3"
          >
            <MdArrowOutward />
          </Button>
        </div>
      </div>
    </>
  );
};

const ResearchPaperPayment = () => {
  const navigate = useNavigate();
  const benefits = [
    {
      heading: "Flexible Learning Schedule",
      text: "Fit your coursework around your existing commitments and obligations.",
    },
    {
      heading: "Expert Instruction",
      text: "Learn from industry experts who have hands-on experience in design and development.",
    },
    {
      heading: "Diverse Course Offerings",
      text: "Explore a wide range of design and development courses covering various topics.",
    },
    {
      heading: "Updated Curriculum",
      text: "Access courses with up-to-date content reflecting the latest trends and industry practices.",
    },
    {
      heading: "Practical Projects and Assignments",
      text: "Develop a portfolio showcasing your skills and abilities to potential employers.",
    },
    {
      heading: "Interactive Learning Environment",
      text: "Collaborate with fellow learners, exchanging ideas and feedback to enhance your understanding.",
    },
  ];

  return (
    <>
      <div className="p-[4vw] pb-0">
        <PageHeader
          spanText="Upgrade"
          text="to Membership"
          // subHead1="Browse to Research Papers and Certifications"
          subHead2="Learn from Industry Experts and Enhance Your Skills."
        />
        <div className="flex items-center justify-center mt-10 gap-5">
          <Button className="bg-primaryLight-500 text-white shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium">
            Explore Courses
          </Button>
          <Button className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium">
            View Pricing
          </Button>
        </div>
      </div>
      <div className="w-full h-full flex flex-wrap justify-center px-[4vw]">
        <div className="h-full w-full py-[2vh] sm:py-[4vh] md:py-[6vh] lg:py-[10vh] flex flex-wrap gap-[5vw] justify-center max-w-[1596px]">
          <div className="w-full flex justify-center items-center rounded-[22px] min-h-[40vw]"
            style={{
                backgroundImage: `url("https://img.freepik.com/premium-photo/group-men-are-sitting-around-table-with-laptop-word-im-it_1022944-32899.jpg?w=1380")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}>
                <PlayCircle
            variant="Bulk"
            className="text-white bg-black/10 w-[100px] h-[100px] rounded-full cursor-pointer"
          />

          </div>
          <OurPricing />
          {/* --------------------------static benefits section---------- */}
          <div className="">
            <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
              Benefits
            </Typography>
            <div className="flex items-start justify-between mt-2">
              <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-3/4">
                Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam
                eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et.
                Ac cum eget habitasse in velit fringilla feugiat senectus in.
              </Typography>

              <Button className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium">
                View All
              </Button>
            </div>

            <div className="mt-10 flex flex-wrap items-center justify-center gap-7">
              {benefits.map((item, index) => (
                <BenefitCard
                  key={index}
                  index={index}
                  heading={item.heading}
                  text={item.text}
                />
              ))}
            </div>
          </div>
          <OurTestimonials />
          <OurFaqs />
        </div>
      </div>
    </>
  );
};

export default ResearchPaperPayment;
