import { Avatar, Button, Carousel, IconButton, Typography } from "@material-tailwind/react";

const Testimonials = () => {

    const arr = [1, 2, 3]

    return (
        <>
            <div className="">

                <div className="mb-20 flex flex-col gap-2">
                    <Typography className="text-text-500 font-Be_Vietnam_Pro font-medium text-2xl">Testimonials</Typography>
                    <Typography className="font-Be_Vietnam_Pro font-normal text-base text-text-400">Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                        Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.</Typography>
                </div>
                <Carousel
                    className="rounded-xl h-[390px]"
                    prevArrow={({ handlePrev }) => (
                        <IconButton
                            variant="text"
                            color="white"
                            size="lg"
                            onClick={handlePrev}
                            className="!absolute bottom-0 right-20 -translate-y-2/4 bg-white bg-opacity-100 hover:bg-white hover:!bg-opacity-100"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="#262626"
                                className="h-6 w-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                                />
                            </svg>
                        </IconButton>
                    )}
                    nextArrow={({ handleNext }) => (
                        <IconButton
                            variant="text"
                            color="white"
                            size="lg"
                            onClick={handleNext}
                            className="!absolute bottom-0 !right-4 -translate-y-2/4 bg-white bg-opacity-100 hover:bg-white hover:!bg-opacity-100"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="#262626"
                                className="h-6 w-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                />
                            </svg>
                        </IconButton>
                    )}

                    navigation={() => <></>}
                >
                    {arr.map((item, index) => (
                        <div key={index} className="bg-white !rounded-xl">
                            <Typography className="p-10 pb-10 border-b font-Be_Vietnam_Pro text-base font-normal text-text-400">
                                The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, 
                                and the interactive learning environment was engaging. I highly recommend it!
                            </Typography>
                            <div className="flex items-center justify-between p-10 bg-[#FCFCFD] rounded-xl">
                                <div className="flex items-center  gap-2">
                                    <Avatar
                                        className="!rounded-lg"
                                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                                    />
                                    <Typography className="font-Be_Vietnam_Pro font-semibold text-base text-text-500">Sarah L</Typography>
                                </div>
                                <Button className="bg-text-100 shadow-none hover:shadow-none normal-case font-Be_Vietnam_Pro font-semibold text-base text-text-500">
                                    Read More
                                </Button>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </>
    )
}

export default Testimonials;