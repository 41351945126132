import { Button, Input, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import { Send, Crown1 } from "iconsax-react";
import { FaCircle } from "react-icons/fa";
import searchIcon from "../../assets/images/Search icon.png";
import ResearchPaperCard from "./ResearchPaperCard";
import { useNavigate } from "react-router";

const IndividualResearchPaper = () => {
  const [premium, setPremium] = useState(false);
  const [inputValue, setInputValue] = useState("Search Blog");
  const navigate = useNavigate();


  const handleInputChange = (e) => {
    let value = e.target.value;

    // If the input value is exactly "Search Blog", prevent backspacing
    if (
      value === "Search Blog" &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return; // Prevent any backspace action
    }

    // If "Search Blog" is part of the value, remove it
    if (value.includes("Search Blog")) {
      value = value.replace(/^Search Blog\s*/, ""); // Removes "Search Blog" from the beginning
    }

    // If the user clears the field, set it back to "Search Blog"
    if (
      value.trim() === "" ||
      value.trim() === "Search Blog" ||
      value.trim() === "Search Blo"
    ) {
      setInputValue("Search Blog");
    } else {
      setInputValue(value);
    }
  };

  const handleFocus = () => {
    if (inputValue === "Search Blog") {
      setInputValue("");
    }
  };

  const handleBlur = () => {
    if (inputValue.trim() === "") {
      setInputValue("Search Blog");
    }
  };

  const reserchPapers = [
    {
      heading: "Flexible Learning Schedule",
      text: "Fit your coursework around your existing commitments and obligations.",
    },
    {
      heading: "Expert Instruction",
      text: "Learn from industry experts who have hands-on experience in design and development.",
    },
    {
      heading: "Diverse Course Offerings",
      text: "Explore a wide range of design and development courses covering various topics.",
    },
  ];

  return (
    <div className="w-full h-full min-h-screen p-[5vw]">
      <div className="items-center gap-x-2 flex w-full justify-center mb-[5vw]">
        <div className="relative flex w-full gap-2 md:w-max">
          <Input
            type="search"
            placeholder="Search Blog"
            value={inputValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            containerProps={{
              className:
                "min-w-[100px] w-full sm:min-w-[400px] md:min-w-[500px] lg:min-w-[596px] !h-[64px] h-full w-full",
            }}
            className="bg-white shadow-[0px_5px_20px_5px_rgba(44,_109,_231,_0.15)] !border-none pl-[60px] pt-[10px] placeholder:!text-text-500 rounded-[18px] h-[64px] placeholder:!font-Be_Vietnam_Pro placeholder:!font-normal placeholder:!text-2xl w-full !text-text-500 !font-Be_Vietnam_Pro !font-normal !text-2xl"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={handleInputChange}
          />
          <div className="!absolute left-3 top-[15px]">
            <img src={searchIcon} alt="search icon" />
          </div>
          <Button
            size="md"
            className="!absolute right-3 top-[8px] bg-primaryLight-500 !p-1 !m-1 text-white rounded-[7px] shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium"
          >
            <Send size="32" />
          </Button>
        </div>
      </div>
      <div
        className="w-full flex justify-end items-end lg:min-h-[35vw] md:min-h-[45vw] sm:min-h-[50vw] min-h-[60vw] h-full rounded-[22px]"
        style={{
          backgroundImage: `url("https://img.freepik.com/free-photo/digital-art-ai-technology-background_23-2151719594.jpg?t=st=1729052286~exp=1729055886~hmac=c7188d7554d7b222e9c4f2406f6ab25495eb2d3d967902ae8dda218e2720310d&w=1380")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full h-[45%] flex flex-col backdrop-blur-md rounded-b-[22px] p-[2vw]">
          <Typography className="font-normal flex justify-center items-center bg-[#F1ECFF] px-2 py-1 w-fit rounded-[45px] lg:text-2xl md:text-xl sm:text-lg text-base text-[#6C40FF] font-Be_Vietnam_Pro">
            <FaCircle style={{ marginRight: "1.5vw" }} /> Artificial
            Intelligence
          </Typography>
          <Typography className="font-semibold text-xl sm:text-2xl md:text-4xl lg:text-5xl text-white font-Be_Vietnam_Pro my-[1.5vw] text-left">
            Mastering ChatGPT Blog Creation: Dos and Don'ts for SaaS Marketing
            Managers
          </Typography>
          <Typography className="flex justify-start items-center font-normal lg:text-2xl md:text-xl sm:text-lg text-base text-white font-Be_Vietnam_Pro">
            Oct 19{" "}
            <FaCircle
              style={{
                width: "12px",
                height: "12px",
                marginRight: "0.5vw",
                marginLeft: "0.5vw",
              }}
            />{" "}
            10 min read
          </Typography>
        </div>
      </div>

      <div className="relative max-h-[50vh]">
        <div
          className={`overflow-y-auto flex flex-col gap-4 mt-[4vw] max-h-[50vh] ${
            !premium && "blur-md -z-1"
          }`}
        >
          <Typography className="font-Be_Vietnam_Pro text-xl font-bold text-text-500">
            Exploring Generative AI in Content Creation
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-base text-justify font-normal text-text-500">
            Hello there! As a marketing manager in the SaaS industry, you might
            be looking for innovative ways to engage your audience. I bet
            generative AI has crossed your mind as an option for creating
            content. Well, let me share from my firsthand experience.
            <br /> Google encourages high-quality blogs regardless of whether
            they're written by humans or created using artificial intelligence
            like ChatGPT. Here's what matters: producing original material with
            expertise and trustworthiness based on Google E-E-A-T principles.
            <br /> This means focusing more on people-first writing rather than
            primarily employing AI tools to manipulate search rankings. There
            comes a time when many experienced professionals want to communicate
            their insights but get stuck due to limited writing skills – that’s
            where Generative AI can step in.
            <br /> So, together, we’re going explore how this technology could
            help us deliver valuable content without sounding robotic or
            defaulting into mere regurgitations of existing materials (spoiler
            alert – common pitfalls!). Hang tight - it’ll be a fun learning
            journey!
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-xl font-bold text-text-500">
            Exploring Generative AI in Content Creation
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-base text-justify font-normal text-text-500">
            Hello there! As a marketing manager in the SaaS industry, you might
            be looking for innovative ways to engage your audience. I bet
            generative AI has crossed your mind as an option for creating
            content. Well, let me share from my firsthand experience.
            <br /> Google encourages high-quality blogs regardless of whether
            they're written by humans or created using artificial intelligence
            like ChatGPT. Here's what matters: producing original material with
            expertise and trustworthiness based on Google E-E-A-T principles.
            <br /> This means focusing more on people-first writing rather than
            primarily employing AI tools to manipulate search rankings. There
            comes a time when many experienced professionals want to communicate
            their insights but get stuck due to limited writing skills – that’s
            where Generative AI can step in.
            <br /> So, together, we’re going explore how this technology could
            help us deliver valuable content without sounding robotic or
            defaulting into mere regurgitations of existing materials (spoiler
            alert – common pitfalls!). Hang tight - it’ll be a fun learning
            journey!
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-xl font-bold text-text-500">
            Exploring Generative AI in Content Creation
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-base text-justify font-normal text-text-500">
            Hello there! As a marketing manager in the SaaS industry, you might
            be looking for innovative ways to engage your audience. I bet
            generative AI has crossed your mind as an option for creating
            content. Well, let me share from my firsthand experience.
            <br /> Google encourages high-quality blogs regardless of whether
            they're written by humans or created using artificial intelligence
            like ChatGPT. Here's what matters: producing original material with
            expertise and trustworthiness based on Google E-E-A-T principles.
            <br /> This means focusing more on people-first writing rather than
            primarily employing AI tools to manipulate search rankings. There
            comes a time when many experienced professionals want to communicate
            their insights but get stuck due to limited writing skills – that’s
            where Generative AI can step in.
            <br /> So, together, we’re going explore how this technology could
            help us deliver valuable content without sounding robotic or
            defaulting into mere regurgitations of existing materials (spoiler
            alert – common pitfalls!). Hang tight - it’ll be a fun learning
            journey!
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-xl font-bold text-text-500">
            Exploring Generative AI in Content Creation
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-base text-justify font-normal text-text-500">
            Hello there! As a marketing manager in the SaaS industry, you might
            be looking for innovative ways to engage your audience. I bet
            generative AI has crossed your mind as an option for creating
            content. Well, let me share from my firsthand experience.
            <br /> Google encourages high-quality blogs regardless of whether
            they're written by humans or created using artificial intelligence
            like ChatGPT. Here's what matters: producing original material with
            expertise and trustworthiness based on Google E-E-A-T principles.
            <br /> This means focusing more on people-first writing rather than
            primarily employing AI tools to manipulate search rankings. There
            comes a time when many experienced professionals want to communicate
            their insights but get stuck due to limited writing skills – that’s
            where Generative AI can step in.
            <br /> So, together, we’re going explore how this technology could
            help us deliver valuable content without sounding robotic or
            defaulting into mere regurgitations of existing materials (spoiler
            alert – common pitfalls!). Hang tight - it’ll be a fun learning
            journey!
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-xl font-bold text-text-500">
            Exploring Generative AI in Content Creation
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-base text-justify font-normal text-text-500">
            Hello there! As a marketing manager in the SaaS industry, you might
            be looking for innovative ways to engage your audience. I bet
            generative AI has crossed your mind as an option for creating
            content. Well, let me share from my firsthand experience.
            <br /> Google encourages high-quality blogs regardless of whether
            they're written by humans or created using artificial intelligence
            like ChatGPT. Here's what matters: producing original material with
            expertise and trustworthiness based on Google E-E-A-T principles.
            <br /> This means focusing more on people-first writing rather than
            primarily employing AI tools to manipulate search rankings. There
            comes a time when many experienced professionals want to communicate
            their insights but get stuck due to limited writing skills – that’s
            where Generative AI can step in.
            <br /> So, together, we’re going explore how this technology could
            help us deliver valuable content without sounding robotic or
            defaulting into mere regurgitations of existing materials (spoiler
            alert – common pitfalls!). Hang tight - it’ll be a fun learning 
            journey!
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-xl font-bold text-text-500">
            Exploring Generative AI in Content Creation
          </Typography>
          <Typography className="font-Be_Vietnam_Pro text-base text-justify font-normal text-text-500">
            Hello there! As a marketing manager in the SaaS industry, you might
            be looking for innovative ways to engage your audience. I bet
            generative AI has crossed your mind as an option for creating
            content. Well, let me share from my firsthand experience.
            <br /> Google encourages high-quality blogs regardless of whether
            they're written by humans or created using artificial intelligence
            like ChatGPT. Here's what matters: producing original material with
            expertise and trustworthiness based on Google E-E-A-T principles.
            <br /> This means focusing more on people-first writing rather than
            primarily employing AI tools to manipulate search rankings. There
            comes a time when many experienced professionals want to communicate
            their insights but get stuck due to limited writing skills – that’s
            where Generative AI can step in.
            <br /> So, together, we’re going explore how this technology could
            help us deliver valuable content without sounding robotic or
            defaulting into mere regurgitations of existing materials (spoiler
            alert – common pitfalls!). Hang tight - it’ll be a fun learning
            journey!
          </Typography>
        </div>
        {/* Centered Button */}
      <div className={`${premium && "hidden"} absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center`}>
      <div className="w-full flex flex-col justify-center items-center">
        <Button
          className="bg-[#E7892C] text-white flex font-Be_Vietnam_Pro items-center hover:shadow-none normal-case justify-center px-6 py-2 rounded-lg font-medium text-lg"
          onClick={() =>{
            navigate("/membership");
            //  setPremium(true)
            }}
        >
          <span className="mr-2"><Crown1 /></span> Upgrade to Membership
        </Button>
        <Typography className="mt-2 text-text-500 text-base font-medium font-Be_Vietnam_Pro">
          Membership will allow you to get access to all premium research papers. Also, membership will provide you special discounts on courses and certifications.
        </Typography>
      </div>
      </div>
      </div>

      {/* --------------------------static You might also like section---------- */}
      <div className="mt-[4vw]">
        <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
          You might also like
        </Typography>
        <div className="flex items-start justify-between mt-2 gap-2 flex-col sm:flex-col md:flex-row lg:flex-row">
              <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-3/4">
                Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam
                eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et.
                Ac cum eget habitasse in velit fringilla feugiat senectus in.
              </Typography>

              <Button className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium">
                View All
              </Button>
            </div>

        <div className="mt-[3vw] flex flex-wrap items-center justify-center gap-7">
          {reserchPapers.map((item, index) => (
            <ResearchPaperCard
              key={index}
              index={index}
              heading={item.heading}
              text={item.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndividualResearchPaper;
