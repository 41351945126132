import { Avatar, Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router";
import UpcomingHackathon from "./UpcomingHackathon";
import OurTestimonials from "../../common/components/OurTestimonials";
import OurFaqs from "../../common/components/OurFaqs";
import ExpandedHackathon from "./Expandedhackathon";

const ParticipantCard = ({ name, bio, index, image }) => {
    return (
        <>
            <div className="bg-white w-full lg:w-[31.5%] p-10 rounded-xl">
                <div className="flex items-end justify-end mb-5">
                    <Avatar src={image} className="!rounded-xl " />
                </div>
                <Typography className="text-2xl text-text-500 font-Be_Vietnam_Pro font-bold">
                    #0{index + 1}  {name}
                </Typography>
                <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm mt-5">
                    {bio}
                </Typography>
            </div>
        </>
    )
}

const IndividualHackathon = ({ type }) => {

    const navigate = useNavigate();

    const participants = [
        {
            name: "Alex Mate",
            bio: "Fit your coursework around your existing commitments and obligations.",
            profileImage: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZGVjZW50JTIwcGVvcGxlfGVufDB8fDB8fHww"
        },
        {
            name: "Alex Mate",
            bio: "Fit your coursework around your existing commitments and obligations.",
            profileImage: "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGRlY2VudCUyMHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D"
        },
        {
            name: "Alex Mate",
            bio: "Fit your coursework around your existing commitments and obligations.",
            profileImage: "https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGRlY2VudCUyMHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D"
        },
        {
            name: "Alex Mate",
            bio: "Fit your coursework around your existing commitments and obligations.",
            profileImage: "https://images.unsplash.com/photo-1492447166138-50c3889fccb1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGRlY2VudCUyMHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D"
        },
        {
            name: "Alex Mate",
            bio: "Fit your coursework around your existing commitments and obligations.",
            profileImage: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZGVjZW50JTIwcGVvcGxlfGVufDB8fDB8fHww"
        },
        {
            name: "Alex Mate",
            bio: "Fit your coursework around your existing commitments and obligations.",
            profileImage: "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGRlY2VudCUyMHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D"
        },
    ]

    return (
        <>
            <div className="p-0 pt-10 sm:p-5 lg:p-20">
                <ExpandedHackathon placement="individual" type={type} />

                <div className="mt-20">
                    <Typography className="font-Be_Vietnam_Pro font-medium text-4xl text-text-500">
                        Top Participants
                    </Typography>
                    <div className="flex flex-col lg:flex-row items-start justify-between mt-2 gap-5 lg:gap-0" >
                        <Typography className="text-text-400 text-sm font-medium font-Be_Vietnam_Pro w-full lg:w-3/4">
                            Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et.
                            Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.
                        </Typography>

                        <Button
                            className="bg-white text-text-500 shadow-none hover:shadow-none normal-case text-base font-Be_Vietnam_Pro font-medium w-full lg:w-fit"
                        >
                            View All
                        </Button>
                    </div>

                    <div className="mt-10 flex flex-wrap items-center justify-center gap-7">
                        {participants.map((item, index) => (
                            <ParticipantCard key={index} index={index} name={item.name} bio={item.bio} image={item.profileImage} />
                        ))}
                    </div>
                </div>

                <div className="mt-20">
                    <UpcomingHackathon type="Upcoming" />
                </div>
                <div className="mt-20">
                    <OurTestimonials />
                </div>
                <div className="mt-20">
                    <OurFaqs />
                </div>
            </div >
        </>
    )
}

export default IndividualHackathon;