import { Button } from '@material-tailwind/react';
import googleIcon from '../../../assets/images/Icon.png';
import { useGoogleSignInMutation } from '../../../config/Apiconfig';
import { useDispatch } from 'react-redux';
import { setAccessToken, setUserDetails } from '../authSlice';
import Loader from '../../../common/components/Loader';
import { useNavigate } from 'react-router';
import { signInWithGooglePopup } from "../../../utils/firebase.utils"
import { useEffect } from 'react';
import toast from 'react-hot-toast';

const GoogleSignIn = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [googleSignIn, { data, isLoading, isError }] = useGoogleSignInMutation();

    //google signin 
    const logGoogleUser = async () => {
        const response = await signInWithGooglePopup();
        if (response?.user) {
            googleSignIn({
                email: response.user.email,
                fullName: response.user.displayName,
                profileImg: response.user.photoURL,
                googleId: response.user.uid
            })
        }
    }

    useEffect(() => {
        if (data) {
            toast.success(data?.message || "Google Authentication successfully done!");
            dispatch(setUserDetails(data?.responseData));
            dispatch(setAccessToken(data?.accessToken));
            navigate("/");
        }
    }, [data])

    // console.log(data, isLoading, isError);
    if (isError) { toast.error('Google authentication failed! Please try again.') };

    if (isLoading) return <Loader />;

    return (
        <>
            <Button onClick={logGoogleUser}
                className="w-full bg-text-100 normal-case font-medium text-base font-Be_Vietnam_Pro text-text-500 flex items-center justify-center gap-2 shadow-none hover:shadow-none"
            >
                <img src={googleIcon} />
                Sign Up with Google
            </Button>
        </>
    )
}

export default GoogleSignIn;