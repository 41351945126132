import { Chip, Typography } from "@material-tailwind/react";
import { Clock } from "iconsax-react";
import { useNavigate } from "react-router";


const ModuleCard = ({ placement, courseData, courseName, data, index }) => {

    const navigate = useNavigate();

    return (
        <>
            <div className={`bg-white rounded-xl  p-10 ${placement === "courseDetails" ? "w-[48.3%]" : "min-w-[41vw] w-full flex-1"}`}>
                <div className="flex items-end justify-end">
                    <Typography className="text-5xl font-bold font-Be_Vietnam_Pro text-text-500">
                        0{index + 1}
                    </Typography>
                </div>

                <div className="mt-5">
                    <Typography className="font-bold text-base font-Be_Vietnam_Pro text-text-500">
                        {data.title}
                    </Typography>

                    <div className="flex flex-col items-start justify-start gap-5 mt-5">
                        {data.lessons.map((lesson, index) => (
                            <div onClick={() => navigate(`/courses/module/${courseName}/${index}`, { state: { courseData: courseData } })} key={index} className="cursor-pointer p-4 border border-text-400/10 rounded-lg w-full flex items-center justify-between">
                                <Typography className="font-Be_Vietnam_Pro font-medium text-sm text-text-400">
                                    {lesson.title}
                                </Typography>

                                <div className="flex items-center justify-center gap-1 bg-text-100 rounded-lg p-2">
                                    <Clock size="13" />
                                    <Chip value={lesson.time} className="p-0 bg-text-100 w-fit font-Be_Vietnam_Pro text-text-400 text-xs normal-case font-medium" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModuleCard;