import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import rightIcon from "../../../assets/images/arrowRightButton.png";

function Icon({ id, open }) {
  return (
    <div className="w-[52px] h-[52px] bg-[#2c6de7] bg-opacity-20 rounded-xl flex justify-center items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${id === open ? "rotate-45" : ""
          } h-5 w-5 transition-transform`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 4.75v14.5M4.75 12h14.5"
        />
      </svg>
    </div>
  );
}

const OurFaqs = () => {
  const [mouse, setMouse] = useState(false);
  const [open, setOpen] = useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const qaData = [
    {
      question: "Is the Council of Information Security (CIS) a non-profit organization?",
      id: 1,
      processRedirect: "About CIS as a Non-Profit Organization",
      answer: `Yes, CIS is a non-profit organization committed to advancing cybersecurity knowledge,
building a secure digital ecosystem, and promoting cybersecurity best practices for
individuals, organizations, and the nation as a whole.`,
    },
    {
      question: "How does CIS sustain its operations as a non-profit?",
      id: 2,
      processRedirect: "About CIS as a Non-Profit Organization",
      answer: `CIS sustains its operations through membership fees, donations, partnerships,
sponsorships, and revenue from training programs and events. All proceeds are used to
further the organization’s mission of enhancing cybersecurity awareness and education.`,
    },
    {
      question: "How can I support CIS’s mission?",
      id: 3,
      processRedirect: "Benefits of Supporting CIS as a Non-Profit",
      answer: `You can support CIS’s mission by becoming a member, attending events, volunteering,
or making a donation. Supporting CIS helps fund programs that improve cybersecurity
skills, spread awareness, and contribute to a safer digital environment.`,
    },
    {
      question: "Are there volunteer opportunities available with CIS?",
      id: 4,
      processRedirect: "Benefits of Supporting CIS as a Non-Profit",
      answer: `Yes, CIS offers various volunteer opportunities for those who want to contribute their
time and expertise. Volunteers can help with events, educational programs, content
creation, and community outreach.`,
    },
    {
      question: "What are the benefits of becoming a CIS member?",
      id: 5,
      processRedirect: "Membership",
      answer: `As a CIS member, you get access to exclusive training resources, certifications,
networking events, industry insights, discounts on cybersecurity courses, and more.
Members also enjoy priority access to new programs, tools, and events.`,
    },
    {
      question: "Are there different membership levels?",
      id: 6,
      processRedirect: "Membership",
      answer: `Yes, CIS offers various membership levels tailored to individuals, students, and
organizations. Each level has unique benefits designed to suit different needs and
professional goals. Check our &quot;Membership&quot; page for detailed information on each
level.`,
    },
    {
      question: "Can I upgrade my membership later?",
      id: 7,
      processRedirect: "Membership",
      answer: `Yes, you can upgrade your membership at any time. Simply log in to your account, go to
the &quot;Membership Settings&quot; section, and choose the upgrade option.`,
    },
    {
      question: "Do members receive discounts on certifications and events?",
      id: 8,
      processRedirect: "Membership",
      answer: `Yes, CIS members receive exclusive discounts on certification programs, workshops,
and events. These discounts vary depending on your membership level.`,
    },
    {
      question: "What programs does CIS offer?",
      id: 9,
      processRedirect: "Programs and Training",
      answer: `CIS offers a range of programs, including cybersecurity awareness training, boot
camps, hackathons, short-term courses, and certification programs. We also offer
specialized programs like Project Drona, which focuses on creating cybersecurity
professionals for underserved areas.`,
    },
    {
      question: "Are CIS certifications recognized in the industry?",
      id: 10,
      processRedirect: "Programs and Training",
      answer: `Yes, CIS certifications are recognized and respected within the cybersecurity industry.
They demonstrate a commitment to cybersecurity excellence and provide practical skills
that are valuable to employers.`,
    },
    {
      question: "Can I take CIS courses online?",
      id: 11,
      processRedirect: "Programs and Training",
      answer: `Yes, most of our courses are available online, allowing you to learn at your own pace
from anywhere. Some hands-on workshops and training sessions may also be offered
in-person.`,
    },
    {
      question: "What certifications does CIS offer?",
      id: 11,
      processRedirect: "Certification",
      answer: `CIS offers various certifications in different areas of cybersecurity, such as Certified
Ethical Hacker, Certified Information Security Manager, and specialized certifications in
fields like incident response and network security.`,
    },
    {
      question: "How do I apply for a CIS certification?",
      id: 12,
      processRedirect: "Certification",
      answer: `You can apply for a CIS certification by selecting the certification of interest on our
&quot;Certifications&quot; page and completing the application process. Once registered, you’ll
receive further instructions for accessing study materials and scheduling exams.`,
    },
    {
      question: "What types of events does CIS organize?",
      id: 13,
      processRedirect: "Events and Networking",
      answer: `CIS hosts a variety of events, including webinars, hackathons, conferences, and the
Annual Information Security Summit. These events provide networking opportunities,
industry insights, and hands-on experience with cybersecurity challenges.`,
    },
    {
      question: "How do I register for an event?",
      id: 14,
      processRedirect: "Events and Networking",
      answer: `Visit the "Events" page on our website to view upcoming events and select the one
you’d like to attend. Follow the registration process, and you’ll receive a confirmation
email with event details.`,
    },
    {
      question: "Does CIS partner with other organizations?",
      id: 15,
      processRedirect: "Partnerships and Collaboration",
      answer: `Yes, CIS collaborates with industry leaders, educational institutions, and government
bodies to promote cybersecurity best practices, conduct research, and develop effective
training programs.`,
    },
  ];

  return (
    <div className="w-full h-full flex flex-col md:flex-col lg:flex-row gap-8 p-[3vw] bg-[#ffffff] rounded-lg">
      <div className="md:w-full lg:w-1/3 w-full h-fit">
        <Typography className="font-medium font-Be_Vietnam_Pro text-4xl text-text-500 lg:max-w-[408px] max-w-full xl:max-w-[408px] md:max-w-full mb-2">
          Frequently Asked Questions
        </Typography>
        <Typography className="font-normal text-base text-text-400 font-Be_Vietnam_Pro lg:max-w-[408px] max-w-full xl:max-w-[408px] md:max-w-full mb-12">
          Still you have any questions? Contact our Team via
          support@skillbridge.com
        </Typography>
        <Button className="bg-white text-text-500 font-semibold text-lg normal-case shadow-none hover:shadow-none border-[1px] border-text-50">
          Contact Us
        </Button>
      </div>
      <div className="md:w-full lg:w-2/3 w-full rounded-xl">
        {qaData.map((item, index) => {
          return (
            <div key={index}>
              <Accordion
                open={open === item.id}
                icon={<Icon id={item.id} open={open} />}
                className="mb-4 rounded-lg border-[1px] border-[#f1f1f3] px-8"
              >
                <AccordionHeader
                  onClick={() => handleOpen(item.id)}
                  className={`transition-colors border-[#f1f1f3] ${open === 1 ? "border-b-[1px]" : "border-b-0"
                    }`}
                >
                  <Typography className="text-lg font-Be_Vietnam_Pro font-medium text-text-500">
                    {item.question}
                  </Typography>
                </AccordionHeader>
                <AccordionBody>
                  <Typography className="text-base font-Be_Vietnam_Pro font-normal text-text-400 pb-8 pt-4">
                    {item.answer}
                  </Typography>
                  <Typography
                    onMouseEnter={() => setMouse(true)}
                    onMouseLeave={() => setMouse(false)}
                    className="flex justify-between items-center text-base font-normal font-Be_Vietnam_Pro text-text-400 px-8 py-3 mb-8 border-[1px] cursor-pointer rounded-lg border-[#f1f1f3] bg-[#f7f7f8]"
                  >
                    {item.processRedirect}
                    {""}
                    <img
                      src={rightIcon}
                      alt="icon"
                      className={`w-[56px] h-[56px] ${mouse ? "rotate-0" : "-rotate-45"
                        } transition-transform rounded-full`}
                    />
                  </Typography>
                </AccordionBody>
              </Accordion>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurFaqs;
