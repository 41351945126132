import { Typography } from '@material-tailwind/react';
import footer from '../../../assets/images/Subtract.png';
import success from '../../../assets/images/Success_Icon.png';
import { Import } from 'iconsax-react';

const PaymentResult = ({ handleNext }) => {
    return (
        <>
            <div className="" >
                <div className='relative h-full bg-white p-10 flex flex-col items-center justify-center rounded-tl-xl rounded-tr-xl'>
                    <div className='absolute -top-16'>
                        <img src={success} />
                    </div>
                    <div className=' border-b w-full flex flex-col items-center justify-center pb-5 mt-10'>
                        <Typography className="font-Be_Vietnam_Pro text-3xl font-bold text-text-500">
                            Payment Success!
                        </Typography>
                        <Typography className="font-Be_Vietnam_Pro text-sm font-medium text-text-400 mt-2">
                            Your payment has been successfully done.
                        </Typography>
                    </div>

                    <Typography className="font-Be_Vietnam_Pro text-sm font-medium text-text-500 mt-5">
                        Total Payment
                    </Typography>
                    <Typography className="font-Be_Vietnam_Pro text-base font-bold text-text-400 mt-5">
                        INR 2000
                    </Typography>

                    <div className='flex gap-5 w-full mt-5 items-center'>
                        <div className='border border-text-400/30 p-2 rounded-lg w-full'>
                            <Typography className='font-Be_Vietnam_Pro font-medium text-sm text-text-400/30'>
                                Ref Number
                            </Typography>
                            <Typography className='font-Be_Vietnam_Pro font-medium text-sm text-text-500 mt-1'>
                                000085752257
                            </Typography>
                        </div>
                        <div className='border border-text-400/30 p-2 rounded-lg w-full'>
                            <Typography className='font-Be_Vietnam_Pro font-medium text-sm text-text-400/30'>
                                Payment Time
                            </Typography>
                            <Typography className='font-Be_Vietnam_Pro font-medium text-sm text-text-500 mt-1'>
                                12 March 2024, 13:22
                            </Typography>
                        </div>
                    </div>

                    <div className='flex gap-5 w-full mt-5 items-center'>
                        <div className='border border-text-400/30 p-2 rounded-lg w-full'>
                            <Typography className='font-Be_Vietnam_Pro font-medium text-sm text-text-400/30'>
                                Payment Method
                            </Typography>
                            <Typography className='font-Be_Vietnam_Pro font-medium text-sm text-text-500 mt-1'>
                                Bank Transfer
                            </Typography>
                        </div>
                        <div className='border border-text-400/30 p-2 rounded-lg w-full'>
                            <Typography className='font-Be_Vietnam_Pro font-medium text-sm text-text-400/30'>
                                Name
                            </Typography>
                            <Typography className='font-Be_Vietnam_Pro font-medium text-sm text-text-500 mt-1'>
                                Ansh Singh
                            </Typography>
                        </div>
                    </div>

                    <Typography className='font-Be_Vietnam_Pro text-text-500 font-medium text-lg mt-10 flex items-center justify-center gap-2 cursor-pointer'>
                        <Import /> Get PDF Receipt
                    </Typography>
                </div>
                <img src={footer} />
            </div>
        </>
    )
}

export default PaymentResult;