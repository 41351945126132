import * as Yup from 'yup';

//Newly inserted
const emailRules =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//register formik schema
export const signupSchema = Yup.object().shape({
    fullName: Yup.string().required('Required'),
    email: Yup.string()
        .email()
        .required('Required')
        .matches(emailRules, 'Invalid email')
        .min(4, 'Invalid mail id')
        .max(64, 'Email should be of maximum 64 characters length'),
    password: Yup.string()
        .required('Required')
        .min(8, 'Password should be of minimum 8 characters length')
        .max(16, 'Password should be of maximum 16 characters length'),
});

//login formik schema
export const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required('Required')
        .matches(emailRules, 'Invalid email')
        .min(4, 'Invalid mail id')
        .max(64, 'Email should be of maximum 64 characters length'),
    password: Yup.string()
        .required('Required')
        .min(8, 'Password should be of minimum 8 characters length')
        .max(16, 'Password should be of maximum 16 characters length'),
});