import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { Crown1 } from "iconsax-react";
import MyBlogs from "./MyBlogs";
import MyCourses from "./MyCourses";
import Hackathon from "./Hackathon";
import ProfileSettings from "./ProfileSettings";
import { useSelector } from "react-redux";

const ClientProfile = () => {
  const [activeTab, setActiveTab] = useState("My Courses");
  const { userDetails } = useSelector((state) => state.authSlice);

  const tabsData = [
    // {
    //   label: "Blog",
    //   value: "Blog",
    //   content: <MyBlogs />,
    // },
    {
      label: "My Courses",
      value: "My Courses",
      content: <MyCourses />,
    },
    {
      label: "Previous Hackathons",
      value: "Previous Hackathons",
      content: <Hackathon />,
    },
    {
      label: "Profile Settings",
      value: "Profile Settings",
      content: <ProfileSettings />,
    },
  ];

  // console.log(userDetails)

  return (
    <div className="w-full h-full flex flex-wrap justify-center items-center flex-col p-[4vw]">
      <div className="flex items-center justify-center flex-col gap-3 mb-20">
        <img
          src={userDetails?.profileImg || "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1729743968~exp=1729747568~hmac=c50bc02b76b3c377093499e15899bdea4a383828a52f5c5e4efea9c3e780cf5e&w=740"}
          alt="avatar"
          className="w-[134px] h-[100px] rounded-tl-[54px] rounded-tr-[28px] rounded-bl-[28px] rounded-br-[54px] object-cover"
          style={{
            clipPath: "polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%)",
          }}
        />
        <Typography className="text-[#7E7F81] font-Be_Vietnam_Pro font-bold text-sm">
          AUTHOR
        </Typography>
        <Typography className="text-text-500 font-Be_Vietnam_Pro text-2xl md:text-4xl font-semibold flex justify-center items-center">
          {userDetails?.fullName}{" "}
          <Crown1 size="24" className="text-[#FF7700] rounded-full ml-1" />
        </Typography>
        <Typography className="text-[#7E7F81] font-Be_Vietnam_Pro font-bold text-sm text-center mb-2 max-w-[50%]">
          {userDetails?.bio}
        </Typography>
        <div className="flex justify-center items-center gap-3">
          <div>
            <img
              src={userDetails?.profileImg || "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1729743968~exp=1729747568~hmac=c50bc02b76b3c377093499e15899bdea4a383828a52f5c5e4efea9c3e780cf5e&w=740"}
              alt="avatar"
              className="w-[48px] h-[48px] object-cover rounded-lg"
            />
          </div>
          <div>
            <Typography className="text-[#262626] font-Be_Vietnam_Pro uppercase font-bold text-xs text-opacity-60">
              contributor
            </Typography>
            <Typography className="text-[#53AA51] font-Be_Vietnam_Pro font-semibold text-lg">
              Cyber Security Specialist
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full">
        <Tabs value={activeTab} className="w-full flex items-center justify-center flex-col rounded-lg">
          <TabsHeader
            className="bg-[#ffffff] max-w-4xl w-full p-2 rounded-lg"
            indicatorProps={{
              className: "bg-[#2C6DE7] text-[#ffffff]", // Blue sliding indicator
            }}
          >
            {tabsData.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setActiveTab(value)}
                className={`px-4 py-4 rounded-lg min-w-[200px] text-base font-Be_Vietnam_Pro font-medium ${activeTab === value ? "text-[#ffffff]" : "text-text-500"
                  }`}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>

          {/* TabsBody placed directly below TabsHeader */}
          <TabsBody>
            {tabsData.map(({ value, content }) => (
              <TabPanel key={value} value={value} className="p-4">
                {content}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </div>
  );
};

export default ClientProfile;
