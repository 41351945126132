import { Avatar, Typography } from "@material-tailwind/react";
import logo from "../../../assets/images/Icon _Container.svg";
import bg from "../../../assets/images/Icon_Container_check.svg";
import { useMatch } from "react-router";

const PageHeader = ({ spanText, text, subHead1, subHead2 }) => {
  const isVerified = useMatch("/certificate/verification");
  return (
    <>
      <div className="flex items-center justify-center flex-col gap-3">
        <div className="flex items-center justify-center gap-2 bg-white p-4 w-fit rounded-xl relative border border-text-50">
          <hr className="hidden lg:block bg-text-500 h-5 w-[2px] absolute -top-4 -left-2.5 -rotate-[45deg] border-0 rounded-md" />
          <hr className="hidden lg:block bg-text-500 h-3 w-[2px] absolute top-0.5 -left-3 -rotate-[80deg] border-0 rounded-md" />
          <hr className="hidden lg:block bg-text-500 h-3 w-[2px] absolute -top-4 left-1 -rotate-[20deg] border-0 rounded-md" />
          <Avatar className="!rounded-none" src={!isVerified ? logo : bg} />
          <Typography className="text-text-500 font-Be_Vietnam_Pro text-2xl md:text-4xl font-semibold">
            <span className="text-primaryLight-500">{spanText}</span> {text}
          </Typography>
        </div>
        {subHead1 && (
          <Typography className="text-text-500 font-Be_Vietnam_Pro font-medium text-2xl text-center">
            {subHead1}
          </Typography>
        )}
        {subHead2 && (
          <Typography className="text-text-400 font-Be_Vietnam_Pro font-medium text-sm lg:max-w-[50%] text-center">
            {subHead2}
          </Typography>
        )}
      </div>
    </>
  );
};

export default PageHeader;
